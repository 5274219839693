/* eslint-disable prettier/prettier */
<template>
    <v-main>
        <BackOnTop url="/login" />
        <PageTitleBar title="大樹寵物會員註冊" hasLogo />
        <v-container class="register-container px-4 mb-4">
            <PopupAlert
                v-model="showAlert"
                :errorMsg="errorMsg"
                :imgSrc="imgSrc"
            />
            <v-form ref="form" class="mb-16" v-model="valid" lazy-validation>
                <v-row class="mt-0">
                    <v-col cols="12" sm="12" class="py-0 mb-4">
                        <FancyInput
                            v-model="form.phone"
                            label="手機*(必填)"
                            iconName="ic24Phone.svg"
                            type="number"
                            :rules="[
                                () =>
                                    phone_error_msg ||
                                    !!form.phone ||
                                    '請輸入正確手機',
                            ]"
                            required
                    /></v-col>
                    <v-col cols="12" sm="12" class="py-0 mb-4">
                        <FancyInput
                            v-model="form.password"
                            label="密碼*(必填)"
                            iconName="ic24Lock.svg"
                            hint="6-12位英文及數字混和的密碼"
                            persistent-hint
                            :rules="[
                                () =>
                                    (!!form.password &&
                                        form.password.match(
                                            /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,12}$/
                                        ) != null) ||
                                    '請輸入6-12位英文及數字混和的密碼',
                            ]"
                            type="password"
                            required
                    /></v-col>
                    <v-col cols="12" sm="12" class="py-0 mb-4">
                        <FancyInput
                            v-model="form.password2"
                            label="確認密碼*(必填)"
                            iconName="ic24Lock.svg"
                            hint="請再次輸入跟上方相同的密碼"
                            persistent-hint
                            :rules="[
                                () =>
                                    (!!form.password2 &&
                                        form.password == form.password2) ||
                                    '兩次密碼輸入不相同',
                            ]"
                            type="password"
                            required
                    /></v-col>
                    <v-col cols="12" sm="12" class="py-0 mb-4">
                        <FancyInput
                            v-model="form.name"
                            label="姓名*(必填)"
                            iconName="ic24Uname.svg"
                            :rules="[() => !!form.name || '請輸入會員姓名']"
                            required
                    /></v-col>
                    <v-col cols="6" sm="6" class="py-0 mb-4">
                        <FancySelect
                            label="生日 年"
                            v-model="form.birthday_year"
                            iconName="ic24Birthday.svg"
                            :items="yearOptions"
                            :options="yearOptions"
                        />
                    </v-col>
                    <v-col cols="3" sm="3" class="py-0 mb-4">
                        <FancySelect
                            label="月"
                            v-model="form.birthday_month"
                            :options="[
                                {
                                    text: '月',
                                    value: '',
                                    selected: true,
                                    disabled: true,
                                },
                                { text: '01', value: '01' },
                                { text: '02', value: '02' },
                                { text: '03', value: '03' },
                                { text: '04', value: '04' },
                                { text: '05', value: '05' },
                                { text: '06', value: '06' },
                                { text: '07', value: '07' },
                                { text: '08', value: '08' },
                                { text: '09', value: '09' },
                                { text: '10', value: '10' },
                                { text: '11', value: '11' },
                                { text: '12', value: '12' },
                            ]"
                            :items="[
                                {
                                    text: '月',
                                    value: '',
                                    selected: true,
                                    disabled: true,
                                },
                                { text: '01', value: '01' },
                                { text: '02', value: '02' },
                                { text: '03', value: '03' },
                                { text: '04', value: '04' },
                                { text: '05', value: '05' },
                                { text: '06', value: '06' },
                                { text: '07', value: '07' },
                                { text: '08', value: '08' },
                                { text: '09', value: '09' },
                                { text: '10', value: '10' },
                                { text: '11', value: '11' },
                                { text: '12', value: '12' },
                            ]"
                        />
                    </v-col>
                    <v-col cols="3" sm="3" class="py-0 mb-4">
                        <!-- <FancySelectBottom
                            v-if="dayOptions.length"
                            defaultLabel="日"
                            v-model="form.birthday_day"
                            :options="dayOptions"
                        /> -->
                        <FancySelect
                            label="日"
                            v-model="form.birthday_day"
                            :options="dayOptions"
                            :items="dayOptions"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" class="py-0 mb-4">
                        <FancySelect
                            v-model="form.gender"
                            label="生理性別*"
                            iconName="ic24UGender.svg"
                            :options="[
                                {
                                    text: '生理性別*',
                                    value: '',
                                    selected: true,
                                    disabled: true,
                                },
                                { text: '女', value: 'female' },
                                { text: '男', value: 'male' },
                            ]"
                            :items="[
                                {
                                    text: '生理性別',
                                    value: '',
                                    selected: true,
                                    disabled: true,
                                },
                                { text: '女', value: 'female' },
                                { text: '男', value: 'male' },
                            ]"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" class="py-0 mb-4">
                        <FancySelect
                            v-model="form.reg_storeno"
                            label="註冊門市*"
                            iconName="ic24Store.svg"
                            :items="shopOptions"
                            :options="shopOptions"
                            required
                        />
                    </v-col>
                    <v-col cols="12" sm="12" class="py-0">
                        <FancyCheckContract
                            v-model="isAgreeRegisterContract"
                            :contractLink="`/register/contract?phone=${form.phone}&name=${form.name}&password=${form.password}&birthday=${form.birthday}&gender=${form.gender}`"
                            hint="為了確保您的自身權益，請務必詳閱服務條款，若您曾於門市申請大樹寵物會員，我們將優先使用您之前所登記的資料大樹寵物會員註冊"
                            :rules="[
                                () =>
                                    !!isAgreeRegisterContract ||
                                    '為了確保您的自身權益，請務必詳閱服務條款',
                            ]"
                            persistent-hint
                        />
                    </v-col>
                </v-row>
            </v-form>
            <BtnNext @click="goNext" />
        </v-container>
    </v-main>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
import PageTitleBar from '@/components/PageTitleBar.vue';
import BtnNext from '@/components/BtnNext.vue';
import FancyInput from '@/components/FancyInput.vue';
// import FancyDate from '@/components/FancyDate.vue';
import FancyCheckContract from '@/components/FancyCheckContract.vue';
import PopupAlert from '@/components/Popup/PopupAlert.vue';
import FancySelect from '@/components/FancySelect.vue';
import { KgQueryMember, checkPhoneExist, KgGetStore } from '@/apis/api.js';

export default {
    name: 'RegisterIndex',
    components: {
        PopupAlert,
        BackOnTop,
        PageTitleBar,
        BtnNext,
        FancyInput,
        // FancyDate,
        FancyCheckContract,
        FancySelect,
    },
    data: () => ({
        errorMsg: '',
        showAlert: false,
        imgSrc: '',
        valid: true,
        form: {
            phone: '',
            name: '',
            password: '',
            password2: '',
            birthday: '2022-05-05',
            gender: '',
            birthday_year: '',
            birthday_month: '',
            birthday_day: '',
            reg_storeno: '',
        },
        isAgreeRegisterContract: false,
        phone_error_msg: '',
        shopOptions: [
            {
                text: '網路門市',
                value: '',
            },
        ],
        yearOptions: [
            {
                text: '生日 年',
                value: '',
                selected: true,
                disabled: true,
            },
        ],
        dayOptions: [
            {
                text: '日',
                value: '',
                selected: true,
                disabled: true,
            },
        ],
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    created() {
        if (this.$route.query.phone) {
            this.form = {
                phone: this.$route.query.phone,
                name: this.$route.query.name,
                password: this.$route.query.password,
                password2: this.$route.query.password,
                birthday: this.$route.query.birthday,
                gender: this.$route.query.gender,
                reg_storeno: this.$route.query.reg_storeno,
            };
        }
        var now = new Date();
        var date =
            now.getFullYear() +
            '-' +
            (now.getMonth() + 1 < 10 ? '0' : '') +
            (now.getMonth() + 1) +
            '-' +
            (now.getDate() < 10 ? '0' : '') +
            now.getDate();
        this.form.birthday = date;
        const parent = this;
        KgGetStore('0989187330').then(function (rsp) {
            for (let i in rsp) {
                parent.shopOptions.push({
                    text: rsp[i].STO_Name,
                    value: rsp[i].STO_Name,
                });
            }
        });
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.state.isLoading = false;
        });
        for (
            let i = new Date().getFullYear();
            i >= new Date().getFullYear() - 100;
            i--
        ) {
            this.yearOptions.push({
                text: `${i} (民${i - 1911})`,
                value: `${i}`,
            });
        }
    },
    methods: {
        daysInMonth(year, month) {
            console.log('daysInMonth', year, month);
            return new Date(year, month, 0).getDate();
        },
        goNext() {
            const valid = this.$refs.form.validate();
            var form_data = this.form;
            var parent = this;
            console.log(form_data);
            // 補預設值
            if (!form_data.gender) form_data.gender = 'female';
            if (valid) {
                checkPhoneExist(parent.form.phone).then(function (rsp) {
                    if (rsp) {
                        // 帳號已註冊過
                        console.log('帳號已註冊過');
                        parent.phone_error_msg = '帳號已被註冊';
                        parent.$refs.form.validate();
                    } else {
                        // 康技查會員
                        KgQueryMember(form_data.phone, null).then(function (
                            rsp
                        ) {
                            console.log('queryMember', rsp);
                            if (rsp || rsp == '') {
                                if (rsp == true || rsp == 'true') {
                                    // app會員
                                    console.log('app會員');
                                    parent.$router.push(
                                        '/register/otp?type=new&phone=' +
                                            form_data.phone +
                                            '&name=' +
                                            form_data.name +
                                            '&password=' +
                                            form_data.password +
                                            '&birthday=' +
                                            form_data.birthday +
                                            '&gender=' +
                                            form_data.gender +
                                            '&reg_storeno=' +
                                            form_data.reg_storeno
                                    );
                                } else {
                                    // 門市會員 -> 啟用
                                    console.log('門市會員 -> 啟用');
                                    
                                    if (rsp.MEB_Name)
                                        form_data.name = rsp.MEB_Name;
                                    if (rsp.MEB_Birth)
                                        form_data.birthday = rsp.MEB_Birth;
                                    if (rsp.MEB_Sex)
                                        form_data.gender = rsp.MEB_Sex;
                                    parent.$router.push(
                                        '/register/otp?type=old&phone=' +
                                            form_data.phone +
                                            '&name=' +
                                            form_data.name +
                                            '&password=' +
                                            form_data.password +
                                            '&birthday=' +
                                            form_data.birthday +
                                            '&gender=' +
                                            form_data.gender +
                                            '&reg_storeno=' +
                                            form_data.reg_storeno
                                    );
                                }
                            } else {
                                console.log('KgQueryMember error');
                                // register(form_data).then(function(rsp){
                                //     console.log("register",rsp);
                                //     parent.$router.push('/register/otp?type=new&phone=' + form_data.phone + "&name=" + form_data.name + "&password=" + form_data.password + "&birthday=" + form_data.birthday.replace("/","").replace("/","") + "&gender=" + form_data.gender);
                                // })
                            }
                        });
                    }
                });
            } else {
                if (this.phone_error_msg) {
                    this.errorMsg = this.phone_error_msg;
                } else if (!this.form.phone) {
                    this.errorMsg = '請輸入正確手機';
                } else if (
                    !this.form.password ||
                    this.form.password.match(
                        /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,12}$/
                    ) == null
                ) {
                    this.errorMsg = '請輸入6-12位英文及數字混和的密碼';
                } else if (this.form.password != this.form.password2) {
                    this.errorMsg = '兩次密碼輸入不相同';
                } else if (!this.form.name) {
                    this.errorMsg = '請輸入會員姓名';
                } else if (
                    !this.form.birthday_year ||
                    !this.form.birthday_month ||
                    !this.form.birthday_day
                ) {
                    this.errorMsg = '請輸入完整生日';
                } 
                // else if (!this.form.gender) {
                //     this.errorMsg = '請選擇性別';
                // }
                 else if (!this.isAgreeRegisterContract) {
                    this.errorMsg = '為了確保您的自身權益，請務必詳閱服務條款';
                }
                this.imgSrc = '@/assets/images/imgWarning.svg';
                this.showAlert = true;
            }
        },
    },
    computed: {
        birthday_year_month() {
            return this.form.birthday_year + this.form.birthday_month;
        },
        birthday() {
            if (
                this.form.birthday_year &&
                this.form.birthday_month &&
                this.form.birthday_day
            ) {
                return `${this.form.birthday_year}-${this.form.birthday_month}-${this.form.birthday_day}`;
            } else {
                return '';
            }
        },
    },
    watch: {
        'form.phone': function () {
            this.phone_error_msg = '';
        },
        birthday_year_month: function () {
            if (this.form.birthday_year && this.form.birthday_month) {
                var days = this.daysInMonth(
                    this.form.birthday_year,
                    parseInt(this.form.birthday_month)
                );
                this.dayOptions = [
                    {
                        text: '日',
                        value: '',
                        selected: true,
                        disabled: true,
                    },
                ];
                for (let i = 1; i <= days; i++) {
                    this.dayOptions.push({
                        text: i < 10 ? `0${i}` : `${i}`,
                        value: i < 10 ? `0${i}` : `${i}`,
                    });
                }
            }
        },
        birthday: function () {
            if (
                this.form.birthday_year &&
                this.form.birthday_month &&
                this.form.birthday_day
            ) {
                this.form.birthday = `${this.form.birthday_year}-${this.form.birthday_month}-${this.form.birthday_day}`;
            } else {
                this.form.birthday = '';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.register-container {
    margin: 12px 0;
    padding: 10px 0 0 0;
}
</style>
