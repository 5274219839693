<template>
    <v-text-field
        class="fancy"
        v-bind="{ ...$attrs, ...commonAttrs }"
        v-on="$listeners"
        :type="!isPasswordType ? type : isOpenPassword ? 'text' : 'password'"
        autocomplete="new-password"
    >
        <template v-slot:prepend-inner v-if="iconName">
            <img
                width="24"
                height="24"
                :src="require(`@/assets/images/${iconName}`)"
                alt=""
            />
        </template>
        <template v-slot:append v-if="isPasswordType">
            <img
                v-if="!isOpenPassword"
                width="24"
                height="24"
                :src="require(`@/assets/images/ic24ViewOn.svg`)"
                @click="isOpenPassword = true"
                alt=""
            />
            <img
                v-if="isOpenPassword"
                width="24"
                height="24"
                :src="require(`@/assets/images/ic24ViewOff.svg`)"
                @click="isOpenPassword = false"
                alt=""
            />
        </template>
        <template
            v-for="(_, scopedSlotName) in $scopedSlots"
            #[scopedSlotName]="slotData"
        >
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template>
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-text-field>
</template>

<script>
export default {
    name: 'FancyInput',
    inheritAttrs: false,
    props: {
        iconName: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
    },
    data: () => ({
        isPasswordType: false,
        isOpenPassword: false,
    }),
    created() {
        if (this.type == 'password') this.isPasswordType = true;
        var text = !this.isPasswordType ? this.type : this.isOpenPassword ? 'text' : 'password';
        console.log(text);
    },
    computed: {
        commonAttrs() {
            return {
                outlined: true,
                shaped: true,
            };
        },
    },
};
</script>

<style lang="scss">
div.fancy {
    &.v-text-field {
        > .v-input__control {
            > .v-input__slot {
                margin-bottom: 4px;
                fieldset {
                    color: $color_main_first !important;
                    padding-left: 12px !important;
                }
                .v-label {
                    color: $color_main_grey !important;
                    height: 24px !important;
                    line-height: 24px !important;
                }
                .v-label--active {
                    color: #ff9f4b !important;
                    // background-color: #fffbf5 !important;
                    transform: translate(6px, -20px) scale(0.75) !important;
                }
                .v-input__prepend-inner,
                .v-input__append-inner {
                    margin-top: 12px !important;
                    padding-right: 8px !important;
                }
            }
            > .v-text-field__details {
                padding-left: 4px !important;
                margin-bottom: 0px;
                padding-top: 1px !important;
                > .v-messages {
                    > .v-messages__wrapper {
                        padding-left: 32px;
                    }
                    &.error--text {
                        color: $color_main_red !important;
                        > .v-messages__wrapper {
                            padding-left: 0px;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: flex-start;
                            align-items: center;
                            &:before {
                                content: '';
                                background-image: url('~@/assets/images/ic24Warning.svg');
                                background-size: 12px 12px;
                                height: 12px;
                                width: 12px;
                                margin-right: 4px;
                            }
                            > .v-messages__message {
                                font-size: 8px;
                                color: $color_main_red;
                            }
                        }
                    }
                }
            }
        }

        &.v-input--is-disabled {
            > .v-input__control {
                > .v-input__slot {
                    fieldset {
                        color: $color_main_grey_light !important;
                        padding-left: 12px !important;
                    }
                    .v-text-field__slot {
                        .v-label--active {
                            color: $color_main_grey_light !important;
                        }
                    }
                }
            }
        }
    }
}
</style>
