<template>
    <FancyDialog v-bind="{ ...$attrs }" v-on="$listeners">
        <v-container fluid class="dialog_container text-left">
            <v-form ref="cancel_form" v-model="cancelValid">
            <p class="title mb-4">取消預約</p>
            <p class="sub mb-1">
                會員{{ form.name }}您好，<br />您將取消本次的預約
            </p>
            <p class="sub sub--desc mb-4">
                時間 : {{ form.time }}<br />
                門市 : {{ form.store }}<br />
                寶貝名稱 : {{ form.petName }}
            </p>
            <p class="sub mb-2">取消原因為</p>
            <FancyRadio
                class="mt-n1 f_sm"
                fluid
                v-model="form.reason"
                
                :items="[
                    { label: '有事無法前來', value: '有事無法前來' },
                    { label: '公司服務不佳', value: '公司服務不佳' },
                    { label: '其他', value: '其他' },
                ]"
                :rules="[
                    () => !!form.reason || '請選擇取消原因',
                ]"
                required
            />
            <FancyTextarea
                height="100"
                v-if="form.reason == '其他'"
                placeholder="其他原因"
                v-model="form.reasonText"
                class="textarea_else"
            />
            <div class="footer d-flex justify-center">
                <FancyButton
                    isGreen
                    class="btn mr-3"
                    label="取消"
                    @click="$emit('input', false)"
                />
                <FancyButton
                    class="btn"
                    label="確認"
                    @click="check()"
                />
            </div>
            </v-form>
        </v-container>
    </FancyDialog>
</template>

<script>
import FancyDialog from '@/components/FancyDialog.vue';
import FancyButton from '@/components/FancyButton.vue';
import FancyRadio from '@/components/FancyRadio.vue';
import FancyTextarea from '@/components/FancyTextarea.vue';

export default {
    components: {
        FancyDialog,
        FancyButton,
        FancyRadio,
        FancyTextarea,
    },
    name: 'PopupReservedCancel',
    props: {
        serviceList: {
            type: Array,
        },
        form: {
            type: Object,
        },
    },
    emits: ['input', 'click:confirm'],
    data: () => ({
        reason: '公司服務不佳',
        cancelValid:''
    }),
    methods:{
        check(){
            const valid = this.$refs.cancel_form.validate();
            if(valid) this.$emit('click:confirm');
        }
    }
};
</script>

<style lang="scss">
div.dialog_container {
    padding: 0px 20px;
    // > p,
    // > div {
    //     padding: 0 20px;
    // }
    p.title {
        color: $color_main_first;
        font-weight: 400 !important;
        font-size: 20px !important;
        line-height: 24px !important;
        text-align: center;
    }
    p.sub {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $color_main_black;
        &--desc {
            font-size: 14px;
            color: $color_main_grey_dark;
        }
    }
    p.desc {
        font-size: 14px;
        line-height: 16px;
        color: $color_main_black;
    }
    .textarea_else {
        margin: -10px auto 0;
    }
    > .footer {
        padding: 0;
        .btn {
            height: 50px;
            width: 100%;
            @media screen and (max-width: 374px) {
                max-width: 118px;
            }
            @media screen and (min-width: 375px) {
                max-width: 145.5px;
            }
        }
    }
}
</style>
