<template>
    <div class="process-box">
        <v-row cols="12">
            <v-col
                cols="12"
                class="text-left process-col"
                v-for="(process, idx) in processList"
                :key="idx"
            >
                <img
                    width="8"
                    height="8"
                    :src="
                        process.isFinished
                            ? require(`@/assets/images/dot.svg`)
                            : require(`@/assets/images/dot_outlined.svg`)
                    "
                    alt=""
                />
                <p v-if="process.time" class="time mr-3">{{ process.time }}</p>
                <p class="step" :class="{ processing: !process.isFinished }">
                    {{ process.step }}
                </p>
                <img
                    v-if="process.isFinished"
                    class="check"
                    height="11"
                    src="@/assets/images/check.svg"
                    alt=""
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'SalonProcessBox',
    props: {
        processList: {
            type: Array,
        },
    },
    data: () => ({}),
};
</script>

<style lang="scss" scoped>
.process-box {
    background: #f9f6f4;
    border-radius: 16px;
    padding: 24px 24px 12px;
}
.process-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 12px 0;
    img {
        margin: 0 12px 0 0;
        &.check {
            margin: 0 0 0 auto;
        }
    }
}
p {
    color: $color_main_first;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    &.step {
        &.processing {
            color: $color_main_grey_light;
        }
    }
}
.row + .row {
    margin-top: 8px;
}
</style>
