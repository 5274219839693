<template>
    <div class="paddingTop50">
        <div class="back d_flex">
            <img
                src="../assets/images/ic24ArrowL.svg"
                alt=""
                @click="handler()"
            />
            <div class="style-title-m">{{  title }}</div>
            <a v-if="showRight" class="save" @click.prevent="handleRight">
                {{ labelRight }}
            </a>
            <a v-if="showSave" class="save" @click.prevent="handleSave">
                儲存
            </a>
            <a v-if="showDelete" class="delete" @click.prevent="handleDelete">
                刪除
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BackOnTop',
    props: {
        title: {
            type: String,
            default:''
        },
        showRight: {
            type: Boolean,
            default: false,
        },
        url: {
            type: String,
            default: '',
        },
        labelRight: {
            type: String,
            default: '儲存',
        },
        showSave: {
            type: Boolean,
            default: false,
        },
        showDelete: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['click:right', 'click:save', 'click:delete'],
    methods: {
        handleRight() {
            this.$emit('click:right');
        },
        handleSave() {
            this.$emit('click:delete');
        },
        handleDelete() {
            this.$emit('click:delete');
        },
        handler() {
            if(this.url == "") this.$router.go(-1);
            else this.$router.push(this.url)
            
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.back {
    height: 80px;
    width: 100%;
    align-items: center;
    justify-content: start;
    padding: 30px 15px 0;
    background-image: url('~@/assets/images/Bg.png');
    background-size: cover;
    background-position-y: top;
    // background-color: #fff;
    position: fixed;
    left: 0;
    top: -30px;
    z-index: 2;
    > img {
        height: 31px;
        margin-left: -6px;
    }

    a {
        &.save,
        &.delete {
            flex: 1;
            text-align: right;
        }
    }
}
.paddingTop50 {
    padding-top: 50px;
    width: 100%;
}
</style>
