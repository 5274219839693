var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',[_c('BackOnTop',{attrs:{"title":_vm.title}}),(!_vm.$store.state.isLoading)?_c('v-container',{staticClass:"salon-container pt-3 px-8 mt-3 mb-4",staticStyle:{"height":"calc(100vh - 200px)"},attrs:{"fluid":""}},[_c('div',{staticClass:"style-title-m",staticStyle:{"text-align":"left","font-size":"16px","margin-bottom":"24px"}},[_vm._v(" 基本資料"),_c('span',{staticClass:"required-icon"},[_vm._v("*")])]),_c('div',{staticClass:"style-body-regular",staticStyle:{"text-align":"left","margin-bottom":"10px"}},[_c('img',{staticStyle:{"width":"18px","height":"18px"},attrs:{"src":require("../../assets/images/foot.png"),"alt":""}}),_vm._v(" 選擇寶貝 ")]),_c('div',{staticClass:"style-salon-baby-list",staticStyle:{"margin-bottom":"30px","display":"flex","overflow":"scroll"}},_vm._l((_vm.baby_list),function(baby,index){return _c('div',{key:index,class:`${
                    _vm.form.baby == baby.value
                        ? 'style-baby-active'
                        : 'style-baby-inactive'
                }`,staticStyle:{"width":"72px","margin-right":"12px"},on:{"click":function($event){_vm.form.baby = baby.value}}},[_c('div',{staticClass:"style-order-edit-img",style:({ 'background-image': `url(${baby.img})` })}),_c('div',{staticClass:"style-body-regular",staticStyle:{"margin-top":"6px"}},[_vm._v(" "+_vm._s(baby.text)+" ")])])}),0),_c('div',{staticClass:"style-body-regular",staticStyle:{"text-align":"left","margin-bottom":"10px","background-color":"white"}},[_c('img',{staticStyle:{"width":"18px","height":"18px"},attrs:{"src":require("../../assets/images/phone.png"),"alt":""}}),_vm._v(" 連絡電話 ")]),_c('div',{staticStyle:{"margin-bottom":"30px"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone),expression:"form.phone"}],staticClass:"style-text-input",staticStyle:{"background-color":"white"},attrs:{"type":"text"},domProps:{"value":(_vm.form.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "phone", $event.target.value)}}})]),_c('div',{staticClass:"style-body-regular",staticStyle:{"text-align":"left","margin-bottom":"10px"}},[_c('img',{staticStyle:{"width":"18px","height":"18px"},attrs:{"src":require("../../assets/images/shop.png"),"alt":""}}),_vm._v(" 選擇門市 ")]),_c('div',{staticStyle:{"margin-bottom":"40px"}},[(_vm.store_list.length)?_c('FancySelectBottom',{attrs:{"defaultLabel":"選擇門市","options":_vm.store_list,"initial":_vm.initial,"refreshServiceItem":_vm.refreshServiceItem,"isTime":""},model:{value:(_vm.form.store),callback:function ($$v) {_vm.$set(_vm.form, "store", $$v)},expression:"form.store"}}):_vm._e()],1),_c('div',{staticClass:"style-title-m",staticStyle:{"text-align":"left","font-size":"16px","margin-bottom":"12px"}},[_vm._v(" 服務項目"),_c('span',{staticClass:"required-icon"},[_vm._v("*")])]),_c('div',{staticClass:"col-12 row"},[_c('div',{class:`col-6 ${
                    _vm.serviceSelect == 'group'
                        ? 'style-active-tab'
                        : 'style-inactive-tab'
                }`,on:{"click":function($event){_vm.serviceSelect = 'group';
                    _vm.form.serviceItem = '';}}},[_vm._v(" 套餐 ")]),_c('div',{class:`col-6 ${
                    _vm.serviceSelect == 'single'
                        ? 'style-active-tab'
                        : 'style-inactive-tab'
                }`,on:{"click":function($event){_vm.serviceSelect = 'single';
                    _vm.form.serviceItem = '';}}},[_vm._v(" 單選 ")])]),(_vm.serviceSelect == 'single')?_c('div',{staticClass:"row",staticStyle:{"margin-top":"20px"}},_vm._l((_vm.optionsChipSingle.filter(function (
                    obj
                ) {
                    if (
                        obj.match('游泳') != null &&
                        _vm.have_swim_service_store_list.indexOf(
                            _vm.form.store.split(' ')[0]
                        ) == -1
                    ) {
                        return false;
                    } else {
                        return true;
                    }
                })),function(item,index){return _c('div',{key:'optionsChipSingle' + index,class:`${
                    _vm.form.baby && _vm.form.phone && _vm.form.store
                        ? (_vm.form.serviceItem == item
                            ? 'style-light-btn'
                            : 'style-border-btn')
                        : 'style-time-disable-btn'
                } style-btn`,style:(`${
                    index != _vm.optionsChipSingle.length - 1
                        ? 'margin-right: 6px;font-size:12px !important;'
                        : 'font-size:12px !important;'
                }`),on:{"click":function($event){(_vm.form.baby && _vm.form.phone && _vm.form.store) ? _vm.form.serviceItem = item : ''}}},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e(),(_vm.serviceSelect == 'group')?_c('div',{staticClass:"row",staticStyle:{"margin-top":"20px"}},_vm._l((_vm.optionsChipGroup.filter(function (
                    obj
                ) {
                    if (
                        obj.match('游泳') != null &&
                        _vm.have_swim_service_store_list.indexOf(
                            _vm.form.store.split(' ')[0]
                        ) == -1
                    ) {
                        return false;
                    } else {
                        return true;
                    }
                })),function(item,index){return _c('div',{key:'optionsChipGroup' + index,class:`${
                    _vm.form.baby && _vm.form.phone && _vm.form.store
                        ? (_vm.form.serviceItem == item
                            ? 'style-light-btn'
                            : 'style-border-btn')
                        : 'style-time-disable-btn'
                } style-btn`,style:(`${
                    index != _vm.optionsChipGroup.length - 1
                        ? 'margin-right: 6px;font-size:12px !important;'
                        : 'font-size:12px !important;'
                }`),on:{"click":function($event){(_vm.form.baby && _vm.form.phone && _vm.form.store) ? _vm.form.serviceItem = item : ''}}},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e(),_c('PopupOrderUnableNotAdd',{on:{"click:confirm":function($event){return _vm.$router.push('/register/pet')}},model:{value:(_vm.showOrderUnableNotAdd),callback:function ($$v) {_vm.showOrderUnableNotAdd=$$v},expression:"showOrderUnableNotAdd"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }