<template>
    <v-list flat>
        <v-list-item-group color="primary">
            <v-list-item
                v-for="(item, i) in times"
                :key="i"
                class="item d_flex"
                color="#333"
                @click.prevent="handleClick(item.id)"
                :disabled="item.isFull"
            >
                <p class="item-time">{{ item.time }}</p>
                <p class="item-text" :class="{ isFull: item.isFull }">
                    {{ item.text }}
                </p>
                <img
                    v-if="!item.isFull"
                    class="item-img"
                    :src="
                        selectedTime == item.id
                            ? require(`@/assets/images/ic24FootActive.svg`)
                            : require(`@/assets/images/ic24Foot.svg`)
                    "
                    alt=""
                />
            </v-list-item>
        </v-list-item-group>
    </v-list>
</template>

<script>
export default {
    name: 'SalonDateSchedule',
    props: {
        times: {
            type: Array,
        },
        value: {
            type: String,
        },
    },
    data: () => ({
        selectedTime: '',
    }),
    created() {
        this.selectedTime = this.value;
    },
    methods: {
        handleClick(id) {
            this.selectedTime = id == this.selectedTime ? '' : id;
            this.$emit('input', this.selectedTime);
        },
    },
};
</script>

<style lang="scss" scoped>
.v-list {
    margin-bottom: 35px;
    .v-item-group {
        .v-list-item {
            &.item {
                color: $color_main_black;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                min-height: 48px !important;
                box-shadow: inset 0px -1px 0px #e9e9e9;
                &.v-item--active {
                    color: $color_main_black;
                }
            }
            p.item-time {
                margin-left: 4px;
                margin-right: 16px;
            }
            p.item-text {
                &.isFull {
                    color: $color_main_red;
                }
            }
            img.item-img {
                height: 24px;
                width: 24px;
                margin-left: auto;
                order: 3;
            }
        }
    }
}
</style>
