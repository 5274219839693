var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('BackOnTop',{attrs:{"url":_vm.url,"title":"美容日誌"}}),_c('div',{staticClass:"col-12",staticStyle:{"z-index":"3","position":"fixed","top":"50px","background-color":"white","padding-top":"16px","padding-left":"32px","padding-right":"32px"}},[_c('div',{staticClass:"col-12 row"},[_c('div',{class:`col-6 ${
                    _vm.tabSelect == 'reserve'
                        ? 'style-active-tab'
                        : 'style-inactive-tab'
                }`,staticStyle:{"background-color":"white"},on:{"click":function($event){_vm.tabSelect = 'reserve';
                    _vm.currentPage = 1;
                    _vm.isScrollDown = false;}}},[_vm._v(" 我的預約 ")]),_c('div',{class:`col-6 ${
                    _vm.tabSelect == 'record'
                        ? 'style-active-tab'
                        : 'style-inactive-tab'
                }`,staticStyle:{"background-color":"white"},on:{"click":function($event){_vm.tabSelect = 'record';
                    _vm.currentPage = 1;
                    _vm.isScrollDown = false;}}},[_vm._v(" 美容紀錄 ")])]),_c('div',{staticClass:"row",style:(`margin-bottom: 16px;opacity:${_vm.isScrollDown ? 0 : 1}`)},[_c('div',{staticStyle:{"width":"47.5%","margin-right":"5%"}},[(_vm.timeSelectOptions.length)?_c('FancySelectBottom',{attrs:{"defaultLabel":"由近到遠","options":_vm.timeSelectOptions,"initial":0,"icon":require('@/assets/images/order-icon.svg'),"isTime":""},model:{value:(_vm.timeSelect),callback:function ($$v) {_vm.timeSelect=$$v},expression:"timeSelect"}}):_vm._e()],1),_c('div',{staticStyle:{"width":"47.5%"}},[(_vm.petList.length)?_c('FancySelectBottom',{attrs:{"defaultLabel":"請選擇寶貝","options":_vm.petList,"isTime":""},model:{value:(_vm.petSelect),callback:function ($$v) {_vm.petSelect=$$v},expression:"petSelect"}}):_vm._e()],1)])]),_c('v-container',{staticClass:"salon-container px-8 mb-4",style:('margin-top: 130px;'),attrs:{"fluid":""}},[_c('v-container',{staticClass:"d_flex justify-space-between mb-6",staticStyle:{"padding-bottom":"20px"},attrs:{"fluid":""}},[(
                    _vm.tabSelect == 'reserve' &&
                    (_vm.reserve_list.length || _vm.beauty_list.length) &&
                    !_vm.$store.state.isLoading
                )?_c('div',{staticClass:"col-12"},[_c('div',{ref:"listPage",staticClass:"col-12",on:{"scroll":_vm.handleScroll}},[_vm._l((_vm.beauty_list),function(info,index){return _c('div',{key:index,staticClass:"col-12"},[_c('div',{class:`style-salon-history-beauty ${_vm.getRecordStatusClass(
                                info.status
                            )}`,on:{"click":function($event){$event.preventDefault();return _vm.$router.push(info.url)}}},[_c('div',{staticClass:"col-12",staticStyle:{"margin-bottom":"18px"}},[_c('div',{staticClass:"row",staticStyle:{"justify-content":"space-between","width":"100%"}},[_c('div',{staticClass:"style-title-m"},[_vm._v(" "+_vm._s(info.service_list.join('、'))+" "+_vm._s(info.name)+" ")]),_c('div',{class:`style-body-regular style-salon-status ${_vm.getStatusClass(
                                            info.status
                                        )}`},[_vm._v(" "+_vm._s(info.status)+" ")])]),_c('div',{staticClass:"style-body-regular",staticStyle:{"letter-spacing":"0.56px"}},[_vm._v(" "+_vm._s(info.time)+" "+_vm._s(info.hour.split(":")[0] >= 12 ? '下午' : '上午')+_vm._s(info.hour)+" ")]),_c('div',{staticClass:"style-body-regular"},[_vm._v(" 門市 "+_vm._s(info.store)+" ")])]),_c('div',[_c('div',{staticClass:"style-body-regular",style:(`margin-bottom:-15px`)},_vm._l((info.PBS_DTL_INFO),function(PBS_DTL_INFO,index){return _c('div',{key:index,staticClass:"service_item",staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"max-height":"20px","min-height":"20px"}},[_c('div',[_c('div',{style:(`width: 8px;height: 8px;background-color: ${
                                                        PBS_DTL_INFO.PBS_SvrBegTime
                                                            ? '#ff9f4b'
                                                            : '#ccc'
                                                    };border-radius: 100%;position:relative;z-index:2`)}),_c('div',{style:(`background-color: ${
                                                        !PBS_DTL_INFO.PBS_SvrBegTime ||
                                                        (PBS_DTL_INFO.PBS_SvrBegTime &&
                                                            info
                                                                .PBS_DTL_INFO[
                                                                index + 1
                                                            ] &&
                                                            !info
                                                                .PBS_DTL_INFO[
                                                                index + 1
                                                            ]
                                                                .PBS_SvrBegTime)
                                                            ? '#ccc'
                                                            : '#FF9F4B'
                                                    };z-index:1;width: 2px;height: 18px;position:relative;left: 3px;bottom: 2px;${
                                                        index ==
                                                        info.PBS_DTL_INFO
                                                            .length -
                                                            1
                                                            ? 'opacity:0;'
                                                            : ''
                                                    }`)})])]),(
                                                PBS_DTL_INFO.PBS_SvrBegTime
                                            )?_c('div',{style:(info.PBS_DTL_INFO[
                                                    index + 1
                                                ] &&
                                                !info.PBS_DTL_INFO[
                                                    index + 1
                                                ].PBS_SvrBegTime
                                                    ? 'width:65px;position:relative;bottom:6px;color:#FF9F48;font-weight:700;font-size:14px;padding-left: 13px;padding-right: 18px;max-height: 20px;min-height: 20px;'
                                                    : 'width:65px;position:relative;bottom:4px;font-size:12px;padding-left: 13px;padding-right: 18px;max-height: 20px;min-height: 20px;')},[_vm._v(" "+_vm._s(PBS_DTL_INFO.PBS_SvrBegTime.slice( 8, 10 ))+":"+_vm._s(PBS_DTL_INFO.PBS_SvrBegTime.slice( 10, 12 ))+" ")]):_c('div',{staticStyle:{"width":"65px","padding-left":"13px","padding-right":"18px","position":"relative","bottom":"14px","max-height":"20px","min-height":"20px"}}),_c('div',{style:(info.PBS_DTL_INFO[
                                                    index + 1
                                                ] &&
                                                !info.PBS_DTL_INFO[
                                                    index + 1
                                                ].PBS_SvrBegTime &&
                                                info.PBS_DTL_INFO[index]
                                                    .PBS_SvrBegTime
                                                    ? 'position:relative;bottom:8px;color:#FF9F48;font-weight:700;font-size:14px;max-height: 20px;min-height: 20px;'
                                                    : 'position:relative;bottom:6px;font-size:12px;max-height: 20px;min-height: 20px;')},[_vm._v(" "+_vm._s(PBS_DTL_INFO.PBS_WrkName)+" ")])])}),0)])])])}),_vm._l((_vm.reserve_list),function(info,index){return _c('div',{key:index,staticClass:"col-12"},[_c('div',{class:`style-salon-history-reserve ${_vm.getRecordStatusClass(
                                info.status
                            )}`,on:{"click":function($event){$event.preventDefault();return _vm.$router.push(info.url)}}},[_c('div',{staticClass:"row",staticStyle:{"justify-content":"space-between","width":"100%"}},[_c('div',{staticClass:"style-title-m"},[_vm._v(" "+_vm._s(info.service_list.join('、'))+" "+_vm._s(info.name)+" ")]),_c('div',{class:`style-body-regular style-salon-status ${_vm.getStatusClass(
                                        info.status
                                    )}`},[_vm._v(" "+_vm._s(info.status)+" ")])]),_c('div',{staticClass:"style-body-regular",staticStyle:{"letter-spacing":"0.56px"}},[_vm._v(" "+_vm._s(info.time)+" "+_vm._s(info.hour.split(":")[0] >= 12 ? '下午' : '上午')+_vm._s(info.hour)+" ")]),_c('div',{staticClass:"style-body-regular"},[_vm._v(" 門市 "+_vm._s(info.store)+" ")])])])})],2)]):(
                    _vm.tabSelect == 'record' &&
                    _vm.history.length &&
                    !_vm.$store.state.isLoading
                )?_c('div',{staticClass:"col-12"},_vm._l((_vm.history),function(info,index){return _c('div',{key:index,staticClass:"col-12"},[_c('div',{class:`style-salon-history-record `,on:{"click":function($event){$event.preventDefault();return _vm.$router.push(info.url)}}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"style-order-history-img",style:({
                                    'background-image': `url(${info.img})`,
                                })}),_c('div',[_c('div',{staticClass:"style-title-m"},[_vm._v(" "+_vm._s(info.service_list.join('、'))+" "+_vm._s(info.name)+" ")]),_c('div',{staticClass:"style-body-regular",staticStyle:{"letter-spacing":"0.56px","color":"#6f6f6f","margin-top":"8px"}},[_vm._v(" "+_vm._s(info.time)+" "+_vm._s(info.hour.split(":")[0] >= 12 ? '下午' : '上午')+_vm._s(info.hour)+" ")]),_c('div',{staticClass:"style-body-regular",staticStyle:{"color":"#6f6f6f","margin-top":"8px"}},[_vm._v(" 門市 "+_vm._s(info.store)+" ")])])])])])}),0):(!_vm.$store.state.isLoading)?_c('BabyPhoto',{attrs:{"imgSrc":require('@/assets/images/new-logo.png'),"title":"尚未有美容紀錄"}}):_vm._e()],1)],1),_c('PopupGeneralAlert',{attrs:{"errorTitle":"伺服器忙碌中","errorMsg":['抱歉，目前伺服器忙碌中，請稍後重試。']},on:{"click:confirm":function($event){_vm.showAlert = false}},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }