<template>
    <v-textarea
        class="fancy fancy--textarea"
        v-bind="{ ...$attrs, ...commonAttrs }"
        v-on="$listeners"
    >
        <template
            v-for="(_, scopedSlotName) in $scopedSlots"
            #[scopedSlotName]="slotData"
        >
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template>
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" /> </template
    ></v-textarea>
</template>

<script>
export default {
    name: 'FancyTextarea',
    inheritAttrs: false,
    props: {},
    data: () => ({}),
    created() {},
    computed: {
        commonAttrs() {
            return { noResize: true, filled: true, flat: true, rows: '4' };
        },
    },
};
</script>

<style lang="scss">
div.fancy--textarea {
    &.v-text-field {
        &.v-textarea {
            .v-input__control {
                font-size: 14px;
                line-height: 24px;
                > .v-input__slot {
                    textarea {
                        color: $color_main_black !important;
                    }

                    border-radius: 8px;
                    background: #f9f6f4 !important;
                    // height: 100px;
                    &:before,
                    &:after {
                        content: none;
                    }
                    &:hover {
                        background: #f9f6f4 !important;
                    }
                }
            }
        }
    }
}
</style>
