<template>
    <v-main>
        <BackOnTop />
        <PageTitleBar title="新密碼已發送至您的手機，請至登入頁面輸入新密碼後登入" />
        <v-container fluid class="fp-container px-4 mb-4">
            <BtnNext class="btn" @click="handleNext" />
        </v-container>
    </v-main>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
import PageTitleBar from '@/components/PageTitleBar.vue';
import BtnNext from '@/components/BtnNext.vue';
// import FancyInput from '@/components/FancyInput.vue';
// import { updatePassword } from '@/apis/api.js';
export default {
    name: 'ForgotPasswordReset',
    components: { BackOnTop, PageTitleBar, BtnNext },
    data: () => ({
        valid: '',
        password: '',
        password2: '',
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.state.isLoading = false;
        });
    },
    methods: {
        handleNext() {
            var parent = this;
            parent.$router.push('/login');
        },
    },
};
</script>

<style lang="scss" scoped>
.fp-container {
    margin: 12px 0;
    padding: 10px 0 0 0;
    p.desc {
        font-size: 16px;
        line-height: 24px;
        color: $color_main_black;
        text-align: left;
    }
    .btn {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: 52px;
    }
}
</style>
