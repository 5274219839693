<template>
    <v-checkbox
        class="fancy check_contract"
        v-bind="{ ...$attrs, ...commonAttrs }"
        v-on="$listeners"
        :input-value="value"
        @change="(changed) => $emit('input', changed)"
    >
        <template v-slot:label>
            <p style="color:#0D0c0c;font-size: 14px;">
                我已同意
                <a
                    :style="{ 'text-decoration': 'underline','color':'black','font-weight':'bold' }"
                    target="_blank"
                    
                    @click="$router.push(contractLink)"
                >
                    服務條款
                </a>
            </p>
        </template>
        <template
            v-for="(_, scopedSlotName) in $scopedSlots"
            #[scopedSlotName]="slotData"
        >
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template>
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-checkbox>
</template>

<script>
export default {
    name: 'FancyCheckContract',
    inheritAttrs: false,
    props: {
        label: String,
        contractLink: String,
        value: null,
    },
    computed: {
        commonAttrs() {
            return {};
        },
    },
};
</script>

<style lang="scss">
div.fancy.check_contract {
    &.v-input--selection-controls {
        margin-top: 0px;
        padding-top: 0px;
        margin-bottom: 35px;

        > .v-input__control {
            align-items: center !important;
            justify-content: center !important;
            > .v-input__slot {
                width: auto;
                .v-label {
                    > p {
                        margin-bottom: 0;
                        color: $color_main_black;
                        font-size: 16px;
                        line-height: 19px;
                        > a {
                            color: $color_main_first;
                            text-decoration: underline;
                        }
                    }
                }
                > .v-input--selection-controls__input {
                    .v-icon {
                        color: $color_main_first;
                        &.error--text {
                            color: $color_main_first !important;
                        }
                    }
                }
            }
            > .v-messages {
                color: #909090;
                font-size: 12px;
                margin-left: 0px !important;
                &.error--text {
                    color: $color_main_red !important;
                    > .v-messages__wrapper {
                        // padding-left: 0px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        align-items: center;
                        &:before {
                            content: '';
                            background-image: url('~@/assets/images/ic24Warning.svg');
                            background-size: 12px 12px;
                            height: 12px;
                            width: 12px;
                            // margin-right: 4px;
                        }
                        > .v-messages__message {
                            font-size: 8px;
                            color: $color_main_red;
                        }
                    }
                }

                * {
                    line-height: 1 !important;
                }
            }
        }
    }
}
</style>
