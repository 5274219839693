<template>
    <v-main>
        <BackOnTop :url="url" />
        <PageTitleBar title="大樹寵物會員服務條款" />
        <v-container class="register-container px-4 mb-4">
            <div class="version f_grey mt-2 mb-3">版本20210331-V2</div>
            <div class="contract text-body-1">
                <p class="mb-6">
                    歡迎您加入大樹寵物股份有限公司（以下簡稱「本公司」）的「大樹寵物」會員，請您務必先行詳細閱讀下列《大樹寵物會員服務條款》（以下簡稱「本服務條款」）內容
                </p>
                <h5 class="mt-8">大樹寵物會員服務條款</h5>
                <ul>
                    <li>
                        <h6 class="mt-6 mb-2">會員註冊：</h6>
                        <ul>
                            <li>
                                您完成註冊成為「大樹寵物」會員後，即視為您已經經過充份的審閱期間，也已經瞭解並同意接受本服務條款的所有內容。您如果未滿20歲，應取得您的家長、監護人及法定代理人同意後始得進行註冊，您完成註冊成為「大樹寵物」會員後，即視為您的家長、監護人及法定代理人均已經過充分的審閱期間，也已經瞭解並同意您接受本服務條款的所有內容、以及同意您的註冊。
                            </li>
                            <li>
                                本公司將定期或不定期修正本服務條款內容，並適時公布在本公司相關平台（包含「大樹寵物」實體門市、網站等），不會另外個別通知您。本服務條款的任一內容修正後，您如果繼續使用「大樹寵物」會員服務，便視為您已經完全瞭解並同意遵守該修正後的條款內容。
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h6 class="mt-6 mb-2">會員服務：</h6>
                        <ul>
                            <li>
                                您基於「大樹寵物」會員所得享有的會員權益均屬本公司所有，您不得以任何方式轉讓、移轉、出租或出借予其他任何第三人，且您轉讓、移轉、出租或出借前述權益予其他任何第三人的行為，對本公司均不生效力。
                            </li>
                            <li>
                                若「大樹寵物」舉辦任何形式之點數活動的活動方式、內容等，悉以本公司公布於「大樹寵物」官方網站的最新活動辦法為準，本公司將依該活動辦法辦理「大樹寵物」的相關事宜。您可以向「大樹寵物」實體門市或藉由本公司設置的點數查詢平台，查詢您累積的「大樹寵物」點數，但您瞭解「大樹寵物」點數的資料更新有時間差，並非即時顯示。
                            </li>
                            <li>
                                「大樹寵物」實體門市若提供您額外的「顧客換貨服務」，其注意事項請參見該實體門市公告，且本公司及該實體門市保有決定換貨與否、以及異動相關換貨方式、服務內容的最終權利。
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h6 class="mt-6 mb-2">會員資料：</h6>
                        <ul>
                            <li>
                                您如果拒絕提供完整、正確的個人資料（包含姓名、電話號碼及地址等）或未完成會員註冊，您將無法享有「大樹寵物」會員的權益。您如果提供或註冊的資料有不完整、不正確、或您的資料有更動卻未即時通知本公司更正者，您同意自負相關責任。
                            </li>
                            <li>
                                您應嚴防您的會員帳號及密碼外洩，一旦您外洩前述資料而遭他人留用、冒用者，均視為您的行為，並由您為該等行為負擔相關責任。
                            </li>
                            <li>
                                本公司將以合於產業標準之合理技術及程序保護您的個人資料，並於法律許可的範圍內依《大樹寵物會員隱私權政策》蒐集、處理、保存、傳遞及使用該等資料。
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h6 class="mt-6 mb-2">智慧財產權：</h6>
                        <p>
                            「大樹寵物」、「大樹寵物及其關係企業所轄經營之電子商城」、「大樹寵物及其關係企業所開發之各式通訊平台(如：APP應用程式等)」之會員服務的所有介面、資訊及內容，包括但不限於圖片、照片、網站架構、網站畫面及網站設計等及其等衍生物（下稱「服務內容」）均由本公司及/或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。除經本公司及/或其他權利人之事前書面同意外，任何人均不得逕自就服務內容為引用、轉載、使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。用戶不因下載本應用程式或建立帳號而取得本公司及/或其他權利人關於服務內容或其他任何智慧財產權之明示或默示之授權。尊重智慧財產權是您應盡的義務，如有違反，您應對本公司及/或其他權利人負擔損害賠償責任（包括但不限於商譽、訴訟費用及律師費用等）。
                            您所提供、傳輸內容皆歸屬本公司或本公司所指定之第三方所有。本公司為改善本服務之目的，可自由且無償地使用您於本服務中已公開發表之本服務相關資訊等內容。
                        </p>
                    </li>
                    <li>
                        <h6 class="mt-6 mb-2">其他約定：</h6>
                        <ul>
                            <li>
                                您若經本公司認定違反本服務條款任一約定，或本公司視情形認為有暫停或終止「大樹寵物」會員服務之必要者，本公司得逕行暫停或終止提供您「大樹寵物」會員服務的一部或全部，您不得向本公司主張及請求任何賠償、補償。
                            </li>
                            <li>
                                本服務條款的任何一部分如經法院認定無效，該部分的約定應以相關法律所允許的最大可能範圍內為解釋或補充，且其餘約定條款及其效力均不受到影響。
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h6 class="mt-6 mb-2">準據法及管轄：</h6>
                        <p>
                            本服務條款之解釋、補充及適用均以中華民國法律為準據法，如因本服務條款發生爭議，除有法律強制規定者外，均以臺灣桃園地方法院為第一審管轄法院。
                        </p>
                    </li>
                    <li>
                        <h6 class="mt-6 mb-2">購物權益說明：</h6>
                        <ul>
                            <li>
                                台端完成線上訂購程序，係表示向大樹寵物及其關係企業所轄經營之電子商城提出擬購買商品之要約，僅係代表已收到您向大樹寵物及其關係企業所轄經營之電子商城提出擬購買商品的要約通知，並非代表買賣契約已成立。
                            </li>
                            <li>
                                大樹寵物及其關係企業所轄經營之電子商城收到台端之訂購要約，會先確認台端擬購買之商品庫存數量及其他重要事項之確認，若經大樹寵物及其關係企業所轄經營之電子商城確認無誤後，將於出貨前以電子郵件發送「商品出貨通知函」予台端；若商品短缺或其他因素致訂單無法成立，大樹寵物及其關係企業所轄經營之電子商城將由專人、簡訊、電子郵件通知拒絕台端之要約，該買賣契約視為自始不成立。
                                大樹寵物隱私權政策
                                大樹寵物股份有限公司（以下簡稱「本公司」）為保障您個人資料之安全，以《大樹寵物隱私權政策》（以下簡稱「本隱私權政策」）讓您瞭解本公司對於您的隱私權之尊重與保護，並提供您有關您個人資料之相關資訊。
                            </li>
                        </ul>
                    </li>
                </ul>
                <h5 class="mt-8 mb-4">大樹寵物隱私權政策</h5>
                <ul>
                    <li>
                        您同意本公司於其營運地區及期間內，基於零售、行銷業務、應用程式使用及前述業務之管理、調查與研究分析、及改善前述業務之特定目的範圍內，得蒐集、處理、利用、下載及國際傳輸您與本公司因契約(如消費)或類似契約關係(如參與贈獎)或註冊本公司會員或使用大樹寵物及其關係企業所開發之各式通訊平台(如：APP應用程式等)相關功能及您為使用本公司或合作第三方(如金融機構、合作廠商、物流業者、政府機關、業務往來之大樹醫藥股份有限公司之關係企業)服務向本公司所提供的基本資料，以上資料包含姓名、電話、地址、電子郵件、帳務資料(依您使用之付款方式)及其他您所提供之資料（以下簡稱「個人資料」）。
                    </li>
                    <li>
                        依據法務部頒佈之「個人資料保護法之特定目的及個人資料之類別」，本公司蒐集、處理、利用及保有您個人資料之種類列示如下：<br />
                        C.○○一 辨識個人者。<br />
                        (例如：姓名、職稱、住址、地址、電話號碼、網路平臺申請之帳號、會員編號、電子郵遞地址及其他任何可辨識資料本人者等。)<br />
                        C.○○二 辨識財務者。<br />
                        (例如：金融機構帳戶之號碼與姓名、信用卡或簽帳卡之號碼、保險單號碼、個人之其他號碼或帳戶等。)<br />
                        C.○○三 政府資料中之辨識者。<br />
                        (例如：身分證統一編號、護照號碼等。) <br />
                        C.○一一 個人描述。<br />
                        (例如：年齡、性別、出生年月日、出生地、國籍、聲音等。)<br />
                        C.○二一 家庭情形。<br />
                        (例如：結婚有無、配偶或同居人之姓名、前配偶或同居人之姓名、結婚之日期、子女之人數等。)<br />
                        C.○三六 生活格調。<br />
                        (例如：使用消費品之種類及服務之細節、個人或家庭之消費模式等。)<br />
                        C.○九三 財務交易。<br />
                        (例如：收付金額、信用額度、保證人、支付方式、往來紀錄、保證金或其他擔保等。)<br />
                        C.一○二 約定或契約。<br />
                        (例如：關於交易、商業、法律或其他契約、代理等。) <br />
                        C.一三二 未分類之資料。<br />
                        (例如：無法歸類之信件、檔案、報告或電子郵件等。)
                    </li>

                    <li>
                        本公司將會基於提供商品銷售、金融交易授權、會員權益通知、物流配送、廣告行銷、市場分析、贈獎活動或其他附隨以上各樣服務及與本公司業務往來之所屬大樹醫藥股份有限公司之關係企業（包含大樹醫藥股份有限公司）基於廣告行銷、市場分析、贈獎活動等及未來所有可能衍生之相關服務之目的，運用或提供您的個人資料。依據法務部頒佈之「個人資料保護法之特定目的及個人資料之類別」，本公司蒐集、處理、利用及保有您個人資料之特定目的列示如下：<br />
                        040行銷<br />
                        063非公務機關依法定義務所進行個人資料之蒐集處理及利用<br />
                        067信用卡、現金卡、轉帳卡或電子票證業務<br />
                        069契約、類似契約或其他法律關係事務<br />
                        090消費者、客戶管理與服務 091消費者保護
                        098商業與技術資訊<br />
                        107採購與供應管理 111票券業務<br />
                        118智慧財產權、光碟管理及其他相關行政
                        125傳播行政與管理<br />
                        127募款 (包含公益勸募) 129會計與相關服務
                        132經營傳播業務<br />
                        136資 (通)訊與資料庫管理
                        148網路購物及其他電子商務服務<br />
                        152廣告或商業行為管理 157調查、統計與研究分析<br />
                        181其他經營合於營業登記項目或組織章程所定之業務
                    </li>
                    <li>
                        除經您的同意外，您並同意本公司得在下列情況下基於前述目的之必要運用或提供您的個人資料予相關第三方：
                        <ul>
                            <li>於前述目的範圍作業利用之必要。</li>
                            <li>
                                合於個人資料保護法第20條但書規定為特定目的外之利用
                            </li>
                            <li>
                                基於法律之規定或受司法機關與其他有權機關基於法定程序之要求
                            </li>
                            <li>
                                因消費行為或參與會員活動、行銷活動而產生的之必要資訊。
                            </li>
                        </ul>
                    </li>
                    <li>
                        您瀏覽或登入使用「大樹寵物」會員服務時，您同意本公司自動記錄您的設備資料，包含互聯網協定位址
                        ( IP Address
                        )及您瀏覽的網頁記錄、cookie等，及允許大樹寵物及其關係企業所開發之各式通訊平台(如：APP應用程式等)追蹤您在其他公司APP和網站的紀錄，作為分析使用者的喜好與習慣、統計瀏覽人次及流量並根據使用者的喜好與習慣調整內容使用，以為您提供更好的服務。
                        <ul>
                            <li>
                                帳號相關資訊及使用本服務時的裝置資訊和訪問日誌為向您提供本服務，當您在本應用程式中建立帳號時，本應用程式將要求您提供包括但不限於您的姓名、暱稱、手機號碼、地址等個人資料。<br />
                                建立帳號並登入後，您可以隨時在本應用程式中設定、管理您的個人資料。<br />
                                本應用程式並將於您帳號的有效期間內，使用您所儲存的資訊協助建置、更新及維護本服務之內容，包括但不限於向您傳送驗證碼或其他形式的帳號驗證資訊，或提供您與本服務有關的重要或最新資訊等。<br />
                                當您使用本服務時，本應用程式可能會取得並保存您的裝置所提供的某些電子標識和日誌資料，包括但不限於裝置類型、作業系統類型、IP位址、瀏覽器資訊，其中包括您下載與使用本應用程式之路徑、裝置類型和語言設定、裝置識別碼、Cookie資料、廣告識別碼或行動應用程式識別碼等。這些資訊是本應用程式持續偵測和阻止未經授權使用和惡意使用或濫用本服務而努力的一部分，也是向您提供本服務中各項功能和針對您裝置而優化之服務所不可欠缺者。<br />
                            </li>
                            <li>
                                您的應用程式搜尋紀錄<br />
                                為提供您更完整且貼近您需求的服務體驗，本應用程式會蒐集您透過應用程式各項功能中建置之搜尋欄位所輸入之搜尋內容，包括但不限於您所搜尋之衛教資訊主題等。蒐集的目的主要係為了記錄您的需求、偏好瞭解的健康新知資訊等，以向您提供您可能有興趣之主題通知。<br />
                            </li>
                            <li>
                                Cookies之運用<br />
                                a.Cookie是從網站傳送到瀏覽器，並保存在使用者電腦硬碟中的簡短資料。為提供個人化的服務，當您使用本服務時，本應用程式有時會在您的電腦上設定與存取Cookie。<br />
                                b.您可以透過設定您的個人電腦或上網設備，決定是否允許Cookie技術的使用，如果您選擇拒絕所有的Cookies時，可能會造成您使用本服務之不便或部份功能無法正常執行。
                            </li>
                            <li>
                                如何使用您的或您所提供的個人資料<br />
                                a.使用您個人資料的方式<br />
                                為了提供本服務，作為資料控管者或共同資料控管者，本公司將根據本政策，依照下列目的與方式使用您的個人資料：<br />
                                以相關技術向您傳送通知或其他訊息，以核實您的身分；<br />
                                以相關技術維護、更新您的個人資料；<br />
                                以相關技術與資訊系統進行連結、資料交換、取得您的資料，以建立、維護、更新您在資訊系統中之資訊，或依照您所要求之服務內容，提供輸入、儲存、辨識、下載您的個人資料等功能與服務；<br />
                                以相關技術提高本服務水準、優化本服務品質，為您提供符合個人需求的客製化服務；<br />
                                以相關技術監控、發現並制止未經授權使用服務、不正當獲取服務或濫用本服務等行為；<br />
                                以相關技術向您進行本服務相關之調查；<br />
                                在適用法律容許的範圍內，以相關技術提供廣告或評估我們的廣告效果。對於此等廣告您隨時有權通知本公司停止；<br />
                                以相關技術產出、積累、傳輸與本服務相關的匿名或去識別化統計資料；及遵守適用法律，履行法定義務。<br />
                                b.您個人資料的保存期間<br />
                                本應用程式在以下情形下會保留您所提供或同意本應用程式蒐集的個人資料：<br />
                                您將個人資料儲存於本應用程式及本服務之期間內；<br />
                                履行本政策所需期間內；<br />
                                適用法律要求的期間內；<br />
                                及其他本公司認為適當之期間內。<br />
                                若為下列目的所合理需要，我們將在您停止使用本服務後繼續留存您的個人資料：<br />
                                為提供及完成客戶支援服務；<br />
                                為留存您同意本政策之紀錄；<br />
                                為符合適用法律之規定或履行法定義務；<br />
                                在適用的時效期限內，解決本服務與用戶之間、本服務與服務供應商或協力廠商之間、用戶與本服務之服務供應商或協力廠商之間、服務供應商或協力廠商之間或用戶之間的爭議；<br />
                                偵測或制止未經授權使用、不正當使用或濫用本服務的行為；及其他本公司認為適當之原因。
                            </li>
                        </ul>
                    </li>

                    <li>
                        本公司將以合於產業標準之合理技術及程序，維護您的個人資料安全。
                        <ul>
                            <li>
                                保護您個人資料安全的方式 <br />
                                本公司為了確保本應用程式安全及保護您個人資料，本網站主機均設有入侵偵測防禦系統、防火牆、防毒系統等相關資訊安全設備及必要的安全防護，並依照法律之要求對本應用程式及您個人資料做嚴格保護。<br />
                                由於任何電子傳輸方法或資料儲存方法都不是無懈可擊，本公司無法擔保您的個人資料在系統傳輸或儲存過程中百分之百安全，不被攻擊。<br />
                                為協助保護您的隱私和個人資料安全，需要您的協助。請不要將您的密碼或個人資料告知未經您允許之他人或使用與其他服務相同的密碼。若您是與他人共享電腦或使用公共電腦，在完成本服務程序後，務必記得登出帳號、關閉瀏器視窗，以防止他人讀取使用您的資料。同時，若您懷疑本服務出現任何未經授權使用您個人資料的行為，或任何其他不安全行為，請通知本公司。
                            </li>
                            <li>
                                使用外部服務供應商或協力廠商的情況<br />
                                請注意，本服務之服務供應商或協力廠商，可能以包括但不限於蒐集、儲存、管理、分析、運算、統計、推播、去識別化等方式蒐集與使用用戶資料，當中可能包括但不限於您於本服務中所提供的所有資訊與個人資料。<br />
                                本服務之服務供應商與協力廠商包括但不限於：應用程式、網站、資訊系統之開發者、設計者、維護者、資訊提供者、專業顧問、分析管理者等。
                            </li>
                        </ul>
                    </li>
                    <li>
                        您已確實知悉可依據中華民國「個人資料保護法」第3
                        條規定，向本公司要求下列權益：<br />
                        (1)查詢或請求閱覽；<br />
                        (2)請求製給複製本（本公司得收取必要費用）；<br />
                        (3)請求補充或更正；<br />
                        (4)請求停止蒐集、處理及利用；<br />
                        (5)請求刪除。<br />
                        且您得透過E-MAIL或客服、書面等方式向本公司行使您的權益。一經核實您的身分，本公司將在合理的期間內根據相關法律就您的要求予以回覆。
                    </li>
                    <li>
                        您的確認與同意<br />
                        您確認以下內容真實無誤：<br />
                        您已詳細閱讀、瞭解、確認並同意本政策之全部條款與關聯文件，並會隨時注意本政策之更新及相關通知。<br />
                        您已瞭解、確認並同意本公司將依照本政策對您的個人資料進行蒐集與使用。<br />
                        您確認您已年滿20歲，或具有完全行為能力。<br />
                        您已瞭解、確認並同意本公司就您所提供或同意本公司蒐集與使用之下述人員之個人資料，本公司集的目的、個人資料類別、使用的期間、地區、對象及方式：<br />
                        您本人；<br />
                        您未滿20歲且未結婚之未成年子女；<br />
                        您所監護或受您輔助之人；及同意您蒐集與使用其個人資料之親友。<br />
                        您已瞭解、確認並同意就您所提供或同意本公司蒐集與使用之非您本人的個人資料：<br />
                        您已向個人資料當事人說明本政策之內容，並已取得其就本政策全部內容所為之同意；<br />
                        您係依照適用法律之規定，本於受您扶養、照顧、監護、輔助之人的最佳利益考量，由您代為同意本政策；及您所提供之非您本人的個人資料係符合適用法律規定無須告知個人資料當事人之例外情形。<br />
                        且就上述事項，本公司並無查證義務。<br />
                        您已瞭解、確認並同意本公司無義務查證您所提供或同意本公司蒐集與使用的個人資料的正確性，並僅於適用法律明文規定之範圍內對於個人資料當事人負有告知或其他法定義務。<br />
                        您已瞭解、確認並同意，就本公司依照本政策所蒐集與使用之個人資料，您依照適用法律可以行使的權利，以及行使方式。<br />
                        您已瞭解、確認並同意，您選擇不提供本服務中個別功能所要求的個人資料，或要求本公司停止蒐集、停止使用或刪除您過去所提供之個人資料，對您使用本服務可能產生之影響。<br />
                        您已瞭解、確認並同意，當您下載、開啟、登入本應用程式或以其他任何形式使用本服務，即表示您已經以電子簽章法所定之電子文件發出同意本政策條款之書面意思表示，這代表您確認您本人即為您使用之電子設備、行動裝置或電子郵件信箱帳戶之擁有者，且為個人資料之當事人、提供者及、或本政策通知之收件人本人，並已詳細閱讀、瞭解、確認並同意本政策所述之全部內容，且同意本公司在法律允許之範圍內，依照本政策蒐集與使用您的個人資料，相關之意思表示，均是您本人所為。本公司會留存您的同意紀錄，以作為後續證明您同意本政策內容之資料。<br />
                    </li>
                    <li>
                        其他重要資訊<br />
                        本政策的變更<br />
                        本公司可能出於各種原因（包括但不限於為了改進本政策）而隨時修訂本政策，以反映出本服務的變化以及遵循相關法律。<br />
                        若本公司對本政策作出調整，本公司將不定時公布於本應用程式中或其他本公司認為適當之處，或透過其他方式通知您；您應隨時注意該等調整或追加並仔細查看更改細節。只要您於任何調整或追加後繼續使用本服務，即表示您確認您已閱讀、瞭解並同意接受調整後之本政策內容。
                    </li>
                </ul>
            </div>
            <!-- <FancyButton
                label="同意"
                width="150"
                height="48"
                @click="$router.push('/register/pet')"
            /> -->
        </v-container>
    </v-main>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
import PageTitleBar from '@/components/PageTitleBar.vue';
// import FancyButton from '@/components/FancyButton.vue';

export default {
    name: 'RegisterContract',
    components: { BackOnTop, PageTitleBar },
    data: () => ({ 
        url:"",
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    created(){
        if(this.$route.query.url == "member") this.url = "";
        else{
            this.url = {
                path:'/register',
                query:this.$route.query
            }
        }
        
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.state.isLoading = false;
        });
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.register-container {
    padding-bottom: 36px;

    * {
        font-size: 14px;
        line-height: 1.4;
    }
    .version {
        text-align: left;
    }
    h5 {
        font-weight: bold;
        font-size: 20px;
        color: $color_main_black;
    }
    h6 {
        font-weight: bold;
    }
    .contract {
        color: $color_main_black;
        font-size: 14px !important;
        line-height: 24px !important;
        text-align: left;
        margin-bottom: 36px;
        > ul {
            list-style-type: cjk-ideographic;
            counter-reset: li;
            padding-left: 30px;
            list-style: none;

            > li {
                counter-increment: li;
                list-style-type: cjk-ideographic;
                position: relative;
                margin-bottom: 10px;

                > ul {
                    counter-reset: a;
                    padding-left: 30px;
                    list-style: none;

                    > li {
                        position: relative;
                        margin-bottom: 10px;

                        &::before {
                            counter-increment: a;
                            content: '(' counter(a, cjk-ideographic) ')';
                            position: absolute;
                            left: 0;
                            transform: translate(-120%, 0);
                        }
                    }
                }
            }
        }
    }
}
</style>
