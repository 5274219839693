<template>
    <v-main>
        <BackOnTop />
        <PageTitleBar title="預約項目" />
        <SalonSubtitle
            v-if="false"
            showRight
            textLeft="請點選您所需要的服務"
            textRight="價目表"
            @click:right="handleRight"
        />
        <v-container fluid class="salon-container mt-14">
            <ServiceSelection
                class="mb-6"
                v-model="checkedSelect"
                :options="optionsSelection"
                :value="checkedSelect"
            />
            <ServiceChipGroup
                class="px-4 mb-8"
                v-model="checkedChip"
                :options="optionsChipGroup"
                :value="checkedChip"
            />
            <div class="footer fluid d_flex justify-center mb-6">
                <FancyButton
                    class="btn"
                    label="選擇預約時段"
                    width="150"
                    height="48"
                    @click.prevent="goNext()"
                />
            </div>
        </v-container>
        <LB :open="handleLB" @closeLb="closeLB" class="lb" isPrice>
            <div class="d_flex">
                <img src="@/assets/images/salon_price.png" class="paw" alt="" />
            </div>
        </LB>
    </v-main>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
import PageTitleBar from '@/components/PageTitleBar.vue';
import SalonSubtitle from '@/components/SalonSubtitle.vue';
import ServiceSelection from '@/components/ServiceSelection.vue';
import ServiceChipGroup from '@/components/ServiceChipGroup.vue';
import FancyButton from '@/components/FancyButton.vue';
import LB from '@/components/LB.vue';
import { getReserveList } from '@/apis/api.js';

export default {
    name: 'SalonReservedSelect',
    components: {
        BackOnTop,
        PageTitleBar,
        SalonSubtitle,
        ServiceSelection,
        ServiceChipGroup,
        FancyButton,
        LB,
    },
    data: () => ({
        handleLB: false,
        optionsSelection: [
            { title: '剪毛', imgSrc: 'imgCut.jpg' },
            { title: '洗澡', imgSrc: 'imgBath.jpg' },
            { title: '游泳', imgSrc: 'imgSwim.jpg' },
            // { title: '草本SPA', imgSrc: 'imgSpa.jpg' },
        ],
        optionsChipGroup: ['洗澡+SPA', '洗澡+游泳', '剪毛+SPA', '剪毛+游泳'],
        checkedSelect: [],
        checkedChip: [],
        query:{}
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    created(){
        this.query = this.$route.query;
        if (this.query) {
            this.checkedSelect = [this.query.type];
        }
    },
    mounted(){
        var parent = this;
        this.$nextTick(() => {
            this.$store.state.isLoading = false;
        });
        setTimeout(function(){
            getReserveList(localStorage.getItem('phone'),localStorage.getItem('password')).then(function(rsp){
                for(let i in rsp){
                    if(rsp[i].c_pbsapptno == parent.$route.query.reserve_no){
                        // 洗澡:0，剪毛:1，游泳:2，游泳+SPA:3，洗澡+游泳:4，剪毛+SPA:5，剪毛+游泳:6
                        if(rsp[i].e_resv_item == "0") parent.checkedSelect = ['洗澡'];
                        if(rsp[i].e_resv_item == "1") parent.checkedSelect = ['剪毛'];
                        if(rsp[i].e_resv_item == "2") parent.checkedSelect = ['游泳'];
                        if(rsp[i].e_resv_item == "3") parent.checkedChip = ['游泳+SPA'];
                        if(rsp[i].e_resv_item == "4") parent.checkedChip = ['洗澡+游泳'];
                        if(rsp[i].e_resv_item == "5") parent.checkedChip = ['剪毛+SPA'];
                        if(rsp[i].e_resv_item == "6") parent.checkedChip = ['剪毛+游泳'];
                    }    
                }
            })
        },500)
    },
    methods: {
        handleRight() {
            this.handleLB = true;
        },
        closeLB() {
            this.handleLB = false;
        },
        goNext() {
            if(this.checkedChip.length != 0){
                this.query.type = this.checkedChip;
            } else {
                this.query.type = this.checkedSelect;
            }
            if(this.checkedChip.length != 0 || this.checkedSelect.length != 0) this.$router.push({path:'/salon/reserved/time',query:this.query})
        }
    },
    watch: {
        checkedSelect(){
            var parent = this;
            console.log(this.checkedSelect,this.checkedChip);
            if(this.checkedSelect.length != 0) parent.checkedChip = [];
        },
        checkedChip(){
            var parent = this;
            console.log(this.checkedSelect,this.checkedChip);
            if(this.checkedChip.length != 0) parent.checkedSelect = [];
        }
    }
};
</script>

<style lang="scss" scoped>
.salon-container {
}
</style>
