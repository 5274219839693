<template>
    <v-main>
        <BackOnTop />
        <PageTitleBar title="忘記密碼" />
        <v-container fluid class="fp-container px-4 mb-4">
            <p class="desc mb-6">忘記密碼了?請輸入您註冊的手機重設密碼吧!</p>
            <v-form ref="form" v-model="valid" lazy-validation>
                <FancyInput
                    v-model="phone"
                    label="手機"
                    iconName="ic24Phone.svg"
                    :rules="[() => err_msg || !!phone || '請輸入正確手機']"
                    required
                />
            </v-form>
            <BtnNext class="btn" @click="handleNext" />
        </v-container>
    </v-main>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
import PageTitleBar from '@/components/PageTitleBar.vue';
import BtnNext from '@/components/BtnNext.vue';
import FancyInput from '@/components/FancyInput.vue';
import { checkPhoneExist } from '@/apis/api.js';
export default {
    name: 'ForgotPasswordIndex',
    components: { BackOnTop, PageTitleBar, BtnNext, FancyInput },
    data: () => ({
        valid: '',
        phone: '',
        err_msg: ''
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.state.isLoading = false;
        });
    },
    methods: {
        handleNext() {
            const valid = this.$refs.form.validate();
            var parent = this;
            if (valid) {
                checkPhoneExist(this.phone).then(function(rsp){
                    if(rsp){
                        parent.$router.push('/forgot-password/otp?phone=' + parent.phone);
                    } else {
                        parent.err_msg = '此手機號碼非註冊會員，請回上一頁註冊會員';
                        parent.$refs.form.validate();
                    }
                })
                
            }
        },
    },
    watch: {
        "phone": function(){
            this.err_msg = "";
        }
    }
};
</script>

<style lang="scss" scoped>
.fp-container {
    margin: 12px 0;
    padding: 10px 0 0 0;
    p.desc {
        font-size: 16px;
        line-height: 24px;
        color: $color_main_black;
        text-align: left;
    }
    .btn {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: 52px;
    }
}
</style>
