<template>
    <img class="logo" alt="logo" src="../assets/images/logoL.svg" />
</template>

<script>
export default {
    name: 'Redirect',
    components: {
        // HelloWorld,
    },
    created () {
        var parent = this;
        // 先綁fcm_token/device_id
        if(parent.$route.query.fcm_token) localStorage.setItem('fcm_token', parent.$route.query.fcm_token);
        setTimeout(function(){
            parent.$router.push('/')
        },1000*3)
    },
    mounted() {
        this.$store.state.isLoading = false;
    }
};
</script>
<style scoped>
.logo {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
}
</style>
