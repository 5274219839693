var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',[_c('BackOnTop',{attrs:{"showRight":""},on:{"click:right":_vm.handleSave}}),_c('PopupAlert',{attrs:{"errorMsg":_vm.msg,"imgSrc":_vm.imgSrc},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}}),_c('PopupRegisterGift',{attrs:{"giftQuantity":_vm.giftQuantity},on:{"click:confirm":_vm.handleRegisterGift,"click:close":function($event){return _vm.$router.push('/')}},model:{value:(_vm.showRegisterGift),callback:function ($$v) {_vm.showRegisterGift=$$v},expression:"showRegisterGift"}}),_c('PageTitleBar',{attrs:{"title":"修改個人資料"}}),_c('v-container',{staticClass:"member-container px-4 mb-4",attrs:{"fluid":""}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-0 mb-6"},[_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancyInput',{attrs:{"label":"帳號*","iconName":"ic24AccountGrey.svg","disabled":""},model:{value:(_vm.form.account),callback:function ($$v) {_vm.$set(_vm.form, "account", $$v)},expression:"form.account"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancyInput',{attrs:{"label":"姓名*","iconName":"ic24Uname.svg","rules":[() => !!_vm.form.name || '請輸入姓名'],"required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"6","sm":"6"}},[_c('FancySelect',{attrs:{"label":"生日 年","iconName":"ic24Birthday.svg","items":_vm.yearOptions,"options":_vm.yearOptions},model:{value:(_vm.form.birthday_year),callback:function ($$v) {_vm.$set(_vm.form, "birthday_year", $$v)},expression:"form.birthday_year"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"3","sm":"3"}},[_c('FancySelect',{attrs:{"label":"月","options":[
                            {
                                text: '月',
                                value: '',
                                selected: true,
                                disabled: true,
                            },
                            { text: '01', value: '01' },
                            { text: '02', value: '02' },
                            { text: '03', value: '03' },
                            { text: '04', value: '04' },
                            { text: '05', value: '05' },
                            { text: '06', value: '06' },
                            { text: '07', value: '07' },
                            { text: '08', value: '08' },
                            { text: '09', value: '09' },
                            { text: '10', value: '10' },
                            { text: '11', value: '11' },
                            { text: '12', value: '12' },
                        ],"items":[
                            {
                                text: '月',
                                value: '',
                                selected: true,
                                disabled: true,
                            },
                            { text: '01', value: '01' },
                            { text: '02', value: '02' },
                            { text: '03', value: '03' },
                            { text: '04', value: '04' },
                            { text: '05', value: '05' },
                            { text: '06', value: '06' },
                            { text: '07', value: '07' },
                            { text: '08', value: '08' },
                            { text: '09', value: '09' },
                            { text: '10', value: '10' },
                            { text: '11', value: '11' },
                            { text: '12', value: '12' },
                        ]},model:{value:(_vm.form.birthday_month),callback:function ($$v) {_vm.$set(_vm.form, "birthday_month", $$v)},expression:"form.birthday_month"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"3","sm":"3"}},[_c('FancySelect',{attrs:{"label":"日","options":_vm.dayOptions,"items":_vm.dayOptions},model:{value:(_vm.form.birthday_day),callback:function ($$v) {_vm.$set(_vm.form, "birthday_day", $$v)},expression:"form.birthday_day"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancySelect',{attrs:{"label":"生理性別*","iconName":"ic24UGender.svg","rules":[() => !!_vm.form.gender || '請輸入生理性別'],"options":[
                            {
                                text: '生理性別*',
                                value: '',
                                selected: true,
                                disabled: true,
                            },
                            { text: '女', value: 'female' },
                            { text: '男', value: 'male' },
                        ],"items":[
                            {
                                text: '生理性別*',
                                value: '',
                                selected: true,
                                disabled: true,
                            },
                            { text: '女', value: 'female' },
                            { text: '男', value: 'male' },
                        ],"required":""},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancySelect',{attrs:{"label":"註冊門市*","iconName":"ic24Store.svg","items":_vm.shopOptions,"options":_vm.shopOptions,"required":""},model:{value:(_vm.form.reg_storeno),callback:function ($$v) {_vm.$set(_vm.form, "reg_storeno", $$v)},expression:"form.reg_storeno"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancyInput',{attrs:{"label":"身分證末三碼*","iconName":"ic24Account.svg","type":"text","rules":[
                            () =>
                                _vm.form.id_card.length == 3
                                    ? true
                                    : '身分證末三碼不符合格式',
                        ],"required":""},model:{value:(_vm.form.id_card),callback:function ($$v) {_vm.$set(_vm.form, "id_card", $$v)},expression:"form.id_card"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancySelect',{attrs:{"label":"縣市*","iconName":"ic24City.svg","rules":[() => !!_vm.form.county || '請輸入縣市'],"items":_vm.country_list,"options":_vm.country_list,"required":""},model:{value:(_vm.form.county),callback:function ($$v) {_vm.$set(_vm.form, "county", $$v)},expression:"form.county"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancySelect',{attrs:{"label":"區域+郵遞區號*","iconName":"ic24Post.svg","items":_vm.zip_select_list,"options":_vm.zip_select_list,"rules":[() => !!_vm.form.district || '請輸入郵遞區號'],"required":""},model:{value:(_vm.form.district),callback:function ($$v) {_vm.$set(_vm.form, "district", $$v)},expression:"form.district"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancyInput',{attrs:{"label":"地址*","iconName":"ic24Address.svg","rules":[
                            () =>
                                _vm.form.address
                                    ? _vm.form.address.length <= 40
                                        ? true
                                        : '地址超過40字'
                                    : '請輸入地址',
                        ],"required":""},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancyInput',{attrs:{"label":"住家電話","iconName":"ic24Tele.svg","type":"number","rules":[
                            () =>
                                _vm.form.telephone.length <= 20
                                    ? true
                                    : '住家電話超過20字',
                        ]},model:{value:(_vm.form.telephone),callback:function ($$v) {_vm.$set(_vm.form, "telephone", $$v)},expression:"form.telephone"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancyInput',{attrs:{"label":"電子信箱","iconName":"ic24Email.svg","type":"email","rules":[
                            () =>
                                _vm.validateEmail(_vm.form.email)
                                    ? true
                                    : !!_vm.form.email
                                    ? '不符合信箱格式或長度超過100字'
                                    : true,
                        ]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }