<template>
    <v-main>
        <BackOnTop showRight @click:right="handleSave" />
        <PopupAlert v-model="showAlert" :errorMsg="errorMsg" :imgSrc="imgSrc" />
        <!-- <FancySnackbar v-model="showFailAlert" label="舊密碼錯誤" /> -->
        <PageTitleBar title="修改密碼" />
        <v-container fluid class="member-container px-4 mb-4">
            <v-form ref="form" v-model="valid" lazy-validation>
                <FancyInput
                    v-model="oldPassword"
                    label="請輸入舊密碼"
                    iconName="ic24Lock.svg"
                    hint="6-12位英文及數字混和的密碼"
                    persistent-hint
                    :rules="[
                        () =>
                            !!oldPassword || '請輸入6-12位英文及數字混和的密碼',
                    ]"
                    type="password"
                    required
                    class="mb-6"
                />
                <FancyInput
                    v-model="newPassword"
                    label="請輸入新密碼"
                    iconName="ic24Lock.svg"
                    hint="6-12位英文及數字混和的密碼"
                    persistent-hint
                    :rules="[
                        () =>
                            (!!newPassword && newPassword.match(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,12}$/) != null)|| '請輸入6-12位英文及數字混和的密碼',
                    ]"
                    type="password"
                    required
                    class="mb-6"
                />
                <FancyInput
                    v-model="newPassword2"
                    label="確認新密碼"
                    iconName="ic24Lock.svg"
                    hint="請再次輸入跟上方相同的密碼"
                    persistent-hint
                    :rules="[
                        () =>
                            (!!newPassword2 && newPassword == newPassword2 && newPassword2.length >= 6 && newPassword2.length <= 12) ||
                            '兩次密碼輸入不相同',
                    ]"
                    type="password"
                    required
                />
            </v-form>
        </v-container>
    </v-main>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
import PageTitleBar from '@/components/PageTitleBar.vue';
import FancyInput from '@/components/FancyInput.vue';
import { updatePassword, KgUpdatePassword } from '@/apis/api.js';
import PopupAlert from '@/components/Popup/PopupAlert.vue';

export default {
    name: 'MemberPassword',
    components: { PopupAlert, BackOnTop, PageTitleBar, FancyInput },
    data: () => ({
        valid: '',
        oldPassword: '',
        newPassword: '',
        newPassword2: '',
        showAlert: false,
        errorMsg:'',
        imgSrc:''
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.state.isLoading = false;
        });
    },
    methods: {
        handleSave() {
            var parent = this;
            const valid = this.$refs.form.validate();
            if (valid) {
                updatePassword(localStorage.getItem('phone'),this.oldPassword,this.newPassword).then(function(rsp){
                    if(rsp){
                        localStorage.setItem('password',parent.newPassword);
                        KgUpdatePassword(localStorage.getItem('phone'),localStorage.getItem('member_no'),parent.oldPassword,parent.newPassword).then(function(rsp){
                            if(rsp){
                                parent.showAlert = true;
                                parent.imgSrc = '@/assets/images/imgTick.svg'
                                parent.errorMsg = '密碼已修改'
                            } else {
                                parent.showAlert = true;
                                parent.imgSrc = '@/assets/images/imgWarning.svg'
                                parent.errorMsg = '舊密碼錯誤'
                            }
                        })
                    } else {
                        parent.showAlert = true;
                        parent.imgSrc = '@/assets/images/imgWarning.svg'
                        parent.errorMsg = '舊密碼錯誤'
                    }
                })
            } else {
                if(parent.newPassword != parent.newPassword2){
                    parent.showAlert = true;
                    parent.imgSrc = '@/assets/images/imgWarning.svg'
                    parent.errorMsg = '兩次密碼輸入不相同'
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.member-container {
    margin: 12px 0;
    padding: 10px 0 0 0;
    p.desc {
        font-size: 16px;
        line-height: 24px;
        color: $color_main_black;
        text-align: left;
    }
    .btn {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: 52px;
    }
}
</style>
