<template>
    <div class="info-box">
        <v-row v-for="(info, idx) in infoList" :key="idx">
            <v-col class="text-left">
                <p>{{ info.key }}</p>
            </v-col>
            <v-col class="text-right">
                <p>{{ info.value }}</p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'SalonInfoBox',
    props: {
        infoList: {
            type: Array,
        },
    },
    data: () => ({}),
};
</script>

<style lang="scss" scoped>
.info-box {
    background: #f9f6f4;
    border-radius: 16px;
    padding: 24px;
}
p {
    color: $color_main_black;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}
.row + .row {
    margin-top: 8px;
}
</style>
