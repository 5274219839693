<!-- Layout -->
<template>
    <div>
        <slot />
        <!-- Page content will be inserted here  -->
        <div id="nav">
            <ul class="d_flex">
                <li v-for="(el, idx) in navList" :key="idx">
                    <router-link :to="el.href">
                        <img
                            :src="
                                require(`../assets/images/${el.imgSrc}${
                                    currentRouteName === idx ? 'On' : 'Off'
                                }.svg`)
                            "
                            alt=""
                        />
                        <p
                            :class="{
                                active:
                                    currentRouteName === idx ? 'active' : '',
                            }"
                        >
                            {{ el.name }}
                        </p>
                        <font v-if="!!el.notify" class="notify">{{
                            el.notify
                        }}</font>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: 'LayoutWithNav',
    data: () => ({
        navList: [
            { name: '首頁', href: '/', imgSrc: 'tabIcHome', notify: 0 },
            { name: '消息', href: '/info/', imgSrc: 'tabIcNews', notify: 3 },
            {
                name: '會員中心',
                href: '/member',
                imgSrc: 'tabIcMember',
                notify: 0,
            },
        ],
    }),
    computed: {
        currentRouteName() {
            const { path } = this.$route;
            switch (path) {
                case '/':
                case '/info/coupon':
                    return 0;
                case '/info/':
                case '/msg':
                    return 1;
                default:
                    return 2;
            }
        },
    },
};
</script>
<style lang="scss">
#nav {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    height: 58px;
    background-color: #fff;
    max-width: 700px;
    border-top: 1px solid #e6e6e6;
    display: none;
    ul {
        padding: 8px 0 0;
        list-style: none;

        li {
            width: 33.33%;
            position: relative;
            p {
                margin-top: -5px;
                font-size: 12px;
                color: $color_main_grey;
                &.active {
                    color: $color_main_first;
                }
            }
            .notify {
                margin-left: 12px;
                left: 50%;
                top: -5px;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                line-height: 15px;
                text-align: center;
                width: 17px;
                height: 17px;
                border-radius: 50%;
                color: #fff;
                background-color: $color_main_first;
            }
        }
    }
}
body {
    padding-bottom: 70px;
}
</style>
