<template>
    <v-dialog class="fancy" v-bind="{ ...$attrs }" v-on="$listeners">
        <div class="dialog_box salon-rating-popup">
            <v-container fluid class="dialog_container">
                <p class="rating-popup--title mb-4">美容服務評比</p>
                <SalonRating class="mb-5" v-model="rating" />
                <p class="rating-popup--title mb-4">體驗回饋</p>
                <FancyTextarea
                    class="mb-6"
                    v-model="feedback"
                    placeholder="您的回饋是我們最重視的建議，您對於本次服務的感想如何呢?"
                    height="130"
                    counter="100"
                    maxlength="100"
                />
                <div class="footer d-flex justify-center">
                    <FancyButton
                        class="btn"
                        block
                        label="送出評比"
                        isGreen
                        @click="eval()"
                    />
                </div>
            </v-container>
        </div>
    </v-dialog>
</template>

<script>
import FancyButton from '@/components/FancyButton.vue';
import FancyTextarea from '@/components/FancyTextarea.vue';
import SalonRating from '@/components/SalonRating.vue';
import { KgEval, createFeedback} from '@/apis/api.js';
export default {
    components: {
        FancyButton,
        FancyTextarea,
        SalonRating,
    },
    name: 'PopupSalonRating',
    props: {
        serviceList: {
            type: Array,
        },
        STO_No:{
            type: String
        },
        PBS_ApptNo:{
            type: String
        }
    },
    emits: ['input', 'click:confirm'],
    data: () => ({ rating: 5, feedback: '' }),
    methods: {
        eval(){
            var parent = this;
            if(this.rating && this.feedback){
                KgEval(localStorage.getItem('phone'),localStorage.getItem('member_no'),parent.STO_No,parent.PBS_ApptNo,parent.feedback).then(function(rsp){
                    if(rsp){
                        createFeedback(localStorage.getItem('phone'),localStorage.getItem('password'),parent.PBS_ApptNo,parent.STO_No,parent.rating, parent.feedback).then(function(rsp){
                            if(rsp) parent.$emit('click:confirm');
                        })    
                    }
                })
            }
        }
    },
};
</script>

<style lang="scss" scoped>
div.v-dialog {
    > .dialog_box {
        &.salon-rating-popup {
            padding: 24px 20px;
            p.rating-popup--title {
                color: $color_main_black;
                font-size: 20px;
                line-height: 24px;
            }
        }
    }
}
</style>
