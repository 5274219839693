<template>
    <div class="page_title_bar d_flex pl-4 pt-1">
        <h1>{{ title }}</h1>
        <img
            v-if="hasLogo"
            class="logo"
            alt="logo"
            src="@/assets/images/logo.svg"
        />
    </div>
</template>

<script>
export default {
    name: 'PageTitleBar',
    props: {
        title: String,
        hasLogo: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.page_title_bar {
    height: 50px;
    align-items: center;
    justify-content: start;
    > h1 {
        color: $color_main_first;
        font-weight: bold;
        font-size: 26px;
        margin-right: 10px;
    }
}
</style>
