var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',[_c('BackOnTop',{attrs:{"showRight":""},on:{"click:right":_vm.handleSave}}),_c('PopupAlert',{attrs:{"errorMsg":_vm.errorMsg,"imgSrc":_vm.imgSrc},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}}),_c('PageTitleBar',{attrs:{"title":"修改密碼"}}),_c('v-container',{staticClass:"member-container px-4 mb-4",attrs:{"fluid":""}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('FancyInput',{staticClass:"mb-6",attrs:{"label":"請輸入舊密碼","iconName":"ic24Lock.svg","hint":"6-12位英文及數字混和的密碼","persistent-hint":"","rules":[
                    () =>
                        !!_vm.oldPassword || '請輸入6-12位英文及數字混和的密碼',
                ],"type":"password","required":""},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('FancyInput',{staticClass:"mb-6",attrs:{"label":"請輸入新密碼","iconName":"ic24Lock.svg","hint":"6-12位英文及數字混和的密碼","persistent-hint":"","rules":[
                    () =>
                        (!!_vm.newPassword && _vm.newPassword.match(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,12}$/) != null)|| '請輸入6-12位英文及數字混和的密碼',
                ],"type":"password","required":""},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('FancyInput',{attrs:{"label":"確認新密碼","iconName":"ic24Lock.svg","hint":"請再次輸入跟上方相同的密碼","persistent-hint":"","rules":[
                    () =>
                        (!!_vm.newPassword2 && _vm.newPassword == _vm.newPassword2 && _vm.newPassword2.length >= 6 && _vm.newPassword2.length <= 12) ||
                        '兩次密碼輸入不相同',
                ],"type":"password","required":""},model:{value:(_vm.newPassword2),callback:function ($$v) {_vm.newPassword2=$$v},expression:"newPassword2"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }