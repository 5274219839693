<template>
    <v-btn
        class="fancy"
        v-bind:class="{
            green: isGreen,
            ['light-orange']: isLightOrange,
            ['outlined-orange']: isOutlinedOrange,
            ['grey']: isGrey,
        }"
        v-bind="{ ...$attrs, ...commonAttrs }"
        v-on="$listeners"
    >
        {{ label }}
        <template
            v-for="(_, scopedSlotName) in $scopedSlots"
            #[scopedSlotName]="slotData"
        >
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template>
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-btn>
</template>

<script>
export default {
    name: 'FancyButton',
    inheritAttrs: false,
    props: {
        label: String,
        isGreen: Boolean, //設定 button 的背景色為 $color_main_second
        isLightOrange: Boolean, //設定 button 的背景色為 $color_main_orange_light
        isOutlinedOrange: Boolean, //設定 button 的背景色為 $color_main_orange_light
        isGrey: Boolean,
    },
    computed: {
        commonAttrs() {
            return {
                depressed: true,
            };
        },
    },
};
</script>

<style lang="scss">
button.fancy {
    width: 150px;

    &.v-btn {
        background-color: $color_main_first !important;
        color: #fff;
        border-radius: 30px;
        font-size: 16px;
        line-height: 24px;

        &.green {
            background-color: $color_main_second !important;
        }

        &.grey {
            background-color: #808184 !important;
        }

        &.light-orange {
            color: #FF9F4B;
            font-size: 12px;
            line-height: 24px;
            background-color: $color_main_orange_light !important;
        }
        &.outlined-orange {
            color: #FF9F4B;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            width: 100px !important;
            border: 1px solid #FF9F48;
            background-color: #ffffff00 !important;
        }
    }
}
</style>
