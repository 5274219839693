<template>
    <v-main>
        <BackOnTop :title="title" />
        <v-container
            fluid
            class="salon-container pt-3 px-8 mt-3 mb-4"
            style="height: calc(100vh - 200px)"
            v-if="!$store.state.isLoading"
        >
            <div
                class="style-title-m"
                style="text-align: left; font-size: 16px; margin-bottom: 24px"
            >
                基本資料<span style="" class="required-icon">*</span>
            </div>
            <div
                class="style-body-regular"
                style="text-align: left; margin-bottom: 10px"
            >
                <img
                    src="../../assets/images/foot.png"
                    alt=""
                    style="width: 18px; height: 18px"
                />
                選擇寶貝
            </div>
            <div
                style="margin-bottom: 30px; display: flex; overflow: scroll"
                class="style-salon-baby-list"
            >
                <div
                    style="width: 72px; margin-right: 12px"
                    v-for="(baby, index) in baby_list"
                    :key="index"
                    :class="`${
                        form.baby == baby.value
                            ? 'style-baby-active'
                            : 'style-baby-inactive'
                    }`"
                    @click="form.baby = baby.value"
                >
                    <div
                        class="style-order-edit-img"
                        :style="{ 'background-image': `url(${baby.img})` }"
                    ></div>
                    <div class="style-body-regular" style="margin-top: 6px">
                        {{ baby.text }}
                    </div>
                </div>
            </div>
            <div
                class="style-body-regular"
                style="
                    text-align: left;
                    margin-bottom: 10px;
                    background-color: white;
                "
            >
                <img
                    src="../../assets/images/phone.png"
                    alt=""
                    style="width: 18px; height: 18px"
                />
                連絡電話
            </div>
            <div style="margin-bottom: 30px">
                <input
                    type="text"
                    class="style-text-input"
                    style="background-color: white"
                    v-model="form.phone"
                />
            </div>
            <div
                class="style-body-regular"
                style="text-align: left; margin-bottom: 10px"
            >
                <img
                    src="../../assets/images/shop.png"
                    alt=""
                    style="width: 18px; height: 18px"
                />
                選擇門市
            </div>
            <div style="margin-bottom: 40px">
                <FancySelectBottom
                    v-if="store_list.length"
                    defaultLabel="選擇門市"
                    v-model="form.store"
                    :options="store_list"
                    :initial="initial"
                    :refreshServiceItem="refreshServiceItem"
                    isTime
                />
            </div>
            <div
                class="style-title-m"
                style="text-align: left; font-size: 16px; margin-bottom: 12px"
            >
                服務項目<span style="" class="required-icon">*</span>
            </div>
            <div class="col-12 row">
                <div
                    :class="`col-6 ${
                        serviceSelect == 'group'
                            ? 'style-active-tab'
                            : 'style-inactive-tab'
                    }`"
                    @click="
                        serviceSelect = 'group';
                        form.serviceItem = '';
                    "
                >
                    套餐
                </div>
                <div
                    :class="`col-6 ${
                        serviceSelect == 'single'
                            ? 'style-active-tab'
                            : 'style-inactive-tab'
                    }`"
                    @click="
                        serviceSelect = 'single';
                        form.serviceItem = '';
                    "
                >
                    單選
                </div>
            </div>
            <div
                class="row"
                style="margin-top: 20px"
                v-if="serviceSelect == 'single'"
            >
                <div
                    v-for="(item, index) in optionsChipSingle.filter(function (
                        obj
                    ) {
                        if (
                            obj.match('游泳') != null &&
                            have_swim_service_store_list.indexOf(
                                form.store.split(' ')[0]
                            ) == -1
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    })"
                    :key="'optionsChipSingle' + index"
                    :class="`${
                        form.baby && form.phone && form.store
                            ? (form.serviceItem == item
                                ? 'style-light-btn'
                                : 'style-border-btn')
                            : 'style-time-disable-btn'
                    } style-btn`"
                    :style="`${
                        index != optionsChipSingle.length - 1
                            ? 'margin-right: 6px;font-size:12px !important;'
                            : 'font-size:12px !important;'
                    }`"
                    @click="(form.baby && form.phone && form.store) ? form.serviceItem = item : ''"
                >
                    {{ item }}
                </div>
            </div>
            <div
                class="row"
                style="margin-top: 20px"
                v-if="serviceSelect == 'group'"
            >
                <div
                    v-for="(item, index) in optionsChipGroup.filter(function (
                        obj
                    ) {
                        if (
                            obj.match('游泳') != null &&
                            have_swim_service_store_list.indexOf(
                                form.store.split(' ')[0]
                            ) == -1
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    })"
                    :key="'optionsChipGroup' + index"
                    :class="`${
                        form.baby && form.phone && form.store
                            ? (form.serviceItem == item
                                ? 'style-light-btn'
                                : 'style-border-btn')
                            : 'style-time-disable-btn'
                    } style-btn`"
                    :style="`${
                        index != optionsChipGroup.length - 1
                            ? 'margin-right: 6px;font-size:12px !important;'
                            : 'font-size:12px !important;'
                    }`"
                    @click="(form.baby && form.phone && form.store) ? form.serviceItem = item : ''"
                >
                    {{ item }}
                </div>
            </div>
            <PopupOrderUnableNotAdd
                v-model="showOrderUnableNotAdd"
                @click:confirm="$router.push('/register/pet')"
            />
        </v-container>
    </v-main>
</template>

<script>
import BackOnTop from '@/components/NewStyle/BackOnTop.vue';
import FancySelectBottom from '@/components/NewStyle/FancySelectBottom.vue';

import PopupOrderUnableNotAdd from '@/components/NewPopup/PopupOrderUnableNotAdd.vue';
import {
    KgGetPetList,
    KgGetStore,
    isPetExist,
    getServiceStoreList,
    syncPetData,
    getPetImage,
    getReserveList,
} from '@/apis/api.js';
export default {
    name: 'SalonOrderEdit',
    components: {
        BackOnTop,
        // FancyInput,
        // FancySelect,
        // SalonBottomButton,
        FancySelectBottom,
        PopupOrderUnableNotAdd,
    },
    data: () => ({
        valid: '',
        form: {
            phone: '',
            baby: '',
            store: '',
            serviceItem: '',
        },
        title: '美容預約',
        serviceSelect: 'group',
        baby_list: [],
        store_list: [],
        optionsChipSingle: ['剪毛', '洗澡', '游泳'],
        optionsChipGroup: ['洗澡+SPA', '洗澡+游泳', '剪毛+SPA', '剪毛+游泳'],
        showOrderUnableNotAdd: false,
        have_swim_service_store_list: [],
        petDict: {},
        storeDict: {},
        initial: 0,
        storeIndexList: [],
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    mounted() {
        // let parent = this;
    },
    created() {
        if (
            !localStorage.getItem('phone') ||
            !localStorage.getItem('password') ||
            !localStorage.getItem('member_no')
        ) {
            this.$router.push('/login');
        } else {
            var parent = this;
            // 預設帶入電話
            this.form.phone = localStorage.getItem('phone');
            // 過濾有美容服務的店家
            getServiceStoreList().then(function (store_rsp) {
                if (typeof store_rsp != 'object') {
                    store_rsp = JSON.parse(store_rsp);
                }
                let have_service_store_list = store_rsp.filter(function (obj) {
                    return obj.status == 'True';
                });
                parent.have_swim_service_store_list = store_rsp
                    .filter(function (obj) {
                        return (
                            obj.status == 'True' && obj.swimsrvstatus == 'True'
                        );
                    })
                    .map(function (obj) {
                        return obj.storeno;
                    });
                console.log(
                    'have_swim_service_store_list',
                    parent.have_swim_service_store_list
                );
                var service_store_list = have_service_store_list.map(function (
                    obj
                ) {
                    return obj.storeno;
                });
                KgGetStore(localStorage.getItem('phone')).then(function (rsp) {
                    if (rsp) {
                        console.log(rsp);
                        for (let i in rsp) {
                            if (
                                service_store_list.indexOf(rsp[i].STO_No) != -1
                            ) {
                                parent.storeIndexList.push(rsp[i].STO_No);
                                parent.storeDict[rsp[i].STO_No] =
                                    rsp[i].STO_No + ' ' + rsp[i].STO_Name;
                                parent.store_list.push({
                                    text: rsp[i].STO_Name,
                                    value:
                                        rsp[i].STO_No + ' ' + rsp[i].STO_Name,
                                });
                                if (
                                    parent.store_list.length == 1 &&
                                    parent.$route.query.type == '游泳'
                                ) {
                                    parent.form.store =
                                        rsp[i].STO_No + ' ' + rsp[i].STO_Name;
                                    console.log(
                                        parent.$route.query.type,
                                        parent.form.store
                                    );
                                    if (
                                        parent.have_swim_service_store_list.indexOf(
                                            parent.form.store.split(' ')[0]
                                        ) != -1
                                    ) {
                                        setTimeout(function () {
                                            parent.serviceSelect = 'single';
                                            parent.form.serviceItem = '游泳';
                                        }, 1000);
                                    }
                                }
                            }
                        }
                    }
                });
            });
            syncPetData(
                localStorage.getItem('phone'),
                localStorage.getItem('password')
            ).then(function () {
                KgGetPetList(
                    localStorage.getItem('phone'),
                    localStorage.getItem('member_no')
                ).then(function (rsp) {
                    if (rsp) {
                        if (rsp.length) {
                            // setTimeout(function(){
                            var promises = rsp.map(function (obj, i) {
                                return isPetExist(
                                    localStorage.getItem('phone'),
                                    localStorage.getItem('password'),
                                    rsp[i].PET_SeqNo
                                ).then(function (i_rsp) {
                                    if (i_rsp) {
                                        return getPetImage(
                                            localStorage.getItem('phone'),
                                            localStorage.getItem('password'),
                                            rsp[i].PET_SeqNo
                                        ).then(function (image) {
                                            parent.petDict[
                                                rsp[i].PET_Name
                                            ] = `${rsp[i].PET_SeqNo} ${rsp[i].PET_Name} ${rsp[i].PET_Type}`;
                                            return {
                                                text: rsp[i].PET_Name,
                                                value: `${rsp[i].PET_SeqNo} ${rsp[i].PET_Name} ${rsp[i].PET_Type}`,
                                                img: image
                                                    ? image
                                                    : require('@/assets/images/imgCard' +
                                                          rsp[i].PET_Type +
                                                          '.jpg'),
                                            };
                                        });
                                    } else {
                                        return false;
                                    }
                                });
                            });
                            Promise.all(promises).then(function (results) {
                                console.log(results);
                                parent.baby_list = results
                                    .filter(function (obj) {
                                        if (obj) return true;
                                        else return false;
                                    })
                                    .sort(function (a, b) {
                                        if (a.text > b.text) {
                                            return -1;
                                        } else {
                                            return 1;
                                        }
                                    });
                            });
                        } else {
                            parent.showOrderUnableNotAdd = true;
                            // parent.$router.push('/register/pet');
                        }
                    } else {
                        parent.showOrderUnableNotAdd = true;
                        // parent.$router.push('/register/pet');
                    }
                });
            });
            if (this.$route.query.reserve_no) {
                this.title = '修改預約';
                setTimeout(function () {
                    getReserveList(
                        localStorage.getItem('phone'),
                        localStorage.getItem('password')
                    ).then(function (rsp) {
                        for (let i in rsp) {
                            if (
                                rsp[i].c_pbsapptno ==
                                parent.$route.query.reserve_no
                            ) {
                                console.log(rsp[i]);
                                parent.form.phone = rsp[i].c_phone_num;
                                parent.form.baby =
                                    parent.petDict[rsp[i].c_pet_name];
                                parent.form.store =
                                    parent.storeDict[rsp[i].e_storeno];
                                parent.initial = parent.storeIndexList.indexOf(
                                    rsp[i].e_storeno
                                );
                                parent.form.ori_store_no = rsp[i].e_storeno;
                                parent.form.ori_c_resv_date =
                                    rsp[i].c_resv_date;
                                if (
                                    rsp[i].e_resv_item.match('基礎美容') != null
                                ) {
                                    parent.form.PBS_Svr05 = 'Y';
                                } else {
                                    parent.form.PBS_Svr05 = 'N';
                                }

                                // 暫時先不要預設帶入
                                // if(parent.optionsChipSingle.indexOf(rsp[i].e_resv_item) != null){
                                //     parent.serviceSelect = 'single';
                                //     parent.form.serviceItem = rsp[i].e_resv_item;
                                // }
                                console.log('getReserveList', parent.form);
                                parent.$store.state.isLoading = false;
                            }
                        }
                    });
                }, 3000);
            } else {
                this.$nextTick(() => {
                    this.$store.state.isLoading = false;
                });
            }
        }
    },
    methods: {
        goNext() {
            // var type = this.$route.query.type;
            // if (type) {
            //     type = '&type=' + type;
            // } else {
            //     type = '';
            // }
            var query = this.$route.query;
            query.phone = this.form.phone;
            query.type = this.form.serviceItem.replace(' ', '+');
            query.baby = this.form.baby;
            query.store = this.form.store;
            // let url = `/salon/order/time?phone=${this.form.phone}&type=${this.form.serviceItem.replace(" ","+")}&baby=${this.form.baby}&store=${this.form.store}`;
            if (this.$route.query.reserve_no) {
                query.ori_store_no = this.form.ori_store_no;
                query.ori_c_resv_date = this.form.ori_c_resv_date;
                query.PBS_Svr05 = this.form.PBS_Svr05;
            }
            this.$router.push({
                path: '/salon/order/time',
                query: query,
            });
        },
        switchOrderUnableNotAdd(isShow) {
            this.showOrderUnableNotAdd = isShow;
        },
        refreshServiceItem() {
            console.log('refreshServiceItem');
            this.form.serviceItem = '';
        },
    },
    watch: {
        form: {
            handler: function (new_val, old_val) {
                console.log(new_val, old_val);
                if (new_val.store != old_val.store) {
                    this.refreshServiceItem();
                }
                if (!this.$store.state.isLoading) {
                    let finish = true;
                    console.log(new_val, old_val);
                    for (let key in new_val) {
                        if (new_val[key] === '') {
                            finish = false;
                        }
                    }
                    if (finish) {
                        this.goNext();
                    }
                }
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.salon-container {
    .btn {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: 52px;
    }
}
</style>
