<template>
    <v-main>
        <BackOnTop :url="url"  />
        <PageTitleBar title="美容中服務" />
        <v-container v-if="!$store.state.isLoading" fluid class="salon-container pt-4 px-0 text-left">
            <SalonInfoBox class="mx-4" :infoList="infos" />
            <h2 v-if="processList.length" class="mx-4">服務進度</h2>
            <SalonProcessBox v-if="processList.length" class="p-box mx-4" :processList="processList" />
            <h2 v-if="slides.length" class="mx-4 mb-3">照片紀錄</h2>
            <SalonPhotoSlides v-if="slides.length" :slides="slides" class="py-2 px-3" />
            <div v-if="false" class="footer d-flex justify-center mx-4 mt-15 mb-4">
                <FancyButton
                    class="btn"
                    width="150"
                    height="48"
                    label="視訊"
                />
            </div>
        </v-container>
    </v-main>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
import PageTitleBar from '@/components/PageTitleBar.vue';
import SalonInfoBox from '@/components/SalonInfoBox.vue';
import SalonProcessBox from '@/components/SalonProcessBox.vue';
import SalonPhotoSlides from '@/components/SalonPhotoSlides.vue';
import FancyButton from '@/components/FancyButton.vue';
import { getReserveList, KgGetStore, getPictList, KgGetReserve, KgGetBeauty } from '@/apis/api.js'
export default {
    name: 'SalonProcess',
    components: {
        BackOnTop,
        PageTitleBar,
        SalonInfoBox,
        SalonProcessBox,
        SalonPhotoSlides,
        FancyButton,
    },
    data: () => ({
        slides: [],
        infos: [],
        processList: [],
        url:"",
        store_id:"",
        pet_id:""
    }),
    created(){
        var parent = this;
        this.url = '/salon/history?selectedTime=' + parent.$route.query.selectedTime + "&selectedStatus=" + parent.$route.query.selectedStatus + "&selectedType=" + parent.$route.query.selectedType;
        var id = parent.$route.query.id;
        var store_id = parent.$route.query.store_id

        var store_dict = {};
        KgGetStore(localStorage.getItem('phone')).then(function(store_list){
            for(let i in store_list){
                console.log(store_list[i]);
                store_dict[store_list[i].STO_No] = store_list[i].STO_Name;
            }
            getReserveList(localStorage.getItem('phone'),localStorage.getItem('password')).then(function(rsp){
                rsp = rsp.filter(function(obj){
                    return obj.c_pbsapptno == id && obj.e_storeno == store_id
                }).slice(0,1)
                for(let i in rsp){
                    if(rsp[i].c_pbsapptno == id && rsp[i].e_storeno == store_id && !parent.processList.length){
                        
                        parent.store_id = rsp[i].e_storeno;
                        KgGetReserve(localStorage.getItem('phone'),localStorage.getItem('member_no'),rsp[i].e_storeno,rsp[i].c_pbsapptno).then(function(inner_rsp){
                            var hour = inner_rsp.PBS_ApptBegTime.slice(0,2) + ":" + inner_rsp.PBS_ApptBegTime.slice(2,4)
                            parent.infos = [
                                { key: '會員姓名', value: rsp[i].c_name },
                                { key: '聯絡電話', value: rsp[i].c_phone_num },
                                { key: '寶貝姓名', value: rsp[i].c_pet_name },
                                { key: '預約門市', value: store_dict[rsp[i].e_storeno] },
                                { key: '預約日期', value: rsp[i].c_resv_date.slice(0,4) + "/" + rsp[i].c_resv_date.slice(4,6) + "/" + rsp[i].c_resv_date.slice(6,8) + '   ' + hour },
                            ]
                            parent.processList = [];
                            KgGetBeauty(localStorage.getItem('phone'), rsp[i].e_storeno, inner_rsp.PBS_No, inner_rsp.PBS_Date).then(function(rsp){
                                if(rsp.PBS_DTL_INFO.length){
                                    var small_start_time = 40220530215412;
                                    for(let index in rsp.PBS_DTL_INFO){
                                        
                                        if(rsp.PBS_DTL_INFO[index].PBS_SvrBegTime < small_start_time){
                                            small_start_time = rsp.PBS_DTL_INFO[index].PBS_SvrBegTime;
                                        }
                                        if(index == rsp.PBS_DTL_INFO.length - 1){
                                            if(small_start_time.length == 14){
                                                parent.processList.push({ time: small_start_time.slice(8,10) + ":" + small_start_time.slice(10,12), step: '處理中', isFinished: true })
                                            }
                                        }
                                    }
                                }
                            })
                        })
                        // 定時
                        setInterval(function(){
                            KgGetReserve(localStorage.getItem('phone'),localStorage.getItem('member_no'),rsp[i].e_storeno,rsp[i].c_pbsapptno).then(function(inner_rsp){
                                console.log(inner_rsp);
                                if((inner_rsp.PBS_ApptStatus == "S2" || inner_rsp.PBS_ApptStatus == "S3")) parent.$router.push('/salon/process/rating?PET_SeqNo=' + inner_rsp.PET_SeqNo + '&PBS_No=' + inner_rsp.PBS_No + '&id=' + id + '&store_id=' + parent.store_id + "&selectedTime=" + parent.$route.query.selectedTime + "&selectedType=" + parent.$route.query.selectedType + "&selectedStatus=" + parent.$route.query.selectedStatus);
                            })
                        },1000*60*5)
                        
                        getPictList(rsp[i].e_storeno, parent.$route.query.PBS_No, localStorage.getItem('member_no'), parent.$route.query.PET_SeqNo).then(function(rsp){
                            console.log(rsp);
                            
                            if(rsp){
                                parent.slides = [];
                                if(rsp.pic_url) parent.slides.push(rsp.pic_url.replace('http://','https://'));
                                else 
                                    rsp.map(function(obj){
                                        parent.slides.push(obj.pic_url.replace('http://','https://'));
                                    })
                            }
                        })
                    }
                }
            })
        })      
    },
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    mounted() {
        var parent = this;
        this.$nextTick(() => {
            setTimeout(function(){
                parent.$store.state.isLoading = false;
            },2000)
            
        });
    },
    methods: {},
};
</script>

<style lang="scss">
.salon-container {
    h2 {
        margin: 28px 0 23px 0;
        color: $color_main_first;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
    }
    .p-box {
        margin: 0 0 16px 0;
    }
}
</style>
