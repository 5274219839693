<template>
    <FancyDialog v-bind="{ ...$attrs }" v-on="$listeners">
        <v-container fluid class="dialog_container text-left">
            <div style="text-align: left;margin-bottom: 12px;">
                <div class="popup-title" style="margin-bottom: 10px;">確定取消預約?</div>
                <div class="popup-desc">將取消本次預約，如需再次預約，需重新填寫預約資料</div>
            </div>
            <v-form ref="cancel_form" v-model="cancelValid">
                <div class="popup-desc" style="margin-bottom: 8px;">取消原因</div>
                <div class="popup-desc">
                    <FancyRadio
                    class="mt-n1 f_sm"
                    fluid
                    v-model="form.reason"
                    
                    :items="[
                        { label: '有事無法前來', value: '有事無法前來' },
                        { label: '天氣因素', value: '天氣因素' },
                        { label: '其他', value: '其他' },
                    ]"
                    :rules="[
                        () => !!form.reason || '請選擇取消原因',
                    ]"
                    required
                />
                <FancyTextarea
                    height="100"
                    v-if="form.reason == '其他'"
                    placeholder="其他原因"
                    v-model="form.reasonText"
                    class="textarea_else"
                />
                </div>
                
                
            </v-form>
            <div class="footer d-flex justify-center" style="margin-bottom: 8px;">
                <button
                    @click="$emit('click:cancel_cancel_reserve')"
                    class="style-btn style-m-btn style-dark-btn"
                    style="margin: 8px;"
                >
                    取消
                </button>
                <button
                    class="style-btn style-m-btn style-light-btn"
                    @click="check()"
                    style="margin: 8px;"
                >
                確認
                </button>
            </div>
        </v-container>
    </FancyDialog>
</template>

<script>
import FancyDialog from '@/components/FancyDialog.vue';
import FancyRadio from '@/components/FancyRadio.vue';
import FancyTextarea from '@/components/FancyTextarea.vue';

export default {
    components: {
        FancyDialog,
        FancyRadio,
        FancyTextarea,
    },
    name: 'PopupReservedCancel',
    props: {
        serviceList: {
            type: Array,
        },
        form: {
            type: Object,
        },
    },
    emits: ['click:cancel_cancel_reserve', 'click:confirm'],
    data: () => ({
        reason: '公司服務不佳',
        cancelValid:''
    }),
    methods:{
        check(){
            const valid = this.$refs.cancel_form.validate();
            if(valid) this.$emit('click:confirm');
        }
    }
};
</script>

<style lang="scss">
div.dialog_container {
    padding: 0px 0px;
    // > p,
    // > div {
    //     padding: 0 20px;
    // }
    p.title {
        color: $color_main_first;
        font-weight: 400 !important;
        font-size: 20px !important;
        line-height: 24px !important;
        text-align: center;
    }
    p.sub {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $color_main_black;
        &--desc {
            font-size: 14px;
            color: $color_main_grey_dark;
        }
    }
    p.desc {
        font-size: 14px;
        line-height: 16px;
        color: $color_main_black;
    }
    .textarea_else {
        margin: -10px auto 0;
    }
    > .footer {
        padding: 0;
        .btn {
            height: 50px;
            width: 100%;
            @media screen and (max-width: 374px) {
                max-width: 118px;
            }
            @media screen and (min-width: 375px) {
                max-width: 145.5px;
            }
        }
    }
}
</style>
