var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FancyDialog',_vm._g(_vm._b({},'FancyDialog',{ ..._vm.$attrs },false),_vm.$listeners),[_c('v-container',{staticClass:"dialog_container text-left",attrs:{"fluid":""}},[_c('v-form',{ref:"cancel_form",model:{value:(_vm.cancelValid),callback:function ($$v) {_vm.cancelValid=$$v},expression:"cancelValid"}},[_c('p',{staticClass:"title mb-4"},[_vm._v("取消預約")]),_c('p',{staticClass:"sub mb-1"},[_vm._v(" 會員"+_vm._s(_vm.form.name)+"您好，"),_c('br'),_vm._v("您將取消本次的預約 ")]),_c('p',{staticClass:"sub sub--desc mb-4"},[_vm._v(" 時間 : "+_vm._s(_vm.form.time)),_c('br'),_vm._v(" 門市 : "+_vm._s(_vm.form.store)),_c('br'),_vm._v(" 寶貝名稱 : "+_vm._s(_vm.form.petName)+" ")]),_c('p',{staticClass:"sub mb-2"},[_vm._v("取消原因為")]),_c('FancyRadio',{staticClass:"mt-n1 f_sm",attrs:{"fluid":"","items":[
                { label: '有事無法前來', value: '有事無法前來' },
                { label: '公司服務不佳', value: '公司服務不佳' },
                { label: '其他', value: '其他' },
            ],"rules":[
                () => !!_vm.form.reason || '請選擇取消原因',
            ],"required":""},model:{value:(_vm.form.reason),callback:function ($$v) {_vm.$set(_vm.form, "reason", $$v)},expression:"form.reason"}}),(_vm.form.reason == '其他')?_c('FancyTextarea',{staticClass:"textarea_else",attrs:{"height":"100","placeholder":"其他原因"},model:{value:(_vm.form.reasonText),callback:function ($$v) {_vm.$set(_vm.form, "reasonText", $$v)},expression:"form.reasonText"}}):_vm._e(),_c('div',{staticClass:"footer d-flex justify-center"},[_c('FancyButton',{staticClass:"btn mr-3",attrs:{"isGreen":"","label":"取消"},on:{"click":function($event){return _vm.$emit('input', false)}}}),_c('FancyButton',{staticClass:"btn",attrs:{"label":"確認"},on:{"click":function($event){return _vm.check()}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }