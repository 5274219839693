var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',[_c('BackOnTop',{attrs:{"title":_vm.title}}),(!_vm.$store.state.isLoading)?_c('v-container',{staticClass:"salon-container pt-3 px-8 mt-3 mb-4",attrs:{"fluid":""}},[_c('div',{staticClass:"style-title-m",staticStyle:{"text-align":"left","font-size":"16px","margin-bottom":"24px"}},[_vm._v(" 預約時間"),_c('span',{staticClass:"required-icon"},[_vm._v("*")])]),_c('div',{staticClass:"style-body-regular",staticStyle:{"text-align":"left","margin-bottom":"8px"}},[_vm._v(" 選擇日期 ")]),_c('SalonDatePicker',{attrs:{"startDate":_vm.startDate,"endDate":_vm.endDate,"disabled-dates":_vm.disabledDates},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}}),_c('div',{staticClass:"style-body-regular",staticStyle:{"text-align":"left","margin-bottom":"10px"}},[_vm._v(" 選擇時段 ")]),_vm._l(([
                [0, 1, 2],
                [3, 4, 5],
                [6, 7, 8],
            ]),function(condition,c_index){return _c('div',{key:c_index,staticClass:"row"},_vm._l((_vm.listTimes.filter(function (
                    obj,
                    i
                ) {
                    return condition.indexOf(i) != -1;
                })),function(timeInfo,index){return _c('div',{key:index,class:`style-btn ${
                    timeInfo.isFull
                        ? 'style-time-disable-btn'
                        : _vm.selectedTime == timeInfo.time
                        ? 'style-light-btn'
                        : 'style-border-btn'
                }`,style:(`${
                    index != condition.length - 1
                        ? 'margin-right: 12px;font-weight:400 !important;'
                        : 'font-weight: 400 !important;'
                }`),on:{"click":function($event){timeInfo.isFull ? '' : (_vm.selectedTime = timeInfo.id);
                    _vm.goNext();}}},[_vm._v(" "+_vm._s(timeInfo.time)+" ")])}),0)})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }