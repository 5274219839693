<template>
    <FancyDialog v-bind="{ ...$attrs }" v-on="$listeners">
        <v-container fluid class="dialog_container">
            <div style="text-align: left;margin-bottom: 20px;">
                <div class="popup-title" style="margin-bottom: 10px;">無法預約</div>
                <div class="popup-desc">請直接撥打電話，門市專人為您服務</div>
            </div>
            
            <div class="footer d-flex justify-center" style="margin-bottom: 8px;">
                <button
                    class="style-btn style-m-btn style-dark-btn"
                    @click="$emit('click:confirm')"
                    style="margin: 8px;"
                >
                取消
                </button>
                <a
                    :href="`tel:${phone}`"
                    class="style-btn style-m-btn style-light-btn"
                    style="margin: 8px;"
                >
                    聯絡門市
                </a>
            </div>
        </v-container>
    </FancyDialog>
</template>

<script>
import FancyDialog from '@/components/FancyDialog.vue';
export default {
    components: {
        FancyDialog,
    },
    name: 'PopupOrderUnableNotAdd',
    props: {
        phone:{
            type:String
        },
        serviceList: {
            type: Array,
        },
    },
    emits: ['input', 'click:confirm'],
    data: () => ({}),
};
</script>

<style lang="scss" scoped>
div.dialog_container {
    p.title {
        color: $color_main_first;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        letter-spacing: -0.3px;
    }
    p.desc {
        font-size: 14px;
        line-height: 16px;
        color: $color_main_black;
    }
    > .footer {
        .btn {
            height: 50px;
            width: 100%;
            @media screen and (max-width: 374px) {
                max-width: 118px;
            }
            @media screen and (min-width: 375px) {
                max-width: 145.5px;
            }
        }
    }
}
</style>
