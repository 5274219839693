<template>
    <FancyDialog v-bind="{ ...$attrs }" v-on="$listeners">
        <v-container fluid class="dialog_container">
            <img
                width="90"
                height="90"
                class="mb-5"
                src="@/assets/images/imgWarning.svg"
                alt=""
            />
            <p class="title mb-3">無法取消</p>
            <p class="desc mb-9">
                很抱歉，預約當日無法線上取消，<br />請洽門市服務人員
            </p>
            <div class="footer d-flex justify-center">
                <FancyButton
                    isGreen
                    class="btn mr-3"
                    label="取消"
                    @click="$emit('input', false)"
                />
                <a class="btn" style="color: #333;" :href="`tel:${phone}`">
                <FancyButton
                    class="btn"
                    label="聯絡門市"
                    @click="$emit('click:confirm')"
                />
                </a>
            </div>
        </v-container>
    </FancyDialog>
</template>

<script>
import FancyDialog from '@/components/FancyDialog.vue';
import FancyButton from '@/components/FancyButton.vue';
export default {
    components: {
        FancyDialog,
        FancyButton,
    },
    name: 'PopupOrderDeleteError',
    props: {
        serviceList: {
            type: Array,
        },
        phone: {
            type: String,
        },
    },
    emits: ['input', 'click:confirm'],
    data: () => ({}),
};
</script>

<style lang="scss" scoped>
div.dialog_container {
    p.title {
        color: $color_main_first;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        letter-spacing: -0.3px;
    }
    p.desc {
        font-size: 14px;
        line-height: 24px;
        color: $color_main_black;
    }
    > .footer {
        .btn {
            height: 50px;
            width: 100%;
            @media screen and (max-width: 374px) {
                max-width: 118px;
            }
            @media screen and (min-width: 375px) {
                max-width: 145.5px;
            }
        }
    }
}
</style>
