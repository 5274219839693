var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',[_c('BackOnTop',{attrs:{"url":"/login"}}),_c('PageTitleBar',{attrs:{"title":"大樹寵物會員註冊","hasLogo":""}}),_c('v-container',{staticClass:"register-container px-4 mb-4"},[_c('PopupAlert',{attrs:{"errorMsg":_vm.errorMsg,"imgSrc":_vm.imgSrc},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}}),_c('v-form',{ref:"form",staticClass:"mb-16",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancyInput',{attrs:{"label":"手機*(必填)","iconName":"ic24Phone.svg","type":"number","rules":[
                            () =>
                                _vm.phone_error_msg ||
                                !!_vm.form.phone ||
                                '請輸入正確手機',
                        ],"required":""},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancyInput',{attrs:{"label":"密碼*(必填)","iconName":"ic24Lock.svg","hint":"6-12位英文及數字混和的密碼","persistent-hint":"","rules":[
                            () =>
                                (!!_vm.form.password &&
                                    _vm.form.password.match(
                                        /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,12}$/
                                    ) != null) ||
                                '請輸入6-12位英文及數字混和的密碼',
                        ],"type":"password","required":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancyInput',{attrs:{"label":"確認密碼*(必填)","iconName":"ic24Lock.svg","hint":"請再次輸入跟上方相同的密碼","persistent-hint":"","rules":[
                            () =>
                                (!!_vm.form.password2 &&
                                    _vm.form.password == _vm.form.password2) ||
                                '兩次密碼輸入不相同',
                        ],"type":"password","required":""},model:{value:(_vm.form.password2),callback:function ($$v) {_vm.$set(_vm.form, "password2", $$v)},expression:"form.password2"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancyInput',{attrs:{"label":"姓名*(必填)","iconName":"ic24Uname.svg","rules":[() => !!_vm.form.name || '請輸入會員姓名'],"required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"6","sm":"6"}},[_c('FancySelect',{attrs:{"label":"生日 年","iconName":"ic24Birthday.svg","items":_vm.yearOptions,"options":_vm.yearOptions},model:{value:(_vm.form.birthday_year),callback:function ($$v) {_vm.$set(_vm.form, "birthday_year", $$v)},expression:"form.birthday_year"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"3","sm":"3"}},[_c('FancySelect',{attrs:{"label":"月","options":[
                            {
                                text: '月',
                                value: '',
                                selected: true,
                                disabled: true,
                            },
                            { text: '01', value: '01' },
                            { text: '02', value: '02' },
                            { text: '03', value: '03' },
                            { text: '04', value: '04' },
                            { text: '05', value: '05' },
                            { text: '06', value: '06' },
                            { text: '07', value: '07' },
                            { text: '08', value: '08' },
                            { text: '09', value: '09' },
                            { text: '10', value: '10' },
                            { text: '11', value: '11' },
                            { text: '12', value: '12' },
                        ],"items":[
                            {
                                text: '月',
                                value: '',
                                selected: true,
                                disabled: true,
                            },
                            { text: '01', value: '01' },
                            { text: '02', value: '02' },
                            { text: '03', value: '03' },
                            { text: '04', value: '04' },
                            { text: '05', value: '05' },
                            { text: '06', value: '06' },
                            { text: '07', value: '07' },
                            { text: '08', value: '08' },
                            { text: '09', value: '09' },
                            { text: '10', value: '10' },
                            { text: '11', value: '11' },
                            { text: '12', value: '12' },
                        ]},model:{value:(_vm.form.birthday_month),callback:function ($$v) {_vm.$set(_vm.form, "birthday_month", $$v)},expression:"form.birthday_month"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"3","sm":"3"}},[_c('FancySelect',{attrs:{"label":"日","options":_vm.dayOptions,"items":_vm.dayOptions},model:{value:(_vm.form.birthday_day),callback:function ($$v) {_vm.$set(_vm.form, "birthday_day", $$v)},expression:"form.birthday_day"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancySelect',{attrs:{"label":"生理性別*","iconName":"ic24UGender.svg","options":[
                            {
                                text: '生理性別*',
                                value: '',
                                selected: true,
                                disabled: true,
                            },
                            { text: '女', value: 'female' },
                            { text: '男', value: 'male' },
                        ],"items":[
                            {
                                text: '生理性別',
                                value: '',
                                selected: true,
                                disabled: true,
                            },
                            { text: '女', value: 'female' },
                            { text: '男', value: 'male' },
                        ]},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancySelect',{attrs:{"label":"註冊門市*","iconName":"ic24Store.svg","items":_vm.shopOptions,"options":_vm.shopOptions,"required":""},model:{value:(_vm.form.reg_storeno),callback:function ($$v) {_vm.$set(_vm.form, "reg_storeno", $$v)},expression:"form.reg_storeno"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('FancyCheckContract',{attrs:{"contractLink":`/register/contract?phone=${_vm.form.phone}&name=${_vm.form.name}&password=${_vm.form.password}&birthday=${_vm.form.birthday}&gender=${_vm.form.gender}`,"hint":"為了確保您的自身權益，請務必詳閱服務條款，若您曾於門市申請大樹寵物會員，我們將優先使用您之前所登記的資料大樹寵物會員註冊","rules":[
                            () =>
                                !!_vm.isAgreeRegisterContract ||
                                '為了確保您的自身權益，請務必詳閱服務條款',
                        ],"persistent-hint":""},model:{value:(_vm.isAgreeRegisterContract),callback:function ($$v) {_vm.isAgreeRegisterContract=$$v},expression:"isAgreeRegisterContract"}})],1)],1)],1),_c('BtnNext',{on:{"click":_vm.goNext}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }