<template>
    <v-main v-if="!$store.state.isLoading">
        <BackOnTop :url="url"/>
        <!-- <FancySnackbar v-model="showAlert" :label="errorMsg" isUpper /> -->
        <PopupAlert v-model="showAlert" :errorMsg="errorMsg" :imgSrc="imgSrc" />
        <PageTitleBar :title="title" />
        <v-container class="register-container otp px-4 mb-4">
            <p class="subject mb-4">已傳送驗證碼至您的手機</p>
            <p class="desc mb-5">
                請輸入6位數驗證碼, 若未收到簡訊請按重新發送或重新確認手機號碼<br>
                若無法收到驗證碼，請聯繫大樹寵物官網客服
            </p>
            <p class="resend mb-6" :class="{ cd: isCD }" @click="cdTimer(true)">
                重新發送驗證碼
                {{ isCD ? '00:' + cd.toString().padStart(2, '0') : '' }}
            </p>

            <FancyOTP v-model="otp" />
        </v-container>
        <FancyButton
            class="mt-9"
            label="送出"
            width="150"
            height="48"
            isGreen
            @click="checkVerifyCode()"
        />
        <FancyDialog v-model="isSuccessOpened">
            <v-container fluid class="dialog_container">
                <img
                    width="90"
                    height="90"
                    class="mb-5"
                    src="@/assets/images/img_dog.svg"
                    alt=""
                />
                <p class="title mb-3">恭喜您</p>
                <p class="desc mb-6">已成功註冊成為大樹寵物會員</p>
                <div class="footer d-flex justify-center">
                    <FancyButton
                        v-if="false"
                        isGreen
                        class="btn mr-3"
                        label="跳過"
                        @click="isSuccessOpened = false"
                    />
                    <FancyButton
                        class="btn"
                        label="確認"
                        @click="next()"
                    />
                </div>
            </v-container>
        </FancyDialog>
    </v-main>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
import PageTitleBar from '@/components/PageTitleBar.vue';
import FancyButton from '@/components/FancyButton.vue';
import FancyOTP from '@/components/FancyOTP.vue';
import FancyDialog from '@/components/FancyDialog.vue';
import PopupAlert from '@/components/Popup/PopupAlert.vue';

import { register, KgRegister, checkVerifyCode, KgQueryMember, KgResetPassword, KgUpdatePassword,sendVerifyCode, updateDeviceId, KgQueryMemberAll } from '@/apis/api.js';
export default {
    name: 'RegisterOTP',
    components: { BackOnTop, PageTitleBar, FancyButton, FancyOTP, FancyDialog, PopupAlert },
    data: () => ({ 
        url:"",
        otp: '', 
        isSuccessOpened: false, 
        isCD: false,
        cd: 60,
        userData:{},
        type:'',
        title:"您為首次註冊會員",
        device_id:'',
        showAlert:false,
        errorMsg:"驗證碼錯誤",
        imgSrc:''
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.state.isLoading = false;
        });
    },
    created(){
        this.type = this.$route.query.type;
        this.userData = this.$route.query;
        console.log(this.userData);
        if(this.type == "old") this.title = "門市會員進行啟用作業";
        if(this.type == "change") this.title = "換機簡訊驗證";
        this.cdTimer(true)
        // sendVerifyCode(this.$route.query.phone).then(function(rsp){
        //     if(rsp){
        //         console.log("發送成功");
        //     }
        // })
        if(this.type == "old"){
            this.url = {
                path:'/register',
                query:this.$route.query
            }
        }
        if(this.type == "change"){
            this.url = {
                path:'/',
            }
        }
        
    },
    methods: {
        next(){
            this.isSuccessOpened = false;
            let parent = this;
            setTimeout(function(){
                parent.$router.push('/register/pet?prev=otp&type=register')
            });
        },
        cdTimer(start) {
            if (start && !this.isCD) {
                sendVerifyCode(this.$route.query.phone).then(function(rsp){
                    if(rsp){
                        console.log("發送成功");
                    }
                })
                this.isCD = true;
                this.cd = 60;
            }
            if (this.cd > 0) {
                setTimeout(() => {
                    this.cd -= 1;
                    this.cdTimer();
                }, 1000);
            }

            if (this.cd == 0) {
                this.isCD = false;
            }
        },
        create_code(len) {
              let code = "";
              var codeLength = len; //驗證碼的長度
              var random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R',
                  'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'); //隨機數
              for (var i = 0; i < codeLength; i++) { //迴圈操作
                  var index = Math.floor(Math.random() * 36);
                  code += random[index];
              }
              return code
        },
        checkVerifyCode(){
            var parent = this;
            parent.$store.state.isLoading = true;
            checkVerifyCode(this.$route.query.phone,parent.otp).then(function(rsp){
                if(rsp){
                    // 註冊
                    parent.device_id = parent.create_code(10);
                    if(parent.type == "change"){
                        // 換機
                        updateDeviceId(parent.userData.phone,parent.userData.password,parent.device_id).then(function(rsp){
                            if(rsp){
                                KgQueryMember(parent.userData.phone,parent.userData.password).then(function(rsp){
                                    if(rsp.toString() != "true" &&  rsp.toString() != "false" && rsp.toString() != ""){
                                        parent.$store.state.isLoading = false;
                                        localStorage.setItem('member_no',rsp);
                                        localStorage.setItem('phone',parent.userData.phone);
                                        localStorage.setItem('password',parent.userData.password);
                                        localStorage.setItem('device_id',parent.device_id);
                                        parent.$router.push('/');
                                    } else {
                                        parent.$store.state.isLoading = false;
                                        parent.errorMsg = "查無此會員，請洽客服人員";
                                        parent.imgSrc = '@/assets/images/imgWarning.svg'
                                        parent.showAlert = true;
                                        
                                    }
                                })
                            } else {
                                parent.$store.state.isLoading = false;
                                parent.errorMsg = "換機失敗，請洽客服人員";
                                parent.imgSrc = '@/assets/images/imgWarning.svg'
                                parent.showAlert = true;
                                
                            }
                        })
                    } else {
                        if(parent.type == "old"){  // 門市會員啟用
                            KgResetPassword(parent.userData.phone).then(function(rsp){
                                if(rsp){
                                    localStorage.setItem('member_no',rsp.member_no);
                                    var ori_password = rsp.password;
                                    // debug
                                    // ori_password = "rrrr1234";
                                    KgUpdatePassword(parent.userData.phone,localStorage.getItem('member_no'),ori_password,parent.userData.password).then(function(rsp){
                                        if(rsp){
                                            KgQueryMemberAll(parent.userData.phone,parent.userData.password).then(function(rsp){
                                                if(rsp.toString() != "true" &&  rsp.toString() != "false" && rsp.toString() != ""){
                                                    register(parent.userData.phone,rsp.MEB_Name,parent.userData.password,parent.userData.gender,parent.userData.birthday.replace("/","").replace("/","").replace("-","").replace("-",""),parent.device_id,localStorage.getItem('member_no'),parent.userData.reg_storeno).then(function(rsp){
                                                        if (rsp) {
                                                            parent.$store.state.isLoading = false;
                                                            localStorage.setItem('phone',parent.userData.phone);
                                                            localStorage.setItem('password',parent.userData.password);
                                                            localStorage.setItem('device_id',parent.device_id);
                                                            parent.isSuccessOpened = true;
                                                        } else {
                                                            parent.$store.state.isLoading = false;
                                                        }
                                                    })
                                                } else {
                                                    parent.$store.state.isLoading = false;
                                                    parent.errorMsg = "查無此會員，請洽客服人員";
                                                    parent.imgSrc = '@/assets/images/imgWarning.svg'
                                                    parent.showAlert = true;
                                                    
                                                }
                                            })
                                        } else {
                                            parent.$store.state.isLoading = false;
                                            parent.errorMsg = "舊門市會員啟用失敗，請洽客服人員";
                                            parent.imgSrc = '@/assets/images/imgWarning.svg'
                                            parent.showAlert = true;
                                        }
                                    })
                                } else {
                                    parent.$store.state.isLoading = false;
                                    parent.errorMsg = "查無此舊門市會員，請洽客服人員";
                                    parent.imgSrc = '@/assets/images/imgWarning.svg'
                                    parent.showAlert = true;
                                }
                            })
                        } else {
                            // 康技註冊
                            KgRegister(parent.userData.phone,parent.userData.name,parent.userData.password,parent.userData.gender,parent.userData.birthday).then(function(rsp){
                                if(rsp === "timeout"){
                                    parent.$store.state.isLoading = false;
                                    parent.errorMsg = "伺服器回應超時，請洽再試一次";
                                    parent.imgSrc = '@/assets/images/imgWarning.svg'
                                    parent.showAlert = true;
                                } else if(rsp){
                                    localStorage.setItem('member_no',rsp);
                                    register(parent.userData.phone,parent.userData.name,parent.userData.password,parent.userData.gender,parent.userData.birthday.replace("-","").replace("-","").replace("/","").replace("/",""),parent.device_id,localStorage.getItem('member_no'),parent.userData.reg_storeno).then(function(rsp){
                                        if (rsp) {
                                            parent.$store.state.isLoading = false;
                                            localStorage.setItem('phone',parent.userData.phone);
                                            localStorage.setItem('password',parent.userData.password);
                                            localStorage.setItem('device_id',parent.device_id);
                                            parent.isSuccessOpened = true;
                                        } else {
                                            parent.$store.state.isLoading = false;
                                            parent.errorMsg = "註冊(2)失敗，請洽客服人員";
                                            parent.imgSrc = '@/assets/images/imgWarning.svg'
                                            parent.showAlert = true;
                                        }
                                    })
                                } else {
                                    parent.$store.state.isLoading = false;
                                    parent.errorMsg = "註冊(1)失敗，請洽客服人員";
                                    parent.imgSrc = '@/assets/images/imgWarning.svg'
                                    parent.showAlert = true;
                                }
                            })
                        }     
                    }
                } else {
                    parent.errorMsg = '驗證碼錯誤'
                    parent.imgSrc = '@/assets/images/imgWarning.svg'
                    parent.showAlert = true;
                    parent.$store.state.isLoading = false;
                }
            })
        }
    },
};
</script>

<style lang="scss" scoped>
.register-container {
    margin: 12px 0;
    padding: 10px 0 0 0;
    &.otp {
        text-align: left;
        p {
            &.subject {
                font-size: 22px;
                color: $color_main_first;
            }
            &.desc {
                font-size: 14px;
                color: $color_main_black;
            }
            &.resend {
                cursor: pointer;
                font-size: 14px;
                line-height: 16px;
                color: $color_main_second;
                &.cd {
                    color: $color_main_grey_light;
                }
            }
        }
    }
}
div.dialog_container {
    p.title {
        color: $color_main_first;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        letter-spacing: -0.3px;
    }
    p.desc {
        font-size: 14px;
        line-height: 16px;
        color: $color_main_black;
    }
    > .footer {
        .btn {
            height: 50px;
            width: 100%;
            @media screen and (max-width: 374px) {
                max-width: 118px;
            }
            @media screen and (min-width: 375px) {
                max-width: 145.5px;
            }
        }
    }
}
</style>
