var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',[_c('BackOnTop',{attrs:{"title":_vm.title}}),_c('v-container',{staticClass:"salon-container pt-4 px-8",staticStyle:{"height":"calc(100vh - 50px)","justify-content":"center"},attrs:{"fluid":""}},[_c('div',{staticStyle:{"margin-bottom":"-14px"}},[(_vm.img)?_c('div',{staticClass:"style-confirm-img",style:({
                    border: '3px solid #FFDAAD',
                    margin: '0 auto',
                    'background-image': `url(${_vm.img})`,
                    width: '120px',
                    height: '120px',
                    'border-radius': '100%',
                    'background-size': 'cover',
                    'background-position': 'center',
                })}):_vm._e(),_c('div',{staticClass:"style-confirm-bone"},[_vm._v(_vm._s(_vm.pet_name))])]),_c('div',{staticClass:"style-title-l",staticStyle:{"color":"#ff9f4b","margin-bottom":"18px"}},[_vm._v(" "+_vm._s(_vm.services.join('、'))+"服務 ")]),_c('div',{staticClass:"style-title-l",staticStyle:{"font-size":"20px","letter-spacing":"0.8px","margin-bottom":"14px"}},[_vm._v(" "+_vm._s(_vm.query.date.replace(/-/g, '/'))+" ")]),_c('div',{staticClass:"style-title-l",staticStyle:{"font-size":"20px","letter-spacing":"0.8px","margin-bottom":"18px"}},[_vm._v(" "+_vm._s(_vm.query.time + ':00')+" ")]),_c('div',{staticClass:"style-body-regular",staticStyle:{"font-size":"16px","margin-bottom":"140px"}},[_vm._v(" 預約門市 "+_vm._s(_vm.store)+" ")]),_c('div',{staticStyle:{"position":"absolute","bottom":"84px","width":"calc(100vw - 64px)"}},[_c('FancyCheckContract',{attrs:{"contractLink":"/member/contract","hint":"為了確保您的權益，請務必詳閱服務條款","persistent-hint":""},model:{value:(_vm.isAgreeMemberContract),callback:function ($$v) {_vm.isAgreeMemberContract=$$v},expression:"isAgreeMemberContract"}}),(_vm.isAgreeMemberContract)?_c('button',{class:`style-btn style-l-btn style-light-btn`,staticStyle:{"border-radius":"18px","margin":"0 auto","border":"none"},on:{"click":function($event){return _vm.goNext()}}},[_vm._v(" 確定"),_c('img',{staticStyle:{"width":"24px","height":"24px"},attrs:{"src":require("../../assets/images/white-foot.png")}})]):_c('button',{class:`style-btn style-l-btn style-disable-btn`,staticStyle:{"border-radius":"18px","margin":"0 auto","border":"none"}},[_vm._v(" 確定"),_c('img',{staticStyle:{"width":"24px","height":"24px"},attrs:{"src":require("../../assets/images/black-foot.png")}})])],1)]),_c('PopupOrderSuccess',{on:{"click:confirm":function($event){_vm.switchOrderSuccess(false);
            _vm.$router.push('/salon/history');}},model:{value:(_vm.showOrderSuccess),callback:function ($$v) {_vm.showOrderSuccess=$$v},expression:"showOrderSuccess"}}),_c('PopupOrderFail',{attrs:{"phone":_vm.phone},on:{"click:confirm":function($event){return _vm.switchOrderFail(false)}},model:{value:(_vm.showOrderFail),callback:function ($$v) {_vm.showOrderFail=$$v},expression:"showOrderFail"}}),_c('PopupOrderUnable',{attrs:{"phone":_vm.phone},on:{"click:confirm":function($event){return _vm.switchOrderUnable(false)}},model:{value:(_vm.showOrderUnable),callback:function ($$v) {_vm.showOrderUnable=$$v},expression:"showOrderUnable"}}),_c('PopupOrderDuplicate',{attrs:{"data":_vm.old_data,"services":_vm.services,"infos":_vm.infos},on:{"click:confirm":function($event){return _vm.cancelOldReservation()},"click:cancel":function($event){return _vm.$router.push('/salon/history')}},model:{value:(_vm.showOrderDuplicate),callback:function ($$v) {_vm.showOrderDuplicate=$$v},expression:"showOrderDuplicate"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }