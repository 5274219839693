<template>
    <div class="sub_title d_flex">
        <p>{{ textLeft }}</p>
        <a class="right" v-if="showRight" @click.prevent="handleRight">
            {{ textRight }}
        </a>
    </div>
</template>

<script>
export default {
    name: 'SalonSubtitle',
    props: {
        showRight: {
            type: Boolean,
            default: false,
        },
        textLeft: {
            type: String,
            default: '',
        },
        textRight: {
            type: String,
            default: '',
        },
    },
    emits: ['click:right'],
    methods: {
        handleRight() {
            this.$emit('click:right');
        },
    },
};
</script>

<style lang="scss" scoped>
.sub_title {
    margin-top: 6px;
    font-size: 14px;
    line-height: 16px;
    color: $color_main_first;
    align-items: center;
    justify-content: start;
    padding: 0 16px;
    background-color: #fff;
    a.right {
        color: $color_main_first;
        flex: 1;
        text-align: right;
    }
}
</style>
