<template>
    <v-btn class="btn_next" icon v-bind="{ ...$attrs }" v-on="$listeners">
        <v-img alt="logo" src="@/assets/images/btn_next.svg" />
    </v-btn>
</template>

<script>
export default {
    name: 'BtnNext',
};
</script>

<style lang="scss" scoped>
// .btn_next {
// }
</style>
