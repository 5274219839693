var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.$store.state.isLoading)?_c('v-main',[(_vm.$route.query.prev != 'otp')?_c('BackOnTop'):_vm._e(),_c('PageTitleBar',{attrs:{"title":"新增寶貝資料"}}),_c('PopupRegisterGift',{attrs:{"giftQuantity":_vm.giftQuantity},on:{"click:confirm":_vm.handleRegisterGift,"click:close":function($event){return _vm.$router.push('/')}},model:{value:(_vm.showRegisterGift),callback:function ($$v) {_vm.showRegisterGift=$$v},expression:"showRegisterGift"}}),_c('v-container',{staticClass:"register-container px-4 mb-4"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancyInput',{attrs:{"label":"寶貝暱稱*(必填)","iconName":"ic24Pname.svg","rules":[() => !!_vm.form.alias || '請輸入寶貝暱稱'],"required":""},model:{value:(_vm.form.alias),callback:function ($$v) {_vm.$set(_vm.form, "alias", $$v)},expression:"form.alias"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancySelect',{attrs:{"iconName":"ic24Breed.svg","rules":[() => !!_vm.form.type || '請輸入寶貝種類'],"items":_vm.petTypeList,"options":_vm.petTypeList,"required":""},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancySelect',{attrs:{"label":"寶貝品種*(必填)","iconName":"ic24Breed.svg","rules":[() => !!_vm.form.breed || '請輸入寶貝品種'],"items":_vm.petBreedList.filter(function (obj) {
                                if (obj.type == _vm.form.type) return true;
                                else return false;
                            }),"options":_vm.petBreedList.filter(function (obj) {
                                if (obj.type == _vm.form.type) return true;
                                else return false;
                            }),"required":""},model:{value:(_vm.form.breed),callback:function ($$v) {_vm.$set(_vm.form, "breed", $$v)},expression:"form.breed"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"6","sm":"6"}},[_c('FancySelect',{attrs:{"label":"寶貝生日 年","iconName":"ic24Birthday.svg","items":_vm.yearOptions,"options":_vm.yearOptions},model:{value:(_vm.form.birthday_year),callback:function ($$v) {_vm.$set(_vm.form, "birthday_year", $$v)},expression:"form.birthday_year"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"3","sm":"3"}},[_c('FancySelect',{attrs:{"label":"月","options":[
                            {
                                text: '月',
                                value: '',
                                selected: true,
                                disabled: true,
                            },
                            { text: '01', value: '01' },
                            { text: '02', value: '02' },
                            { text: '03', value: '03' },
                            { text: '04', value: '04' },
                            { text: '05', value: '05' },
                            { text: '06', value: '06' },
                            { text: '07', value: '07' },
                            { text: '08', value: '08' },
                            { text: '09', value: '09' },
                            { text: '10', value: '10' },
                            { text: '11', value: '11' },
                            { text: '12', value: '12' },
                        ],"items":[
                            {
                                text: '月',
                                value: '',
                                selected: true,
                                disabled: true,
                            },
                            { text: '01', value: '01' },
                            { text: '02', value: '02' },
                            { text: '03', value: '03' },
                            { text: '04', value: '04' },
                            { text: '05', value: '05' },
                            { text: '06', value: '06' },
                            { text: '07', value: '07' },
                            { text: '08', value: '08' },
                            { text: '09', value: '09' },
                            { text: '10', value: '10' },
                            { text: '11', value: '11' },
                            { text: '12', value: '12' },
                        ]},model:{value:(_vm.form.birthday_month),callback:function ($$v) {_vm.$set(_vm.form, "birthday_month", $$v)},expression:"form.birthday_month"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"3","sm":"3"}},[_c('FancySelect',{attrs:{"label":"日","items":_vm.dayOptions,"options":_vm.dayOptions},model:{value:(_vm.form.birthday_day),callback:function ($$v) {_vm.$set(_vm.form, "birthday_day", $$v)},expression:"form.birthday_day"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancySelect',{attrs:{"iconName":"ic24PetGender.svg","rules":[() => !!_vm.form.gender || '請輸入寶貝性別'],"options":[
                            {
                                text: '寶貝性別*(必填)',
                                value: '',
                                selected: true,
                                disabled: true,
                            },
                            { text: '公', value: '01' },
                            { text: '母', value: '02' },
                            { text: '其他', value: '03' },
                        ],"items":[
                            {
                                text: '寶貝性別*(必填)',
                                value: '',
                                selected: true,
                                disabled: true,
                            },
                            { text: '公', value: '01' },
                            { text: '母', value: '02' },
                            { text: '其他', value: '03' },
                        ],"required":""},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancySelect',{attrs:{"iconName":"ic24PetBody.svg","rules":[() => !!_vm.form.body || '請輸入寶貝體型'],"options":[
                            {
                                text: '寶貝體型*(必填)',
                                value: '',
                                selected: true,
                                disabled: true,
                            },
                            { text: '小型犬', value: '2' },
                            { text: '中型犬', value: '4' },
                            { text: '大型犬', value: '6' },
                            { text: '幼貓', value: '7' },
                            { text: '成貓', value: '8' },
                        ],"items":[
                            {
                                text: '寶貝體型*(必填)',
                                value: '',
                                selected: true,
                                disabled: true,
                            },
                            { text: '小型犬', value: '2' },
                            { text: '中型犬', value: '4' },
                            { text: '大型犬', value: '6' },
                            { text: '幼貓', value: '7' },
                            { text: '成貓', value: '8' },
                        ],"required":""},model:{value:(_vm.form.body),callback:function ($$v) {_vm.$set(_vm.form, "body", $$v)},expression:"form.body"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancyInput',{attrs:{"label":"毛色*(必填)","iconName":"ic24Comb.svg","rules":[
                            () =>
                                (!!_vm.form.comb && _vm.form.comb.length <= 5) ||
                                '請輸入寶貝毛色或輸入長度過長',
                        ],"required":""},model:{value:(_vm.form.comb),callback:function ($$v) {_vm.$set(_vm.form, "comb", $$v)},expression:"form.comb"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancyRadio',{attrs:{"row":"","items":[
                            { label: '已結紮', value: '1' },
                            { label: '未結紮', value: '0' },
                        ],"rules":[
                            () =>
                                (!!_vm.form.isNeutered &&
                                    _vm.form.isNeutered.length == 1) ||
                                '請填寫寶貝是否結紮',
                        ],"required":""},model:{value:(_vm.form.isNeutered),callback:function ($$v) {_vm.$set(_vm.form, "isNeutered", $$v)},expression:"form.isNeutered"}})],1)],1),_c('FancyButton',{staticClass:"mb-6",attrs:{"label":"完成","width":"150","height":"48"},on:{"click":_vm.goNext}}),_c('br'),(_vm.isShowJump)?_c('router-link',{staticClass:"skip mb-8",attrs:{"to":{
                    path: '/',
                }}},[_vm._v(" 跳過 ")]):_vm._e()],1)],1),_c('FancyDialog',{model:{value:(_vm.isSuccessOpened),callback:function ($$v) {_vm.isSuccessOpened=$$v},expression:"isSuccessOpened"}},[_c('v-container',{staticClass:"dialog_pet_success",attrs:{"fluid":""}},[_c('img',{staticClass:"mb-5",attrs:{"width":"90","height":"90","src":require("@/assets/images/img_dog.svg"),"alt":""}}),_c('p',{staticClass:"title mb-3"},[_vm._v("恭喜您")]),_c('p',{staticClass:"desc mb-6"},[_vm._v("已成功新增寶貝資料")]),_c('div',{staticClass:"footer d-flex justify-center"},[_c('FancyButton',{staticClass:"btn mr-3",attrs:{"isGreen":"","label":"確認"},on:{"click":function($event){_vm.$route.query.prev != 'otp'
                            ? _vm.$router.go(-1)
                            : _vm.$router.push('/')}}}),_c('FancyButton',{staticClass:"btn",attrs:{"color":"primary","label":"新增其他寶貝"},on:{"click":function($event){return _vm.refresh()}}})],1)])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }