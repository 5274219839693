<template>
    <v-main v-if="!$store.state.isLoading">
        <BackOnTop v-if="is_cancel" title="已取消" :url="url" />
        <!-- <BackOnTop
            v-else-if="$route.query.PBS_ShtType != 'R'"
            showRight
            labelRight="修改資料"
            title="已預約"
            :url="url"
            @click:right="$router.push('/salon/reserved/edit?reserve_no=' + id)"
        /> -->
        <BackOnTop v-else showRight labelRight="" title="已預約" :url="url" />
        <!-- <PageTitleBar title="已預約服務" /> -->
        <v-container
            fluid
            class="salon-container pt-4 px-4"
            style="height: calc(100vh - 50px)"
        >
            <div>
                <div
                    v-if="petImageObj[pet_no]"
                    class="style-confirm-img"
                    :style="{
                        border: '3px solid #FFDAAD',
                        margin: '0 auto',
                        'background-image': `url(${petImageObj[pet_no]})`,
                        width: '135px',
                        height: '135px',
                        'border-radius': '100%',
                        'background-size': 'cover',
                        'background-position': 'center',
                    }"
                ></div>
                <div class="style-confirm-bone">{{ pet_name }}</div>
            </div>
            <div
                class="style-title-l"
                style="
                    color: #ff9f4b;
                    margin-top: -20px;
                    margin-bottom: 18px;
                    font-size: 20px;
                "
            >
                {{ services.join('、') }}服務
            </div>
            <div
                class="style-title-l"
                style="
                    font-size: 18px;
                    letter-spacing: 0.8px;
                    margin-bottom: 14px;
                "
            >
                {{ date.replace(/-/g, '/') }}
            </div>
            <div
                class="style-title-l"
                style="
                    font-size: 18px;
                    letter-spacing: 0.8px;
                    margin-bottom: 18px;
                "
            >
                {{ time }}
            </div>
            <div
                class="style-body-regular"
                style="font-size: 14px; margin-bottom: 18px"
            >
                預約門市 {{ store }}
            </div>
            <!-- <SalonInfoBox :infoList="infos" />
            <h2>預約服務項目</h2>
            <SalonServiceBox class="s-box" :serviceList="services" /> -->
            <img
                src="@/assets/images/Remind.png"
                style="width: 60%; margin-bottom: 18px"
            />
            <div v-if="!is_cancel">
                <!-- <FancyCheckContract
                    v-model="isAgreeMemberContract"
                    contractLink="/member/contract"
                    hint="為了確保您的權益，請務必詳閱服務條款"
                    persistent-hint
                /> -->
                <button
                    :class="`style-btn style-l-btn  style-light-btn`"
                    @click="switchReservedCancel(true)"
                    style="border-radius: 18px; margin: 0 auto"
                >
                    取消預約<img
                        src="../../assets/images/white-foot.png"
                        style="width: 24px; height: 24px"
                    />
                </button>
            </div>
        </v-container>
        <!-- <v-container fluid class="salon-container pt-4 px-4 text-left">
            <SalonInfoBox :infoList="infos" />
            <h2>預約服務項目</h2>
            <SalonServiceBox class="s-box" :serviceList="services" />
            <SalonBottomButton
                label="取消預約"
                isOutlinedOrange
                @click="switchReservedCancel(true)"
            />
        </v-container> -->
        <PopupReservedCancel
            v-model="showReservedCancel"
            :form="formReservedCancel"
            @click:cancel_cancel_reserve="switchReservedCancel(false)"
            @click:confirm="cancelConfirm()"
        />
        <PopupReservedCancelConfirm
            v-model="showReservedCancelConfirm"
            @click:confirm="cancelConfirm()"
        />
        <PopupReservedCancelSuccess
            v-model="showReservedCancelSuccess"
            @click:confirm="$router.push('/salon/history')"
        />
        <PopupOrderDeleteError
            v-model="showOrderDeleteError"
            :phone="phone"
            @click:confirm="switchOrderDeleteError(false)"
        />
    </v-main>
</template>

<script>
import BackOnTop from '@/components/NewStyle/BackOnTop.vue';
import PopupReservedCancel from '@/components/NewPopup/PopupReservedCancel.vue';
import PopupReservedCancelConfirm from '@/components/NewPopup/PopupReservedCancelConfirm.vue';
import PopupReservedCancelSuccess from '@/components/NewPopup/PopupReservedCancelSuccess.vue';
import PopupOrderDeleteError from '@/components/NewPopup/PopupOrderDeleteError.vue';
import {
    getReserveList,
    KgGetStore,
    cancelReserve,
    KgGetReserve,
    getPetImage,
    KgGetPetList,
    KgGetSheetList,
} from '@/apis/api.js';

export default {
    name: 'SalonReserved',
    components: {
        BackOnTop,
        PopupReservedCancel,
        PopupReservedCancelConfirm,
        PopupReservedCancelSuccess,
        PopupOrderDeleteError,
    },
    data: () => ({
        showReservedCancel: false,
        showReservedCancelConfirm: false,
        showReservedCancelSuccess: false,
        showOrderDeleteError: false,
        isAgreeRegisterContract: false,
        formReservedCancel: {
            name: 'scott',
            petName: '大白',
            time: '2021/06/18 14:00',
            store: '八德興豐店',
            reason: '',
            reasonText: '',
        },
        infos: [
            { key: '會員姓名', value: '' },
            { key: '聯絡電話', value: '' },
            { key: '寶貝姓名', value: '' },
            { key: '預約門市', value: '' },
            { key: '預約日期', value: '' },
        ],
        data: {},
        services: ['剪毛', '剪指甲', '剪毛', '(加購)spa護膚'],
        pet_name: '',
        id: '',
        url: '',
        phone: '',
        pet_no: '',
        petImageObj: {},
        is_cancel: false,
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    mounted() {
        // this.$nextTick(() => {
        //     this.$store.state.isLoading = false;
        // });
    },
    created() {
        var parent = this;
        this.url =
            '/salon/history?selectedTime=' +
            parent.$route.query.selectedTime +
            '&selectedStatus=' +
            parent.$route.query.selectedStatus +
            '&selectedType=' +
            parent.$route.query.selectedType;

        var id = parent.$route.query.id;
        var store_id = parent.$route.query.store_id;
        if (parent.$route.query.is_cancel) {
            parent.is_cancel = true;
        }
        parent.id = id;
        var store_dict = {};
        var store_phone_dict = {};
        // var item_dict = ["洗澡","剪毛","游泳","洗澡+SPA","洗澡+游泳","剪毛+SPA","剪毛+游泳"];
        // 起始日 結束日為前後各60天
        var numberOfMlSeconds = new Date().getTime();
        var newDateObj = new Date(numberOfMlSeconds - 1000 * 60 * 60 * 24 * 60);
        this.start_date =
            newDateObj.getFullYear().toString() +
            (newDateObj.getMonth() + 1 < 10
                ? '0' + (newDateObj.getMonth() + 1).toString()
                : (newDateObj.getMonth() + 1).toString()) +
            (newDateObj.getDate() < 10
                ? ('0' + newDateObj.getDate()).toString()
                : newDateObj.getDate().toString());
        newDateObj = new Date(numberOfMlSeconds + 1000 * 60 * 60 * 24 * 60);
        this.end_date =
            newDateObj.getFullYear().toString() +
            (newDateObj.getMonth() + 1 < 10
                ? '0' + (newDateObj.getMonth() + 1).toString()
                : (newDateObj.getMonth() + 1).toString()) +
            (newDateObj.getDate() < 10
                ? '0' + newDateObj.getDate().toString()
                : newDateObj.getDate().toString());
        KgGetPetList(
            localStorage.getItem('phone'),
            localStorage.getItem('member_no')
        ).then(function (rsp) {
            var promises = rsp.map(function (tObj, i) {
                return getPetImage(
                    localStorage.getItem('phone'),
                    localStorage.getItem('password'),
                    rsp[i].PET_SeqNo
                ).then(function (image) {
                    image = image
                        ? image
                        : require('@/assets/images/imgCard' +
                              rsp[i].PET_Type +
                              '.jpg');
                    parent.petImageObj[rsp[i].PET_SeqNo] = image;
                    return image;
                });
            });
            Promise.all(promises).then(function () {
                KgGetStore(localStorage.getItem('phone')).then(function (
                    store_list
                ) {
                    for (let i in store_list) {
                        console.log(store_list[i]);
                        store_dict[store_list[i].STO_No] =
                            store_list[i].STO_Name;
                        store_phone_dict[store_list[i].STO_No] =
                            store_list[i].STO_Tel;
                    }
                    if (parent.$route.query.PBS_ShtType == 'R') {
                        KgGetSheetList(
                            localStorage.getItem('phone'),
                            localStorage.getItem('member_no'),
                            parent.$route.query.store_id,
                            parent.start_date,
                            parent.end_date
                        ).then(function (rsp) {
                            if (rsp) {
                                console.log('fetchSheetList', rsp);
                                rsp = rsp.filter(function (obj) {
                                    if (
                                        obj.PBS_ApptNo ||
                                        obj.PBS_ShtNo != parent.$route.query.id
                                    ) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                });
                                rsp = rsp.map(function (obj) {
                                    obj.STO_No = parent.$route.query.store_id;
                                    if (
                                        ['R0', 'R1', 'R2'].indexOf(
                                            obj.PBS_ShtStatus
                                        ) != -1
                                    ) {
                                        obj.PBS_ApptStatus = 3;
                                    } else if (
                                        ['R3'].indexOf(obj.PBS_ShtStatus) != -1
                                    ) {
                                        obj.PBS_ApptStatus = 'S1';
                                    } else if (
                                        ['R4', 'R5'].indexOf(
                                            obj.PBS_ShtStatus
                                        ) != -1
                                    ) {
                                        obj.PBS_ApptStatus = 4;
                                    }
                                    //
                                    obj.PBS_CheckInDate = obj.PBS_ShtDate;
                                    obj.PBS_ApptBegTime = obj.CRT_Date.slice(
                                        8,
                                        12
                                    );
                                    obj.PBS_ApptNo = obj.PBS_ShtNo;
                                    obj.PBS_No = obj.PBS_ShtNo;
                                    let inner_rsp = obj;
                                    var hour =
                                        inner_rsp.PBS_ApptBegTime.slice(0, 2) +
                                        ':' +
                                        inner_rsp.PBS_ApptBegTime.slice(2, 4);
                                    var is_wash =
                                        inner_rsp.PBS_Svr01 == 'Y'
                                            ? true
                                            : false;
                                    var is_spa =
                                        inner_rsp.PBS_Svr02 == 'Y'
                                            ? true
                                            : false;
                                    var is_cut =
                                        inner_rsp.PBS_Svr03 == 'Y'
                                            ? true
                                            : false;
                                    var is_swim =
                                        inner_rsp.PBS_Svr04 == 'Y'
                                            ? true
                                            : false;
                                    var is_basic =
                                        inner_rsp.PBS_Svr05 == 'Y'
                                            ? true
                                            : false;
                                    parent.infos = [
                                        {
                                            key: '會員姓名',
                                            value: inner_rsp.MEB_Name,
                                        },
                                        {
                                            key: '聯絡電話',
                                            value: inner_rsp.MEB_Mobile,
                                        },
                                        {
                                            key: '寶貝姓名',
                                            value: inner_rsp.PET_Name,
                                        },
                                        {
                                            key: '預約門市',
                                            value: store_dict[inner_rsp.STO_No],
                                        },
                                        {
                                            key: '預約日期',
                                            value:
                                                inner_rsp.PBS_CheckInDate.slice(
                                                    0,
                                                    4
                                                ) +
                                                '/' +
                                                inner_rsp.PBS_CheckInDate.slice(
                                                    4,
                                                    6
                                                ) +
                                                '/' +
                                                inner_rsp.PBS_CheckInDate.slice(
                                                    6,
                                                    8
                                                ) +
                                                '   ' +
                                                hour,
                                        },
                                    ];

                                    parent.store = store_dict[inner_rsp.STO_No];
                                    parent.data = inner_rsp;
                                    parent.date =
                                        inner_rsp.PBS_CheckInDate.slice(0, 4) +
                                        '/' +
                                        inner_rsp.PBS_CheckInDate.slice(4, 6) +
                                        '/' +
                                        inner_rsp.PBS_CheckInDate.slice(6, 8);
                                    parent.time = hour;
                                    parent.pet_name = inner_rsp.PET_Name;
                                    parent.pet_no = inner_rsp.PET_SeqNo;
                                    parent.formReservedCancel = {
                                        name: inner_rsp.MEB_Name,
                                        petName: inner_rsp.PET_Name,
                                        time:
                                            inner_rsp.PBS_CheckInDate.slice(
                                                0,
                                                4
                                            ) +
                                            '/' +
                                            inner_rsp.PBS_CheckInDate.slice(
                                                4,
                                                6
                                            ) +
                                            '/' +
                                            inner_rsp.PBS_CheckInDate.slice(
                                                6,
                                                8
                                            ) +
                                            '   ' +
                                            hour,
                                        store: store_dict[inner_rsp.STO_No],
                                        reason: '',
                                        reasonText: '',
                                    };

                                    parent.services = [];
                                    if (is_wash && is_spa) {
                                        parent.services.push('洗澡+SPA');
                                    } else if (is_wash && is_swim) {
                                        parent.services.push('洗澡+游泳');
                                    } else if (is_cut && is_spa) {
                                        parent.services.push('剪毛+SPA');
                                    } else if (is_cut && is_swim) {
                                        parent.services.push('剪毛+游泳');
                                    } else if (is_wash) {
                                        parent.services.push('洗澡');
                                    } else if (is_cut) {
                                        parent.services.push('剪毛');
                                    } else if (is_swim) {
                                        parent.services.push('游泳');
                                    }

                                    if (is_basic) {
                                        parent.services.push('基礎美容');
                                    }
                                    // parent.services = [item_dict[rsp[i].e_resv_item]]
                                    console.log(
                                        store_phone_dict,
                                        store_id,
                                        store_phone_dict[inner_rsp.STO_No]
                                    );
                                    parent.phone = `+886${store_phone_dict[
                                        inner_rsp.STO_No
                                    ]
                                        .replace('(', '')
                                        .replace(')', '')
                                        .replace('-', '')
                                        .replace('0', '')}`;
                                    parent.$store.state.isLoading = false;
                                    return obj;
                                });

                                // return true;
                            } else {
                                // return false;
                            }
                        });
                    } else {
                        getReserveList(
                            localStorage.getItem('phone'),
                            localStorage.getItem('password')
                        ).then(function (rsp) {
                            rsp = rsp
                                .filter(function (obj) {
                                    return (
                                        obj.c_pbsapptno == id &&
                                        obj.e_storeno == store_id
                                    );
                                })
                                .slice(0, 1);
                            for (let i in rsp) {
                                if (
                                    rsp[i].c_pbsapptno == id &&
                                    rsp[i].e_storeno == store_id
                                ) {
                                    KgGetReserve(
                                        localStorage.getItem('phone'),
                                        localStorage.getItem('member_no'),
                                        store_id,
                                        id
                                    ).then(function (inner_rsp) {
                                        var hour =
                                            inner_rsp.PBS_ApptBegTime.slice(
                                                0,
                                                2
                                            ) +
                                            ':' +
                                            inner_rsp.PBS_ApptBegTime.slice(
                                                2,
                                                4
                                            );
                                        var is_wash =
                                            inner_rsp.PBS_Svr01 == 'Y'
                                                ? true
                                                : false;
                                        var is_spa =
                                            inner_rsp.PBS_Svr02 == 'Y'
                                                ? true
                                                : false;
                                        var is_cut =
                                            inner_rsp.PBS_Svr03 == 'Y'
                                                ? true
                                                : false;
                                        var is_swim =
                                            inner_rsp.PBS_Svr04 == 'Y'
                                                ? true
                                                : false;
                                        var is_basic =
                                            inner_rsp.PBS_Svr05 == 'Y'
                                                ? true
                                                : false;
                                        parent.infos = [
                                            {
                                                key: '會員姓名',
                                                value: rsp[i].c_name,
                                            },
                                            {
                                                key: '聯絡電話',
                                                value: rsp[i].c_phone_num,
                                            },
                                            {
                                                key: '寶貝姓名',
                                                value: rsp[i].c_pet_name,
                                            },
                                            {
                                                key: '預約門市',
                                                value: store_dict[
                                                    rsp[i].e_storeno
                                                ],
                                            },
                                            {
                                                key: '預約日期',
                                                value:
                                                    rsp[i].c_resv_date.slice(
                                                        0,
                                                        4
                                                    ) +
                                                    '/' +
                                                    rsp[i].c_resv_date.slice(
                                                        4,
                                                        6
                                                    ) +
                                                    '/' +
                                                    rsp[i].c_resv_date.slice(
                                                        6,
                                                        8
                                                    ) +
                                                    '   ' +
                                                    hour,
                                            },
                                        ];

                                        parent.store =
                                            store_dict[rsp[i].e_storeno];
                                        parent.data = rsp[i];
                                        parent.date =
                                            rsp[i].c_resv_date.slice(0, 4) +
                                            '/' +
                                            rsp[i].c_resv_date.slice(4, 6) +
                                            '/' +
                                            rsp[i].c_resv_date.slice(6, 8);
                                        parent.time = hour;
                                        parent.pet_name = rsp[i].c_pet_name;
                                        parent.pet_no = inner_rsp.PET_SeqNo;
                                        parent.formReservedCancel = {
                                            name: rsp[i].c_name,
                                            petName: rsp[i].c_pet_name,
                                            time:
                                                rsp[i].c_resv_date.slice(0, 4) +
                                                '/' +
                                                rsp[i].c_resv_date.slice(4, 6) +
                                                '/' +
                                                rsp[i].c_resv_date.slice(6, 8) +
                                                '   ' +
                                                hour,
                                            store: store_dict[rsp[i].e_storeno],
                                            reason: '',
                                            reasonText: '',
                                        };

                                        parent.services = [];
                                        if (is_wash && is_spa) {
                                            parent.services.push('洗澡+SPA');
                                        } else if (is_wash && is_swim) {
                                            parent.services.push('洗澡+游泳');
                                        } else if (is_cut && is_spa) {
                                            parent.services.push('剪毛+SPA');
                                        } else if (is_cut && is_swim) {
                                            parent.services.push('剪毛+游泳');
                                        } else if (is_wash) {
                                            parent.services.push('洗澡');
                                        } else if (is_cut) {
                                            parent.services.push('剪毛');
                                        } else if (is_swim) {
                                            parent.services.push('游泳');
                                        }

                                        if (is_basic) {
                                            parent.services.push('基礎美容');
                                        }
                                        // parent.services = [item_dict[rsp[i].e_resv_item]]
                                        console.log(
                                            store_phone_dict,
                                            store_id,
                                            store_phone_dict[store_id]
                                        );
                                        parent.phone = `+886${store_phone_dict[
                                            store_id
                                        ]
                                            .replace('(', '')
                                            .replace(')', '')
                                            .replace('-', '')
                                            .replace('0', '')}`;
                                        parent.$store.state.isLoading = false;
                                    });
                                }
                            }
                        });
                    }
                });
            });
        });
    },
    methods: {
        switchReservedCancel(isShow) {
            this.showReservedCancel = isShow;
        },
        switchReservedCancelSuccess(isShow) {
            this.showReservedCancel = false;
            this.$nextTick(() => {
                this.showReservedCancelSuccess = isShow;
            });
        },
        switchOrderDeleteError(isShow) {
            this.showReservedCancel = false;
            this.showOrderDeleteError = isShow;
        },
        cancelConfirm() {
            var parent = this;
            // 幾天前可取消預約
            var num = 0;
            var numberOfMlSeconds = new Date().getTime();
            var newDateObj = new Date(
                numberOfMlSeconds + 1000 * 60 * 60 * 24 * num
            );
            var start_date = `${newDateObj.getFullYear()}${
                newDateObj.getMonth() + 1 < 10 ? '0' : ''
            }${newDateObj.getMonth() + 1}${
                newDateObj.getDate() < 10 ? '0' : ''
            }${newDateObj.getDate()}`;
            console.log(start_date, this.date.replace(/\//g, ''));
            if (
                this.$route.query.PBS_ShtType != 'R' &&
                start_date < this.date.replace(/\//g, '') &&
                (this.formReservedCancel.reason ||
                    this.formReservedCancel.reasonText)
            ) {
                parent.$store.state.isLoading = true;
                cancelReserve(
                    localStorage.getItem('phone'),
                    localStorage.getItem('password'),
                    localStorage.getItem('member_no'),
                    this.data.c_resv_date,
                    this.data.e_storeno,
                    this.data.c_resv_time,
                    this.data.e_resv_item,
                    this.id,
                    this.formReservedCancel.reason != '其他'
                        ? this.formReservedCancel.reason
                        : this.formReservedCancel.reasonText
                ).then(function (rsp) {
                    if (rsp) {
                        console.log(rsp);
                        parent.$store.state.isLoading = false;
                        parent.switchReservedCancelSuccess(true);
                    } else {
                        parent.$store.state.isLoading = false;
                        parent.switchOrderDeleteError(true);
                    }
                });
            } else {
                parent.$store.state.isLoading = false;
                parent.switchOrderDeleteError(true);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.salon-container {
    background-image: url('~@/assets/images/logo_background.png');
    background-size: 75%;
    background-position-x: right;
    background-position-y: 35vh;
    h2 {
        margin: 28px 0 23px 0;
        color: $color_main_first;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
    }
    .s-box {
        margin: 0 0 35px 0;
    }
}
</style>
