/* eslint-disable prettier/prettier */
import axios from 'axios';
import sha1 from 'sha1';
import md5 from 'md5';
import http from 'axios/index';
import qs from 'qs'

axios.defaults.timeout = 50000;
//測試區
// const api_key = '1234567890';
// const secret = 'GIKTk9Pbo80S4moX4RIOLQhWfs9Vy4tlfSUDCZJE';
// var kgUrl = '/KGWS/KGMBSRest.dll/TsmKGMBS/KgMbsFunc';

//正式區
const api_key = '90738873';
const secret = 'pbNFtsUAkYDvNY5B9Ajb0NkcGjiDgqo1AXuSn3Qp';
var kgUrl = '/KGMBSRest.dll/TsmKGMBS/KgMbsFunc';

// 確認會員是否領取過填寫完整資料的優惠卷
export function checkRegisterCoupon(phone, password) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            let formData = new FormData();
            formData.append('c_phone_num', phone);
            formData.append('c_password', password);
            options = {
              headers: {
                  headers: {
                    'Content-Type': 'multipart/form-data;',
                  },
              },
                params: formData,
            };
            console.log(options);
            axios.post('/api/Specific/ChkRegCoupon', options.params, options.headers)
            .then((res) => {
              console.log("/api/Specific/ChkRegCoupon",res.data);
              if(res.data.respcode == "0"){
                resolve(false);
              } else {
                resolve(res.data);
              }
              
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// 取得日期區間內 無法預約的日期陣列
export function getQueryTotalOfServItemQtyADayIsZero(startdate,enddate,servitemno,storeno) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
              headers: {
                  headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                  }
              },
                params: {                
                    startdate:startdate,
                    enddate:enddate,
                    servitemno:servitemno.toString(),
                    storeno:storeno
                },
            };
            console.log(options);
          //   resolve(true);
            axios.post('/api/Reserve/QueryTotalOfServItemQtyADayIsZero', options.params, options.headers)
            .then((res) => {
              console.log("QueryTotalOfServItemQtyADayIsZero",res.data);
              resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
  }

// APP API 取得有美容服務的門市列表

export function getServiceStoreList() {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
              headers: {
                  headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                  }
              },
                params: {
                },
            };
            console.log(options);
          //   resolve(true);
            axios.post('/api/Reserve/GetTheSTOSRVInfo', options.params, options.headers)
            .then((res) => {
              console.log("checkPhoneExist",res.data);
              resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
  }

// APP API 確認是否註冊
export function checkPhoneExist(phone) {
  return new Promise(function (resolve) {
      (async () => {
          let options = {};
          options = {
            headers: {
                headers: {
                  'Content-Type': 'application/json; charset=utf-8',
                }
            },
              params: {
                c_phone_num:phone
              },
          };
          console.log(options);
        //   resolve(true);
          axios.post('/api/MemberData/ChkPhoneNumIsExist', options.params, options.headers)
          .then((res) => {
            console.log("checkPhoneExist",res.data);
            resolve(res.data);
          })
          .catch((error) => {
              console.log(error);
              resolve(false);
          });
      })();
  });
}

// APP API 取得使用者資料
export function getUserInfo(phone,password) {
  return new Promise(function (resolve) {
      (async () => {
          let options = {};
          options = {
            headers: {
                headers: {
                  'Content-Type': 'application/json; charset=utf-8',
                },
            },
            params: {
                c_phone_num:phone,
                c_password:password
            },
          };
          console.log(options);
          axios.post('/api/MemberData/GetUserInfo', options.params, options.headers)
          .then((res) => {
            console.log("GetUserInfo",res.data);
            if(res.data.errorMsg){
              resolve(false);
            } else {
              resolve(res.data);
            }
            
          })
          .catch((error) => {
              console.log(error);
              resolve(false);
          });
      })();
  });
}

// APP API 註冊
export function register(phone,name,password,gender,birth,device_id,member_no,reg_storeno) {
  return new Promise(function (resolve) {
      (async () => {
          let options = {};
          options = {
            headers: {
                headers: {
                  'Content-Type': 'application/json; charset=utf-8',
                },
            },
            params: {
                c_phone_num:phone,
                c_name:name,
                c_password:password,
                c_gender:gender,
                c_birth:birth,
                c_deviceid:device_id,
                member_no:member_no,
                reg_storeno:reg_storeno,
                id_card:""
            },
          };
          console.log(options);
          axios.post('/api/MemberData/AddNewUser', options.params, options.headers)
          .then((res) => {
            console.log("register",res.data);
            resolve(res.data)
          })
        .catch((error) => {
            console.log(error);
            resolve(false);
        });
      })();
  });
}

// APP API 登入
export function login(phone,password) {
  return new Promise(function (resolve) {
      (async () => {
          let options = {};
          options = {
              headers: {
                headers:{
                    'Content-Type': 'application/json; charset=utf-8'
                },
              },
              params: {
                c_phone_num:phone,
                c_password:password
              },
          };
          console.log(options);
        //   resolve(true);
          axios.post('/api/MemberData/ChkPwdIsMatch', options.params,options.headers)
          .then((res) => {
            console.log("login",res.data);       
            resolve(res.data);   
          })
.catch((error) => {
                console.log(error);
                resolve(false);
            });
      })();
  });
}

// APP API 發送簡訊
export function sendVerifyCode(phone) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                  c_phone_num:phone
                },
            };
            console.log(options);
            // resolve(true);
            axios.post('/api/MemberData/SendVerifyCode', options.params, options.headers)
            .then((res) => {
              console.log("sendVerifyCode",res.data);
              resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API 簡訊驗證
export function checkVerifyCode(phone,verify_code) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                  c_phone_num:phone,
                  c_verifycode:verify_code
                },
            };
            console.log(options);
            // resolve(true);
            axios.post('/api/MemberData/ChkVerifyCode', options.params, options.headers)
            .then((res) => {
                console.log("checkVerifyCode",res.data);
                resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
  }


// APP API 檢查手機裝置ID
export function checkDeviceIdMatch(phone,password,device_id) {
  return new Promise(function (resolve) {
      (async () => {
          let options = {};
          options = {
            headers: {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                },
            },
              params: {
                c_phone_num:phone,
                c_password:password,
                c_deviceid:device_id
              },
          };
          console.log(options);
          axios.post('/api/MemberData/ChkDevIsMatch', options.params, options.headers)
          .then((res) => {
            // console.log("checkDeviceIdMatch",res.data);
            resolve(res.data);
            
          })
            .catch((error) => {
                console.log(error);
                // 避免因為timeout被登出
                resolve(true);
            });
      })();
  });
}


// APP API 更換密碼
export function updatePassword(phone,old_password,new_password) {
  return new Promise(function (resolve) {
      (async () => {
          let options = {};
          options = {
            headers: {
                headers: {
                  'Content-Type': 'application/json; charset=utf-8',
              },
            },
              params: {
                c_phone_num:phone,
                c_old_password:old_password,
                c_new_password:new_password
              },
          };
          console.log(options);
        //   resolve(true)
          axios.post('/api/MemberData/UptPwd', options.params,options.headers)
          .then((res) => {
            console.log("updatePassword",res.data);
            resolve(res.data);
          })
.catch((error) => {
                console.log(error);
                resolve(false);
            });
      })();
  });
}

// APP API 更換密碼
export function resetPassword(phone) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
              headers: {
                  headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                },
              },
                params: {
                  c_phone_num:phone
                }
            };
            console.log(options);
          //   resolve(true)
            axios.post('/api/MemberData/ResetPwd', options.params,options.headers)
            .then((res) => {
              console.log("ResetPwd",res.data);
              resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
  }

// APP API 更換device id
export function updateDeviceId(phone, password, device_id) {
  return new Promise(function (resolve) {
      (async () => {
          let options = {};
          options = {
            headers: {
                    headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                },
            },
              params: {
                c_phone_num:phone,
                c_password:password,
                c_deviceid:device_id
              },
          };
          console.log(options);
          axios.post('/api/MemberData/UptDeviceId', options.params, options.headers)
          .then((res) => {
            console.log("updateDeviceId",res.data);
            resolve(res.data);
          })
.catch((error) => {
                console.log(error);
                resolve(false);
            });
      })();
  });
}

// APP API 更換使用者資料
export function updateUserInfo(phone, password, name, birth, gender, home_num, email, county, postcode, address,member_no,reg_storeno,id_card) {
  return new Promise(function (resolve) {
      (async () => {
          let options = {};
          options = {
            headers: {
                headers: {
                  'Content-Type': 'application/json; charset=utf-8',
                },
            },
              params: {
                c_phone_num:phone,
                c_password:password,
                c_name:name,
                c_birth:birth,
                c_gender:gender,
                c_home_num:home_num,
                c_email:email,
                c_county:county,
                c_postcode:postcode,
                c_address:address,
                reg_storeno:reg_storeno,
                id_card:id_card
              },
          };
          console.log(options);
        //   resolve(true)
          axios.post('/api/MemberData/UptUserInfo', options.params, options.headers)
          .then((res) => {
            console.log("UptUserInfo",res.data);
            KgUpdateMember(phone,member_no,password,name,gender,birth,home_num,postcode,address,email).then(function(rsp){
                resolve(rsp);
            })
          })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
      })();
  });
}

// APP API 刪除寵物
export function deletePet(phone, password, seqno, reason) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            console.log(reason);
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                  c_phone_num:phone,
                  c_password:password,
                  c_pet_seqno:seqno,
                  c_reason:reason
                },
            };
            console.log("DelPets",options);
            // resolve(true);
            axios.post('/api/PetData/DelPets', options.params,options.headers)
            .then((res) => {
              console.log("deletePet",res.data);
              resolve(res.data);
              if(res.data){
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API 查詢寵物
export function queryPet(phone, password, seqno) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                  c_phone_num:phone,
                  c_password:password,
                  c_pet_seqno:seqno
                },
            };
            console.log("QueryPetsNickName",options);
            // resolve(true);
            axios.post('/api/PetData/QueryPetsNickName', options.params,options.headers)
            .then((res) => {
              console.log("QueryPetsNickName",res.data);
              if(res.data){
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API 查詢寵物是否存在
export function isPetExist(phone, password, seqno) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                  c_phone_num:phone,
                  c_password:password,
                  c_pet_seqno:seqno
                },
            };
            console.log("QueryPetsIsExist",options);
            // resolve(true);
            axios.post('/api/PetData/QueryPetsIsExist', options.params,options.headers)
            .then((res) => {
              console.log("QueryPetsIsExist",res.data);
              if(res.data){
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API 新增寵物
export function insertPet(phone, password, seqno, name) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                  c_phone_num:phone,
                  c_password:password,
                  c_pet_seqno:seqno,
                  c_pet_nickname:name
                },
            };
            console.log("insertPet",options);
            // resolve(true);
            axios.post('/api/PetData/AddPets', options.params,options.headers)
            .then((res) => {
              console.log("AddPets",res.data);
              if(res.data){
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

  // APP API 美容名額
export function getReserveInfo(reserv_date, store,reserv_time) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    reserv_date:reserv_date,
                    store_no:store,
                    reserv_time:reserv_time
                },
            };
            console.log(options);
            axios.post('/api/Reserve/QueryReseveInfo', options.params, options.headers)
            .then((res) => {
              
              if(res.data){
                var data = JSON.parse(res.data);
                // debug
                // data = {
                //     e_cut: 1,
                //     e_cutspa: 1,
                //     e_cutswim: 1,
                //     e_swim: 1,
                //     e_wash: 1,
                //     e_washspa: 1,
                //     e_washswim: 1
                // }
                // console.log("QueryReseveInfo",reserv_time,data);
                resolve(data);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
  }

// APP API 新增美容預約
export function addReserve(c_phone_num, c_password,member_no,c_name,pet_no,c_pet_name,reserv_date,store_no,reserv_time,reserv_item,PBS_Svr05="N") {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_phone_num:c_phone_num,
                    c_password:c_password,
                    c_name:c_name,
                    c_pet_name:c_pet_name,
                    reserv_date:reserv_date,
                    store_no:store_no,
                    reserv_time:reserv_time,
                    reserv_item:reserv_item,
                },
            };
            console.log("addReserve",options);
            KgGetReserveNo(c_phone_num, store_no, reserv_date).then(function(rsp){
                if(rsp){
                    var reserve_no = rsp.PBS_ApptNo;
                    options.params.c_pbsapptno = reserve_no;
                    axios.post('/api/Reserve/CreateReqForReserv', options.params, options.headers)
                    .then((res) => {
                        console.log("addReserve",res.data);
                        if(res.data == 0){
                            KgAddReserve(c_phone_num,member_no,pet_no,store_no,reserv_date,reserve_no,reserv_item,reserv_time,PBS_Svr05).then(function(rsp){
                                if(rsp){
                                    resolve(res.data);
                                } else {
                                    resolve(3);
                                }
                            })
                        } else {
                            resolve(res.data);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        resolve(3);
                    });   
                } 
            })
        })();
    });
}

// APP API 修改美容預約
export function updateReserve(c_phone_num, c_password,member_no,c_name,pet_no,c_pet_name,reserv_date,store_no,reserv_time,reserv_item,reserve_no,ori_store_no,PBS_Svr05="N") {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_pbsapptno:reserve_no,
                    c_phone_num:c_phone_num,
                    c_password:c_password,
                    c_name:c_name,
                    c_pet_name:c_pet_name,
                    reserv_date:reserv_date,
                    store_no:store_no,
                    reserv_time:reserv_time,
                    reserv_item:reserv_item,
                },
            };
            console.log("updateReserve",options);
            // axios.post('/api/Reserve/UptReqForReserv', options.params, options.headers)
            // .then((res) => {
            //     console.log("updateReserve",res.data);
            //     if(res.data == 0){
            //         KgUpdateReserve(c_phone_num,member_no,pet_no,store_no,reserv_date,reserve_no,reserv_item,reserv_time).then(function(rsp){
            //             if(rsp){
            //                 resolve(res.data);
            //             } else {
            //                 resolve(3)
            //             }
            //         })
            //     } else {
            //         resolve(res.data);
            //     }
            // })
            // .catch((error) => {
            //     console.log(error);
            //     resolve(3)
            // });
            KgUpdateReserve(c_phone_num,member_no,pet_no,store_no,reserv_date,reserve_no,reserv_item,reserv_time,ori_store_no,PBS_Svr05).then(function(rsp){
                if(rsp == "change_store"){
                    addReserve(c_phone_num, c_password,member_no,c_name,pet_no,c_pet_name,reserv_date,store_no,reserv_time,reserv_item,PBS_Svr05).then(function(rsp){
                        resolve(rsp);
                    })
                } else if(rsp){
                    axios.post('/api/Reserve/UptReqForReserv', options.params, options.headers)
                    .then((res) => {
                      console.log("updateReserve",res.data);
                      resolve(res.data);
                    })
                    .catch((error) => {
                        console.log(error);
                        resolve(3)
                    });
                } else {
                    resolve(3)
                }
            })
        })();
    });
}

// APP API 取消美容預約
export function cancelReserve(c_phone_num, c_password,member_no,reserv_date,store_no,reserv_time,reserv_item,reserve_no,del_reason) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_pbsapptno:reserve_no,
                    c_phone_num:c_phone_num,
                    c_password:c_password,
                    reserv_date:reserv_date,
                    store_no:store_no,
                    reserv_time:reserv_time,
                    reserv_item:reserv_item,
                    del_reason:del_reason
                },
            };
            console.log("cancelReserve",options);
            var cancel_code = "0";
            if(del_reason == "有事無法前來") cancel_code = "0"
            if(del_reason == "公司服務態度不佳") cancel_code = "1"
            if(del_reason.match("其他") != null) cancel_code = "2"
            KgCancelReserve(c_phone_num,member_no,store_no,reserve_no,cancel_code,del_reason).then(function(rsp){
                if(rsp){
                    axios.post('/api/Reserve/CancelReqForReserv', options.params, options.headers)
                    .then((res) => {
                        console.log(res.data);
                        resolve(res.data)
                    })

                } else {
                    resolve(false);
                    // axios.post('/api/Reserve/CancelReqForReserv', options.params, options.headers)
                    // .then((res) => {
                    //     console.log(res.data);
                    //     resolve(res.data)
                    // })
                }
            })
        })();
    });
}

// APP API 美容預約記錄列表
export function getReserveList(c_phone_num,c_password,days=60) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_phone_num:c_phone_num,
                    c_password:c_password,
                    days:days
                },
            };
            console.log("getReserveList", options);
            axios.post('/api/Reserve/QueryCustomReserv', options.params, options.headers)
            .then((res) => {
            //   console.log("getReserveList",res.data);
              resolve(JSON.parse(res.data));
            //   if(res.data){
            //     resolve(true);
            //   } else {
            //     resolve(false);
            //   }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API 美容意見回饋
export function createFeedback(c_phone_num,c_password,c_pbsapptno,e_storeno,c_star,c_feedback) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_phone_num:c_phone_num,
                    c_password:c_password,
                    c_pbsapptno:c_pbsapptno,
                    store_no:e_storeno,
                    c_star:c_star,
                    c_feedback:c_feedback
                },
            };
            console.log("createFeedback", options);
            axios.post('/api/Reserve/CreateFeedback', options.params, options.headers)
            .then((res) => {
              console.log("createFeedback",res.data);
              resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API 確認美容意見回饋完成
export function checkFeedback(c_phone_num,c_password,c_pbsapptno) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_phone_num:c_phone_num,
                    c_password:c_password,
                    c_pbsapptno:c_pbsapptno,
                },
            };
            console.log("checkFeedback", options);
            axios.post('/api/Reserve/ChkFeedback', options.params, options.headers)
            .then((res) => {
              console.log("checkFeedback",res.data);
              resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}


// APP API 上傳個人照片
export function uploadMemberImage(c_phone_num,image) {
    return new Promise(function (resolve) {
        (async () => {
            let formData = new FormData();
            formData.append('file', image);
            axios.post( '/api/MemberData/UploadImgFile?phone=' + c_phone_num,
                formData,
                {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
              }
            ).then(function(){
                console.log('SUCCESS!!');
                resolve(true)
        })
        .catch(function(){
            console.log('FAILURE!!');
            resolve(false);
        });
        })();
    });
}

// APP API 取得個人照片
export function getMemberImage(c_phone_num, c_password) {
    return new Promise(function (resolve) {
        http.post('/api/MemberData/DownloadImgFile',{
            c_phone_num:c_phone_num,
            c_password:c_password
        }, {
            responseType: 'blob' // stream也可以，但是瀏覽器會報屬性無法識別的警告，arraybuffer也可以
        })
        .then((res) => {
            var blob = new Blob([res.data], { type: 'application/' + res.headers['content-type'] }) 
            var href = window.URL.createObjectURL(blob) // 創建下載的鏈接
            console.log(href);
            resolve(href)
        })
        .catch((err) =>{
            console.log(err)
            resolve(false);
        })
    });
}

// APP API 上傳寵物照片
export function uploadPetImage(c_phone_num,no, image) {
    return new Promise(function (resolve) {
        (async () => {
            let formData = new FormData();
            formData.append('file', image);
            axios.post( '/api/PetData/UploadImgFile?phone=' + c_phone_num + "&no=" + no,
                formData,
                {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
              }
            ).then(function(){
                console.log('SUCCESS!!');
                resolve(true)
        })
        .catch(function(){
            console.log('FAILURE!!');
            resolve(false);
        });
        })();
    });
}

// APP API 取得寵物照片
export function getPetImage(c_phone_num, c_password, no) {
    return new Promise(function (resolve) {
        (async () => {
            http.post('/api/PetData/DownloadImgFile',{
                c_phone_num:c_phone_num,
                c_password:c_password,
                c_pet_seqno:no
            }, {
                responseType: 'blob' // stream也可以，但是瀏覽器會報屬性無法識別的警告，arraybuffer也可以
            })
            .then((res) => {
                var blob = new Blob([res.data], { type: 'application/' + res.headers['content-type'] }) 
                var href = window.URL.createObjectURL(blob) // 創建下載的鏈接
                console.log(href);
                resolve(href)
            })
            .catch((err) =>{
                console.log(err)
                resolve(false);
            })
        })();
    });
}

// APP API 取得個人優惠卷列表
export function getPersonalCouponList(c_phone_num,c_password) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_phone_num:c_phone_num,
                    c_password:c_password,
                },
            };
            console.log("QueryPerCoupon", options);
            axios.post('/api/Coupon/QueryPerCoupon', options.params, options.headers)
            .then((res) => {
              console.log("QueryPerCoupon",JSON.parse(res.data));
              if(JSON.parse(res.data)){
                resolve(JSON.parse(res.data));
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API 取得所有優惠卷列表
export function getCouponList(c_phone_num,c_password) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_phone_num:c_phone_num,
                    c_password:c_password,
                },
            };
            console.log("QueryAllCoupon", options);
            axios.post('/api/Coupon/QueryAllCoupon', options.params, options.headers)
            .then((res) => {
            //   console.log("QueryAllCoupon",JSON.parse(res.data));
              if(res.data){
                resolve(res.data);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API  使用優惠卷
export function useCoupon(c_phone_num, c_password, barcode, tNo, pcid) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_phone_num:c_phone_num,
                    c_password:c_password,
                    barcode:barcode,
                    tNo:tNo,
                    pcid:pcid
                },
            };
            console.log("UsePerCoupon", options);
            axios.post('/api/Coupon/UsePerCoupon', options.params, options.headers)
            .then((res) => {
              console.log("UsePerCoupon",res.data);
              if(res.data){
                resolve(res.data);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API  領取優惠卷
export function getCoupon(c_phone_num, c_password, tNo) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_phone_num:c_phone_num,
                    c_password:c_password,
                    tNo:tNo
                },
            };
            console.log("GetCoupon", options);
            axios.post('/api/Coupon/GetCoupon', options.params, options.headers)
            .then((res) => {
              console.log("GetCoupon",res.data);
              if(res.data){
                resolve(res.data);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}


// APP API  輸入序號領取優惠卷
export function useCodeGetCoupon(c_phone_num, c_password, exserial) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_phone_num:c_phone_num,
                    c_password:c_password,
                    exserial:exserial
                },
            };
            console.log("IptSerialGetCoupon", options);
            axios.post('/api/Coupon/IptSerialGetCoupon', options.params, options.headers)
            .then((res) => {
              console.log("IptSerialGetCoupon",res.data);
              if(res.data){
                resolve(res.data);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}


// APP API 即時同步寵物
export function syncPetData(c_phone_num, c_password) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_phone_num:c_phone_num,
                    c_password:c_password,
                },
            };
            console.log("syncPetData", options);
            axios.post('/api/Specific/SyncPetData', options.params, options.headers)
            .then((res) => {
              console.log("syncPetData",res.data);
              if(res.data){
                resolve(res.data);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API 下載couponimge
export function getCouponImage(tNo) {
    return new Promise(function (resolve) {
        (async () => {
            http.post('/api/Coupon/GetCouponImg',{
                tNo:tNo
            }, {
                responseType: 'blob' // stream也可以，但是瀏覽器會報屬性無法識別的警告，arraybuffer也可以
            })
            .then((res) => {
                var blob = new Blob([res.data], { type: 'application/' + res.headers['content-type'] }) 
                var href = window.URL.createObjectURL(blob) // 創建下載的鏈接
                console.log(href);
                resolve(href)
            })
        })();
    });
}

// APP API 下載美容照片
export function getPict(url) {
    return new Promise(function (resolve) {
        (async () => {
            http.get(url,{
                // tNo:tNo
            }, {
                responseType: 'blob' // stream也可以，但是瀏覽器會報屬性無法識別的警告，arraybuffer也可以
            })
            .then((res) => {
                var blob = new Blob([res.data], { type: 'application/' + res.headers['content-type'] }) 
                var href = window.URL.createObjectURL(blob) // 創建下載的鏈接
                console.log(href);
                resolve(href)
            })
        })();
    });
}

// APP API coupon content
export function getCouponContent(tNo) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    tNo:tNo
                },
            };
            console.log("GetCoupon", options);
            axios.post('/api/Coupon/GetContent', options.params, options.headers)
            .then((res) => {
              
              var result = JSON.parse(res.data);
              console.log("GetCoupon",result);
              if(result.content){
                resolve(result.content);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API 檢查個人資料
export function checkPersonalData(c_phone_num,c_password) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_phone_num:c_phone_num,
                    c_password:c_password
                },
            };
            console.log("ChkPerData", options);
            axios.post('/api/Coupon/ChkPerData', options.params, options.headers)
            .then((res) => {
              console.log("ChkPerData",res.data);
              if(res.data){
                resolve(res.data);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API 檢查寵物資料
export function checkPetData(c_phone_num,c_password) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_phone_num:c_phone_num,
                    c_password:c_password
                },
            };
            console.log("ChkPetData", options);
            axios.post('/api/Coupon/ChkPetData', options.params, options.headers)
            .then((res) => {
              console.log("ChkPetData",res.data);
              if(res.data){
                resolve(res.data);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API 領取個人優惠卷
export function getRegPre(c_phone_num,c_password) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_phone_num:c_phone_num,
                    c_password:c_password
                },
            };
            console.log("GetRegPre", options);
            axios.post('/api/Coupon/GetRegPre', options.params, options.headers)
            .then((res) => {
              console.log("GetRegPre",res.data);
              if(res.data){
                resolve(res.data);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API 確認個人優惠卷
export function checkReg(c_phone_num,c_password) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_phone_num:c_phone_num,
                    c_password:c_password
                },
            };
            console.log("ChkNewReg", options);
            axios.post('/api/Coupon/ChkNewReg', options.params, options.headers)
            .then((res) => {
              console.log("ChkNewReg",res.data);
              if(res.data){
                resolve(res.data);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API 寵物聊天室... 
export function getHomePageArticle() {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {},
            };
            console.log("getHomePageArticle", options);
            axios.get('/api/Admin/GetLinkContent', options.params, options.headers)
            .then((res) => {
              console.log("getHomePageArticle",res.data);
              if(res.data){
                resolve(res.data);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API  職人推薦
export function getRecommendList() {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {},
            };
            console.log("getRecommendList", options);
            axios.get('/api/Admin/GetRecommend', options.params, options.headers)
            .then((res) => {
              console.log("getRecommendList",JSON.parse(res.data));
              if(JSON.parse(res.data)){
                resolve(JSON.parse(res.data));
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API 下載職人推薦圖片
export function getRecommendImage(id) {
    return new Promise(function (resolve) {
        (async () => {
            http.post('/api/Admin/DownloadRecImg',{
                id:id
            }, {
                responseType: 'blob' // stream也可以，但是瀏覽器會報屬性無法識別的警告，arraybuffer也可以
            })
            .then((res) => {
                var blob = new Blob([res.data], { type: 'application/' + res.headers['content-type'] }) 
                var href = window.URL.createObjectURL(blob) // 創建下載的鏈接
                console.log(href);
                resolve(href)
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API 綁定fcm_token
export function updateDeviceToken(c_phone_num,appid) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_phone_num:c_phone_num,
                    appid:appid
                },
            };
            console.log("updateDeviceToken", options);
            axios.post('/api/MemberData/UptDeviceToken', options.params, options.headers)
            .then((res) => {
              console.log("updateDeviceToken",res.data);
              resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API 推播個人消息列表
export function GetFcmPushList(c_phone_num,c_password) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_phone_num:c_phone_num,
                    c_password:c_password
                },
            };
            console.log("GetPerPushLog", options);
            axios.post('/api/MemberData/GetPerPushLog', options.params, options.headers)
            .then((res) => {
              console.log("GetPerPushLog",res.data);
              resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API 推播已讀
export function updateIsRead(c_phone_num, c_password, id) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_phone_num:c_phone_num,
                    c_password:c_password,
                    id:id
                },
            };
            console.log("UptIsRead", options);
            axios.post('/api/MemberData/UptIsRead', options.params, options.headers)
            .then((res) => {
              console.log("UptIsRead",res.data);
              resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API 查詢個人集點卡 
export function showRewardCard(c_phone_num, c_password) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_phone_num:c_phone_num,
                    c_password:c_password,
                },
            };
            console.log("ShowRewardCard", options);
            axios.post('/api/Reward/ShowRewardCard', options.params, options.headers)
            .then((res) => {
              console.log("ShowRewardCard",res.data);
              resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API 點數歷程 
export function pointRecord(c_phone_num, c_password) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_phone_num:c_phone_num,
                    c_password:c_password,
                },
            };
            console.log("PointRecord", options);
            axios.post('/api/Reward/PointRecord', options.params, options.headers)
            .then((res) => {
              console.log("PointRecord",res.data);
              resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API 查詢所有集點可以兌換的票券 
export function queryAllRewardCoupon(c_phone_num, c_password) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_phone_num:c_phone_num,
                    c_password:c_password,
                },
            };
            console.log("QueryAllRewardCoupon", options);
            axios.post('/api/Reward/QueryAllRewardCoupon', options.params, options.headers)
            .then((res) => {
            //   console.log("QueryAllRewardCoupon",res.data);
              resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API 查詢集點活動辦法
export function getRewardActivity(c_phone_num, c_password) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_phone_num:c_phone_num,
                    c_password:c_password,
                },
            };
            console.log("getRewardActivity", options);
            axios.post('/api/Reward/GetRewardActivity', options.params, options.headers)
            .then((res) => {
              console.log("getRewardActivity",res.data);
              resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// APP API 集點票券兌換 
export function exchangeRewardCoupon(c_phone_num, c_password, card_id, tNo) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
                params: {
                    c_phone_num:c_phone_num,
                    c_password:c_password,
                    card_id:card_id,
                    tNo:tNo
                },
            };
            console.log("ExchangeRewardCoupon", options);
            axios.post('/api/Reward/ExchangeRewardCoupon', options.params, options.headers)
            .then((res) => {
              console.log("ExchangeRewardCoupon",res.data);
              resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}



// 康技 取得店舖列表
export function KgGetStore(phone) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            let time_str = getDatetime();
            let func_id = 'KGPBS_QUERY_STO';
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Access-Control-Allow-Origin': '*',
                    },
                },
                params: {
                    FUNC_ID: func_id,
                    ACNT_NO: phone,
                    SYS_DATE: time_str,
                    FUNC_SIGNATURE: getFunctionSign(func_id, time_str, phone),
                    FUNC_DATA: {
                        EOS_No: phone,
                        TXN_DATA_HASH: md5(
                            api_key + func_id + phone
                        ),
                    },
                },
            };
            console.log("KgGetStore",options);

             await axios
            .post(kgUrl, options.params, options.headers)
            .then((res) => {
                const rtn = res.data;
                console.log(res.data);
                if(rtn.RSPN_MSG.match("執行成功") != null){
                        resolve(res.data.RSPN_DATA.STO_INFO)
                    } else {
                        resolve(false);
                    }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// 康技 取得美容預約單號
function KgGetReserveNo(phone,store_no,reserv_date) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            let time_str = getDatetime();
            let func_id = 'KGPBS_GET_APPT_NO';
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Access-Control-Allow-Origin': '*',
                    },
                },
                params: {
                    FUNC_ID: func_id,
                    ACNT_NO: phone,
                    SYS_DATE: time_str,
                    FUNC_SIGNATURE: getFunctionSign(func_id, time_str, phone),
                    FUNC_DATA: {
                        STO_No:store_no,
                        PBS_ApptDate:reserv_date,
                        EOS_No: phone,
                        TXN_DATA_HASH: md5(
                            api_key + func_id + phone + store_no + reserv_date
                        ),
                    },
                },
            };
            console.log("KgGetReserveNo",options);
             await axios
            .post(kgUrl, options.params, options.headers)
            .then((res) => {
                const rtn = res.data;
                console.log(res.data);
                if(rtn.RSPN_MSG.match("執行成功") != null){
                        resolve(res.data.RSPN_DATA)
                    } else {
                        resolve(false);
                    }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}


console.log("`${new Date().getFullYear()}${new Date().getMonth() < 9 ? '0' : ''}${new Date.getMonth()+1}${new Date().getDate() < 10 ? '0' : ''}${new Date().getDate()}`",`${new Date().getFullYear()}${new Date().getMonth() < 9 ? '0' : ''}${new Date().getMonth()+1}${new Date().getDate() < 10 ? '0' : ''}${new Date().getDate()}`)
// 康技 新增美容預約
function KgAddReserve(phone,member_no,pet_no,store_no,reserv_date,reserve_no,reserv_item,reserv_time,PBS_Svr05="N") {
    let appt_date = `${new Date().getFullYear()}${new Date().getMonth() < 9 ? '0' : ''}${new Date().getMonth()+1}${new Date().getDate() < 10 ? '0' : ''}${new Date().getDate()}`
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            let time_str = getDatetime();
            let func_id = 'KGPBS_APPT_BEAUTY';
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Access-Control-Allow-Origin': '*',
                    },
                },
                params: {
                    FUNC_ID: func_id,
                    ACNT_NO: phone,
                    SYS_DATE: time_str,
                    FUNC_SIGNATURE: getFunctionSign(func_id, time_str, phone),
                    FUNC_DATA: {
                        EOS_No:phone,
                        MEB_No:member_no,
                        PET_SeqNo:pet_no,
                        STO_No:store_no,
                        PBS_ApptDate:appt_date,
                        PBS_ApptNo:reserve_no,
                        PBS_Svr01:(reserv_item == "0" || reserv_item == "3" || reserv_item == "4") ? "Y" : "N",
                        PBS_Svr02:(reserv_item == "3" || reserv_item == "5") ? "Y" : "N",
                        PBS_Svr03:(reserv_item == "1" || reserv_item == "5" || reserv_item == "6") ? "Y" : "N",
                        PBS_Svr04:(reserv_item == "2" || reserv_item == "4" || reserv_item == "6") ? "Y" : "N",
                        PBS_Svr05:PBS_Svr05,
                        PBS_CheckInDate:reserv_date,
                        PBS_ApptBegTime:reserv_time + "00",
                        TXN_DATA_HASH: md5(
                            api_key + func_id + phone + member_no + pet_no + store_no + appt_date + reserve_no
                        ),
                    },
                },
            };
            console.log("KgAddReserve",options);
            
             await axios
            .post(kgUrl, options.params, options.headers)
            .then((res) => {
                const rtn = res.data;
                console.log("KgAddReserve", res.data);
                if(rtn.RSPN_MSG.match("執行成功") != null){
                        resolve(res.data.RSPN_DATA)
                    } else {
                        resolve(false);
                    }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// 康技 修改美容預約
function KgUpdateReserve(phone,member_no,pet_no,store_no,reserv_date,reserve_no,reserv_item,reserv_time,ori_store_no,PBS_Svr05="N") {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            let time_str = getDatetime();
            let func_id = 'KGPBS_UPDATE_APPT';
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Access-Control-Allow-Origin': '*',
                    },
                },
                params: {
                    FUNC_ID: func_id,
                    ACNT_NO: phone,
                    SYS_DATE: time_str,
                    FUNC_SIGNATURE: getFunctionSign(func_id, time_str, phone),
                    FUNC_DATA: {
                        EOS_No:phone,
                        MEB_No:member_no,
                        PET_SeqNo:pet_no,
                        STO_No:store_no,
                        PBS_ApptDate:reserv_date,
                        PBS_ApptNo:reserve_no,
                        PBS_Svr01:(reserv_item == "0" || reserv_item == "3" || reserv_item == "4") ? "Y" : "N",
                        PBS_Svr02:(reserv_item == "3" || reserv_item == "5") ? "Y" : "N",
                        PBS_Svr03:(reserv_item == "1" || reserv_item == "5" || reserv_item == "6") ? "Y" : "N",
                        PBS_Svr04:(reserv_item == "2" || reserv_item == "4" || reserv_item == "6") ? "Y" : "N",
                        PBS_Svr05:PBS_Svr05,
                        PBS_CheckInDate:reserv_date,
                        PBS_ApptBegTime:reserv_time + "00",
                        TXN_DATA_HASH: md5(
                            api_key + func_id + phone + member_no + store_no + reserve_no
                        ),
                    },
                },
            };
            console.log("KgUpdateReserve",options);
            if(ori_store_no == store_no){
                await axios
                .post(kgUrl, options.params, options.headers)
                .then((res) => {
                    const rtn = res.data;
                    console.log("KgUpdateReserve",res.data);
                    if(rtn.RSPN_MSG.match("執行成功") != null){
                        resolve(res.data.RSPN_DATA)
                    } else if(rtn.RSPN_MSG.match('查無此預約編號') != null) {
                        KgCancelReserve(phone,member_no,ori_store_no,reserve_no,'0','修改預約').then(function(rsp){
                            if(rsp){
                                resolve("change_store");
                            } else {
                                resolve(false);
                            }
                        })
                    } else {
                        resolve(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    resolve(false);
                });
            } else {
                KgCancelReserve(phone,member_no,ori_store_no,reserve_no,'0','修改預約').then(function(rsp){
                    if(rsp){
                        resolve("change_store");
                    } else {
                        resolve(false);
                    }
                })
            }
             
        })();
    });
}

// 康技 取消美容預約
function KgCancelReserve(phone,member_no,store_no,reserve_no,cancel_code,cancel_text) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            let time_str = getDatetime();
            let func_id = 'KGPBS_DELETE_APPT';
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Access-Control-Allow-Origin': '*',
                    },
                },
                params: {
                    FUNC_ID: func_id,
                    ACNT_NO: phone,
                    SYS_DATE: time_str,
                    FUNC_SIGNATURE: getFunctionSign(func_id, time_str, phone),
                    FUNC_DATA: {
                        EOS_No:phone,
                        MEB_No:member_no,
                        STO_No:store_no,
                        PBS_ApptNo:reserve_no,
                        PBS_CancelCode:'0',
                        PBS_CancelRemak:cancel_text,
                        TXN_DATA_HASH: md5(
                            api_key + func_id + phone + member_no  + store_no + reserve_no
                        ),
                    },
                },
            };
            console.log("KgCancelReserve",options);
             await axios
            .post(kgUrl, options.params, options.headers)
            .then((res) => {
                const rtn = res.data;
                console.log(res.data);
                if(rtn.RSPN_MSG.match("執行成功") != null){
                    resolve(res.data.RSPN_DATA)
                } else {
                    resolve(false);
                }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// 康技 取得美容預約紀錄
export function KgGetReserveList(phone,member_no,status,start_date,end_date) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            let time_str = getDatetime();
            let func_id = 'KGPBS_QUERY_APPT_MULTI';
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Access-Control-Allow-Origin': '*',
                    },
                },
                params: {
                    FUNC_ID: func_id,
                    ACNT_NO: phone,
                    SYS_DATE: time_str,
                    FUNC_SIGNATURE: getFunctionSign(func_id, time_str, phone),
                    FUNC_DATA: {
                        EOS_No: phone,
                        MEB_No: member_no,
                        PBS_ApptStatus:status,
                        QRY_ApptDate_BEG:start_date,
                        QRY_ApptDate_END:end_date,
                        TXN_DATA_HASH: md5(
                            api_key + func_id + phone + member_no
                        ),
                    },
                },
            };
            console.log(options);
            
 
             await axios
            .post(kgUrl, options.params, options.headers)
            .then((res) => {
                const rtn = res.data;
                console.log(res.data);
                if(rtn.RSPN_MSG.match("執行成功") != null){
                        resolve(res.data.RSPN_DATA.PBS_APPT_INFO)
                    } else {
                        resolve(false);
                    }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// 康技 取得美容單據紀錄
export function KgGetSheetList(phone,member_no,store_no,start_date,end_date) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            let time_str = getDatetime();
            let func_id = 'KGPBS_QUERY_SHEET';
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Access-Control-Allow-Origin': '*',
                    },
                },
                params: {
                    FUNC_ID: func_id,
                    ACNT_NO: phone,
                    SYS_DATE: time_str,
                    FUNC_SIGNATURE: getFunctionSign(func_id, time_str, phone),
                    FUNC_DATA: {
                        EOS_No: phone,
                        MEB_No: member_no,
                        STO_No: store_no,
                        QRY_MebMobile: phone,
                        // PBS_ApptStatus:status,
                        QRY_ShtDate_BEG:start_date,
                        QRY_ShtDate_END:end_date,
                        QRY_ShtType:'R',
                        TXN_DATA_HASH: md5(
                            api_key + func_id + phone + store_no
                        ),
                    },
                },
            };
            console.log(options);
            
 
             await axios
            .post(kgUrl, options.params, options.headers)
            .then((res) => {
                const rtn = res.data;
                console.log(res.data);
                if(rtn.RSPN_MSG.match("執行成功") != null){
                        resolve(res.data.RSPN_DATA.PBS_SHT_INFO)
                    } else {
                        resolve(false);
                    }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}


// 康技 取得單筆美容預約紀錄
export function KgGetReserve(phone,member_no,STO_No,PBS_ApptNo) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            let time_str = getDatetime();
            let func_id = 'KGPBS_QUERY_APPT';
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Access-Control-Allow-Origin': '*',
                    },
                },
                params: {
                    FUNC_ID: func_id,
                    ACNT_NO: phone,
                    SYS_DATE: time_str,
                    FUNC_SIGNATURE: getFunctionSign(func_id, time_str, phone),
                    FUNC_DATA: {
                        EOS_No: phone,
                        MEB_No: member_no,
                        STO_No:STO_No,
                        PBS_ApptNo:PBS_ApptNo,
                        TXN_DATA_HASH: md5(
                            api_key + func_id + phone + member_no + STO_No + PBS_ApptNo
                        ),
                    },
                },
            };
            console.log(options);

             await axios
            .post(kgUrl, options.params, options.headers)
            .then((res) => {
                const rtn = res.data;
                console.log(res.data);
                if(rtn.RSPN_MSG.match("執行成功") != null){
                        resolve(res.data.RSPN_DATA)
                    } else {
                        resolve(false);
                    }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// 康技 取得單筆美容作業紀錄
export function KgGetBeauty(phone,STO_No,PBS_No,PBS_Date) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            let time_str = getDatetime();
            let func_id = 'KGPBS_QUERY_BEAUTY';
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Access-Control-Allow-Origin': '*',
                    },
                },
                params: {
                    FUNC_ID: func_id,
                    ACNT_NO: phone,
                    SYS_DATE: time_str,
                    FUNC_SIGNATURE: getFunctionSign(func_id, time_str, phone),
                    FUNC_DATA: {
                        EOS_No: phone,
                        STO_No:STO_No,
                        PBS_No:PBS_No,
                        PBS_Date:PBS_Date,
                        TXN_DATA_HASH: md5(
                            api_key + func_id + phone + STO_No + PBS_No + PBS_Date
                        ),
                    },
                },
            };
            console.log(options);

             await axios
            .post(kgUrl, options.params, options.headers)
            .then((res) => {
                const rtn = res.data;
                console.log(res.data);
                if(rtn.RSPN_MSG.match("執行成功") != null){
                        resolve(res.data.RSPN_DATA)
                    } else {
                        resolve(false);
                    }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// 康技 美容評比
export function KgEval(phone,member_no,STO_No,PBS_ApptNo,PBS_EvalRemak) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            let time_str = getDatetime();
            let func_id = 'KGPBS_EVAL_BEAUTY';
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Access-Control-Allow-Origin': '*',
                    },
                },
                params: {
                    FUNC_ID: func_id,
                    ACNT_NO: phone,
                    SYS_DATE: time_str,
                    FUNC_SIGNATURE: getFunctionSign(func_id, time_str, phone),
                    FUNC_DATA: {
                        EOS_No: phone,
                        MEB_No: member_no,
                        STO_No:STO_No,
                        PBS_ApptNo:PBS_ApptNo,
                        PBS_EvalRemak:PBS_EvalRemak,
                        TXN_DATA_HASH: md5(
                            api_key + func_id + phone + member_no + STO_No + PBS_ApptNo
                        ),
                    },
                },
            };
            console.log("KgEval",options);
             await axios
            .post(kgUrl, options.params, options.headers)
            .then((res) => {
                const rtn = res.data;
                console.log(rtn,res.data);
                // resolve(true)
                if(rtn.RSPN_MSG.match("執行成功") != null){
                    resolve(res.data.RSPN_DATA)
                } else {
                    resolve(false);
                }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// 康技 新增寵物
export function KgInsertPet(phone,member_no,petData) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            let time_str = getDatetime();
            let func_id = 'KGPBS_REGISTER_PET';
            options = {
                headers:{
                        headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Access-Control-Allow-Origin': '*',
                    },
                },
                params: {
                    FUNC_ID: func_id,
                    ACNT_NO: phone,
                    SYS_DATE: time_str,
                    FUNC_SIGNATURE: getFunctionSign(func_id, time_str, phone),
                    FUNC_DATA: {
                        EOS_No: phone,
                        MEB_No: member_no,
                        PET_Name: petData.alias,
                        PET_Type: petData.type,
                        PET_Breed: petData.breed,
                        PET_Size:petData.body,
                        PET_Sex:petData.gender,
                        PET_Fix:petData.isNeutered,
                        PET_Color:petData.comb,
                        PET_Birth:petData.birthday,
                        TXN_DATA_HASH: md5(
                            api_key + func_id + phone + member_no
                        ),
                    },
                },
            };
            console.log(options);
            // resolve(true);
             await axios
            .post(kgUrl, options.params, options.headers)
            .then((res) => {
                const rtn = res.data;
                console.log(res.data);
                if(rtn.RSPN_MSG.match("執行成功") != null){
                        resolve(rtn.RSPN_DATA);
                    } else {
                        resolve(false);
                    }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// 康技 更新寵物資料
export function KgUpdatePet(phone,member_no,petData) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            let time_str = getDatetime();
            let func_id = 'KGPBS_UPDATE_PET';
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Access-Control-Allow-Origin': '*',
                    },
                },
                params: {
                    FUNC_ID: func_id,
                    ACNT_NO: phone,
                    SYS_DATE: time_str,
                    FUNC_SIGNATURE: getFunctionSign(func_id, time_str, phone),
                    FUNC_DATA: {
                        EOS_No: phone,
                        MEB_No: member_no,
                        PET_Name: petData.alias,
                        PET_SeqNo: petData.id,
                        PET_Type: petData.type,
                        PET_Breed: petData.breed,
                        PET_Size:petData.body,
                        PET_Sex:petData.gender,
                        PET_Fix:petData.isNeutered,
                        PET_Color:petData.comb,
                        PET_Birth:petData.birthday.replace("/","").replace("/",""),
                        TXN_DATA_HASH: md5(
                            api_key + func_id + phone + member_no + petData.id
                        ),
                    },
                },
            };
            console.log(options);
            // resolve(true);
             await axios
            .post(kgUrl, options.params, options.headers)
            .then((res) => {
                const rtn = res.data;
                console.log(res.data);
                if(rtn.RSPN_MSG.match("執行成功") != null){
                        resolve(true)
                    } else {
                        resolve(false);
                    }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// 康技 取得寵物列表
export function KgGetPetList(phone,member_no) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            let time_str = getDatetime();
            let func_id = 'KGPBS_QUERY_PET';
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Access-Control-Allow-Origin': '*',
                    },
                },
                params: {
                    FUNC_ID: func_id,
                    ACNT_NO: phone,
                    SYS_DATE: time_str,
                    FUNC_SIGNATURE: getFunctionSign(func_id, time_str, phone),
                    FUNC_DATA: {
                        EOS_No: phone,
                        MEB_No: member_no,
                        TXN_DATA_HASH: md5(
                            api_key + func_id + phone + member_no
                        ),
                    },
                },
            };
            console.log(options.params);
            
             await axios
            .post(kgUrl, options.params, options.headers)
            .then((res) => {
                const rtn = res.data;
                console.log(res.data);
                if(rtn.RSPN_MSG.match("執行成功") != null){
                        resolve(res.data.RSPN_DATA.PET_INFO)
                    } else {
                        resolve([]);
                    }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// 康技 寵物類別
export function KgGetPetType(phone) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            let time_str = getDatetime();
            let func_id = 'KGPBS_GET_PET_TYPE';
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Access-Control-Allow-Origin': '*',
                    },
                },
                params: {
                    FUNC_ID: func_id,
                    ACNT_NO: phone,
                    SYS_DATE: time_str,
                    FUNC_SIGNATURE: getFunctionSign(func_id, time_str, phone),
                    FUNC_DATA: {
                        EOS_No: phone,
                        TXN_DATA_HASH: md5(
                            api_key + func_id + phone
                        ),
                    },
                },
            };
            console.log(options);
            
             await axios
            .post(kgUrl, options.params, options.headers)
            .then((res) => {
                const rtn = res.data;
                console.log(res.data);
                if(rtn.RSPN_MSG.match("執行成功") != null){
                    if(rtn.RSPN_DATA.PET_TYPE_INFO){
                        resolve(rtn.RSPN_DATA.PET_TYPE_INFO)
                    } else {
                        resolve(false);
                    }
                } else {
                    resolve(false);
                }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// 康記 取得寵物品種資料
export function KgGetPetBreed(phone) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            let time_str = getDatetime();
            let func_id = 'KGPBS_GET_PET_BREED';
            options = {
                headers: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Access-Control-Allow-Origin': '*',
                    },
                },
                params: {
                    FUNC_ID: func_id,
                    ACNT_NO: phone,
                    SYS_DATE: time_str,
                    FUNC_SIGNATURE: getFunctionSign(func_id, time_str, phone),
                    FUNC_DATA: {
                        EOS_No: phone,
                        TXN_DATA_HASH: md5(
                            api_key + func_id + phone
                        ),
                    },
                },
            };
            console.log(options);
            
             await axios
            .post(kgUrl, options.params, options.headers)
            .then((res) => {
                const rtn = res.data;
                console.log(res.data);
                if(rtn.RSPN_MSG.match("執行成功") != null){
                    if(rtn.RSPN_DATA.PET_BREED_INFO){
                        resolve(rtn.RSPN_DATA.PET_BREED_INFO)
                    } else {
                        resolve(false);
                    }
                } else {
                    resolve(false);
                }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}


// 康技 註冊會員
export function KgRegister(phone,name,password,gender,birth) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            let time_str = getDatetime();
            let func_id = 'KGMBS_REGISTER_MEMBER';
            options = {
                headers:{
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Access-Control-Allow-Origin': '*',
                    },
                },
                params: {
                    FUNC_ID: func_id,
                    ACNT_NO: phone,
                    SYS_DATE: time_str,
                    FUNC_SIGNATURE: getFunctionSign(func_id, time_str, phone),
                    FUNC_DATA: {
                        EOS_No: phone,
                        MEB_Mobile: phone,
                        MEB_Name: name,
                        MEB_Pwd: password,
                        MEB_Sex:gender == "male" ? "M" : "F",
                        MEB_Birth:birth,
                        TXN_DATA_HASH: md5(
                            api_key + func_id + phone + phone
                        ),
                    },
                },
            };
            console.log(options);
            // resolve("2100000000");
             await axios
            .post(kgUrl, options.params, options.headers)
            .then((res) => {
                // const rtn = res.data;
                console.log(res.data);
                if(res.data.RSPN_MSG.match("執行成功") != null || res.data.RSPN_CODE == "E000"){
                    // if(res.data.RSPN_DATA.MEB_No){
                        resolve(res.data.RSPN_DATA.MEB_No)
                    // } else {
                    //     resolve(false);
                    // }
                } else {
                    resolve(false);
                }
            })
            .catch((error) => {
                console.log(error);
                resolve('timeout');
            });
        })();
    });
}

// 康技 更改密碼
export function KgUpdatePassword(phone,member_no,old_password,new_password) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            let time_str = getDatetime();
            let func_id = 'KGMBS_CHANGE_PASSWORD';
            options = {
                headers:{
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Access-Control-Allow-Origin': '*',
                    },
                },
                params: {
                    FUNC_ID: func_id,
                    ACNT_NO: phone,
                    SYS_DATE: time_str,
                    FUNC_SIGNATURE: getFunctionSign(func_id, time_str, phone),
                    FUNC_DATA: {
                        EOS_No: phone,
                        MEB_No: member_no,
                        MEB_Pwd: old_password,
                        NEW_MEB_Pwd:new_password,
                        TXN_DATA_HASH: md5(
                            api_key + func_id + phone + member_no
                        ),
                    },
                },
            };
            console.log(options);
            // resolve("2100000000");
             await axios
            .post(kgUrl, options.params, options.headers)
            .then((res) => {
                const rtn = res.data;
                console.log(res.data);
                if(rtn.RSPN_MSG.match("執行成功") != null){
                    if(rtn.RSPN_DATA.MEB_No){
                        resolve(rtn.RSPN_DATA.MEB_No)
                    } else {
                        resolve(false);
                    }
                } else {
                    resolve(false);
                }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// 康技 重設密碼
export function KgResetPassword(phone) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            let time_str = getDatetime();
            let func_id = 'KGMBS_RESET_PASSWORD';
            options = {
                headers:{
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Access-Control-Allow-Origin': '*',
                    },
                },
                params: {
                    FUNC_ID: func_id,
                    ACNT_NO: phone,
                    SYS_DATE: time_str,
                    FUNC_SIGNATURE: getFunctionSign(func_id, time_str, phone),
                    FUNC_DATA: {
                        EOS_No: phone,
                        MEB_Mobile:phone,
                        TXN_DATA_HASH: md5(
                            api_key + func_id + phone + phone
                        ),
                    },
                },
            };
            console.log(options);
            // resolve("2100000000");
             await axios
            .post(kgUrl, options.params, options.headers)
            .then((res) => {
                const rtn = res.data;
                console.log(res.data);
                if(rtn.RSPN_MSG.match("執行成功") != null){
                    var temp_str = `MEB_MOBILE=${phone}&API_KEY=${api_key}&MEB_RESETKEY=${rtn.RSPN_DATA.MEB_ResetKey}`;
                    var password = sha1(temp_str).slice(0,8)
                    if(rtn.RSPN_DATA.MEB_No){
                        resolve({
                            password:password,
                            member_no:rtn.RSPN_DATA.MEB_No
                        })
                    } else {
                        resolve(false);
                    }
                } else {
                    resolve(false);
                }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// 康技-查詢會員資料
export const KgQueryMember = async (phone,password) => {
    let options = {};
    let time_str = getDatetime();
    let func_id = 'KGMBS_QUERY_MEMBER';
    options = {
        headers:{
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Access-Control-Allow-Origin': '*',
            },
        },
        params: {
            FUNC_ID: func_id,
            ACNT_NO: phone,
            SYS_DATE: time_str,
            FUNC_SIGNATURE: getFunctionSign(func_id, time_str, phone),
            FUNC_DATA: {
                EOS_No: phone,
                MEB_Mobile: phone,
                MEB_Pwd: password,
                TXN_DATA_HASH: md5(
                    api_key + func_id + phone + phone
                ),
            },
        },
    };
    console.log(options);
    const result = await axios
        .post(kgUrl, options.params,options.headers)
        .then((res) => {
            console.log(res.data);
                if(res.data.RSPN_MSG.match("執行成功") != null){
                    return res.data.RSPN_DATA.MEB_No
                } else if(res.data.RSPN_MSG.match("查無此會員手機號碼") != null){
                    return true;
                } else if(res.data.RSPN_MSG.match("不正確") != null){
                    return "";
                } else {
                    return false;
                }
        })
        .catch((error) => {
            console.log(error);
        });
    return result;
};

// 康技-查詢會員資料return all data
export const KgQueryMemberAll = async (phone,password) => {
    let options = {};
    let time_str = getDatetime();
    let func_id = 'KGMBS_QUERY_MEMBER';
    options = {
        headers:{
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Access-Control-Allow-Origin': '*',
            },
        },
        params: {
            FUNC_ID: func_id,
            ACNT_NO: phone,
            SYS_DATE: time_str,
            FUNC_SIGNATURE: getFunctionSign(func_id, time_str, phone),
            FUNC_DATA: {
                EOS_No: phone,
                MEB_Mobile: phone,
                MEB_Pwd: password,
                TXN_DATA_HASH: md5(
                    api_key + func_id + phone + phone
                ),
            },
        },
    };
    console.log(options);
    const result = await axios
        .post(kgUrl, options.params,options.headers)
        .then((res) => {
            console.log(res.data);
                if(res.data.RSPN_MSG.match("執行成功") != null){
                    return res.data.RSPN_DATA
                } else if(res.data.RSPN_MSG.match("查無此會員手機號碼") != null){
                    return true;
                } else if(res.data.RSPN_MSG.match("不正確") != null){
                    return "";
                } else {
                    return false;
                }
        })
        .catch((error) => {
            console.log(error);
        });
    return result;
};

// 康技 更改會員資料
function KgUpdateMember(EOS_No,MEB_No,MEB_Pwd,MEB_Name,MEB_Sex,MEB_Birth,MEB_Tel,MEB_Post,MEB_Addr,MEB_Email) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            let time_str = getDatetime();
            let func_id = 'KGMBS_UPDATE_MEMBER';
            options = {
                headers:{
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Access-Control-Allow-Origin': '*',
                    },
                },
                params: {
                    FUNC_ID: func_id,
                    ACNT_NO: EOS_No,
                    SYS_DATE: time_str,
                    FUNC_SIGNATURE: getFunctionSign(func_id, time_str, EOS_No),
                    FUNC_DATA: {
                        EOS_No: EOS_No,
                        MEB_No: MEB_No,
                        MEB_Pwd: MEB_Pwd,
                        MEB_Name:MEB_Name,
                        MEB_Sex:MEB_Sex == "male" ? 'M' : "F",
                        MEB_Birth:MEB_Birth,
                        MEB_Tel:MEB_Tel,
                        MEB_Post:MEB_Post,
                        MEB_Addr:MEB_Addr,
                        MEB_Email:MEB_Email,
                        TXN_DATA_HASH: md5(
                            api_key + func_id + EOS_No + MEB_No
                        ),
                    },
                },
            };
            console.log(options);
            // resolve("2100000000");
             await axios
            .post(kgUrl, options.params, options.headers)
            .then((res) => {
                const rtn = res.data;
                console.log(res.data);
                if(rtn.RSPN_MSG.match("執行成功") != null){
                    if(rtn.RSPN_DATA.MEB_No){
                        resolve(true)
                    } else {
                        resolve(false);
                    }
                } else {
                    resolve(false);
                }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// 官方API 取得bearer token !!!!
function getToken() {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                params:{
                    'secret': secret,
                },
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded' ,
                    
                },
            };
            console.log(options);
            axios.post('/api/guest/login', qs.stringify(options.params), options.headers)
            .then((res) => {
              console.log("getToken",res.data.result.token);
              resolve(res.data.result.token);
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });
        })();
    });
}

// 官方API 取得Banner
export const getBannerList = async () => {
    return new Promise(function (resolve) {
        let options = {};
        options = {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            params: {},
        };
        axios.get('/api/mobile/pet/ad', options.params, options.headers)
        .then((res) => {
            console.log("getBannerList",res.data);
            if(res.data.errorCode == 0){
                for(let i in res.data.result.data){
                    if(res.data.result.data[i].title == "首頁輪播APP" || res.data.result.data[i].title == "首頁輪播App"){
                        axios.get('/api/mobile/pet/ad/' + res.data.result.data[i].id, options.params, options.headers)
                        .then((inner_res) => {
                            console.log("getBannerList",inner_res.data);
                            resolve(inner_res.data)
                        })
                        .catch((error) => {
                            console.log(error);
                            resolve(false);
                        });
                    }
                }
            } else {
                resolve([])
            }
            
        })
        .catch((error) => {
            console.log(error);
            resolve(false);
        });
    })
};

// 官方API 取得美容前後照片 !!!!
export function getPictList(shop_no, work_no, member_no, pet_no){
    return new Promise(function (resolve) {
        (async () => {
            getToken().then(function(token){
                let options = {};
                options = {
                    headers: {
                        headers:{
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        
                        }
                    },
                    params: {
                        shop_no:shop_no,
                        work_no:work_no,
                        member_no:member_no,
                        pet_no:pet_no
                    },
                };
                console.log(options);
                let picInfo = {
                    'before':[],
                    'after':[]
                }
                axios.post('/api/guest/work/pic/before', options.params, options.headers)
                .then((res) => {
                    console.log("getPictList",res.data);
                    if(res.data.errorCode == 0){
                        picInfo['before'] = res.data.result.pic_url.map(function(obj){
                            return obj.url;
                        })
                    }
                    axios.post('/api/guest/work/pic/after', options.params, options.headers)
                    .then((res) => {
                        console.log("getPictList",res.data);
                        if(res.data.errorCode == 0){
                            picInfo['after'] = res.data.result.pic_url.map(function(obj){
                                return obj.url;
                            })
                            resolve(picInfo);
                        } else {
                            resolve(picInfo)
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        resolve(false);
                    });
                })
                .catch((error) => {
                    console.log(error);
                    axios.post('/api/guest/work/pic/after', options.params, options.headers)
                    .then((res) => {
                        console.log("getPictList",res.data);
                        if(res.data.errorCode == 0){
                            picInfo['after'] = [res.data.result.pic_url]
                            resolve(picInfo);
                        } else {
                            resolve(picInfo)
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        resolve(false);
                    });
                });
            })
            
        })();
    });
};

// 官方API 最新消息 & 活動快訊
export function articleList() {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                },
                params: {},
            };
            console.log(options);
            // return 1;
            var category_list = ['news.lates', 'news.promotion'];
            var article_list = [];
            axios.get('/api/mobile/pet/articles/' + category_list[0], options)
                .then((res) => {
                    if (res.data.errorCode == 0) {
                        console.log(res.data.result.data);
                        for(let index in res.data.result.data){
                            var obj = res.data.result.data[index]
                        // res.data.result.data.map(function(obj,index){
                            obj.category_id = category_list[0];
                            obj.sort_id = index;
                            article_list.push(obj);
                            if(index == res.data.result.data.length - 1){
                                axios.get('/api/mobile/pet/articles/' + category_list[1], options)
                                .then((res) => {
                                    if (res.data.errorCode == 0) {
                                        console.log(res.data.result.data);
                                        for(let index in res.data.result.data){
                                            var obj = res.data.result.data[index]
                                            obj.category_id = category_list[1];
                                            obj.sort_id = index;
                                            article_list.push(obj);
                                            if(index == res.data.result.data.length - 1){
                                                resolve(article_list);
                                            }
                                        }
                                        if(res.data.result.data.length == 0){
                                            resolve(article_list);
                                        }
                                    } else {
                                        console.log('error', res.data);
                                        resolve(false)
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                    resolve(false);
                                });
                            }
                            // return obj
                        }
                        if(res.data.result.data.length == 0){
                            axios.get('/api/mobile/pet/articles/' + category_list[1], options)
                            .then((res) => {
                                if (res.data.errorCode == 0) {
                                    console.log(res.data.result.data);
                                    for(let index in res.data.result.data){
                                        var obj = res.data.result.data[index]
                                        obj.category_id = category_list[1];
                                        obj.sort_id = index;
                                        article_list.push(obj);
                                        if(index == res.data.result.data.length - 1){
                                            resolve(article_list);
                                        }
                                    }
                                    if(res.data.result.data.length == 0){
                                        resolve(article_list);
                                    }
                                } else {
                                    console.log('error', res.data);
                                    resolve(false)
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                                resolve(false);
                            });
                        }
                    } else {
                        console.log('error', res.data);
                        // article_list = article_list.concat([]);
                        resolve(false)
                    }
                })
                .catch((error) => {
                    console.log(error);
                    resolve(false);
                });
            // for(let i in category_list){
            //     var count = i;
            //     axios.get('/api/mobile/pet/articles/' + category_list[i], options)
            //     .then((res) => {
            //         if (res.data.errorCode == 0) {
            //             console.log(res.data.result.data);
            //             for(let index in res.data.result.data){
            //                 var obj = res.data.result.data[index]
            //             // res.data.result.data.map(function(obj,index){
            //                 obj.category_id = category_list[i];
            //                 obj.sort_id = index;
            //                 article_list.push(obj);
            //                 if(index == res.data.result.data.length - 1 && count == category_list.length - 1){
            //                     resolve(article_list);
            //                 }
            //                 // return obj
            //             }
            //         } else {
            //             console.log('error', res.data);
            //             // article_list = article_list.concat([]);
            //             if(count == category_list.length - 1) resolve(article_list);
            //         }
            //     })
            //     .catch((error) => {
            //         console.log(error);
            //         resolve(false);
            //     });
            // };
        })();
    });
}

// 官方API 文章內容頁數
export function getArticlePage(category_id,page) {
    return new Promise(function (resolve) {
        (async () => {
            let options = {};
            options = {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                },
                params: {},
            };
            console.log(options);

            axios.get('/api/mobile/pet/articles/pages/' + category_id + '?page=' + page, options)
            .then((res) => {
                if (res.data.errorCode == 0) {
                    console.log(res.data.result);
                    resolve(res.data.result);
                } else {
                    console.log('error', res.data);
                    resolve(false);
                }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            });

        })();
    });
}

// 官方API 文章內容
export function getArticle(category_id,article_id) {
  return new Promise(function (resolve) {
      (async () => {
        let options = {};
            options = {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                },
                params: {},
            };
            axios.get('/api/mobile/pet/articles/' + category_id + '/' + article_id, options)
                .then((res) => {
                    if (res.data.errorCode == 0) {
                        console.log(res.data.result);
                        resolve(res.data.result)
                    } else {
                        console.log('error', res.data);
                        resolve(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    resolve(false);
                });
      })()
  })
}


// 官方API 門市據點
export function getStore() {
  return new Promise(function (resolve) {
      (async () => {
        let options = {};
            options = {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                },
                params: {},
            };
        axios.get('/api/mobile/pet/stores', options)
                .then((res) => {
                    if (res.data.errorCode == 0) {
                        console.log(res.data.result);
                        resolve(res.data.result.data)
                    } else {
                        console.log('error', res.data);
                        resolve(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    resolve(false);
                });
      })()
  })
}

// 官方API DM
export function getDmList() {
    return new Promise(function (resolve) {
        (async () => {
          let options = {};
              options = {
                  headers: {
                      'Content-Type': 'application/json; charset=utf-8',
                  },
                  params: {},
              };
              axios.get('/service/pet/dm', options)
                  .then((res) => {
                      if (res.data.errorCode == 0) {
                          console.log(res.data.result);
                          resolve(res.data.result)
                      } else {
                          console.log('error', res.data);
                          resolve(false);
                      }
                  })
                  .catch((error) => {
                      console.log(error);
                      resolve(false);
                  });
        })()
    })
  }
  

// 簡訊
export function sendSms(phone,body){
  return new Promise(function (resolve) {
    (async () => {
      // let options = {};
      //     options = {
      //         headers: {
      //             'Content-Type': 'application/json; charset=utf-8',
      //         },
      //         params: {
      //           username:"sixdotsit",
      //           password:"Gt@12803476",
      //           dstaddr:phone,
      //           smbody:"測試test123"
      //         },
      //     };
      axios.post('/b2c/mtk/SmSend?username=sixdotsit&password=Gt@12803476&dstaddr=' + phone + "&CharsetURL=UTF-8&smbody=" + encodeURI(body))
              .then((res) => {
                  if (res.data.statuscode == 1) {
                      console.log(res.data);
                      resolve(true)
                  } else {
                      console.log('error', res.data);
                      resolve(false);
                  }
              })
              .catch((error) => {
                  console.log(error);
                  resolve(false);
              });
    })()
})
}

// Utils
function getDatetime() {
    var newDateObj = new Date();
    return (
        newDateObj.getFullYear().toString() +
        ((newDateObj.getMonth() + 1 < 10
            ? '0' + (newDateObj.getMonth() + 1).toString()
            : newDateObj.getMonth() + 1).toString()) +
        ((newDateObj.getDate() < 10
            ? '0' + newDateObj.getDate().toString()
            : newDateObj.getDate().toString())) +
        ((newDateObj.getHours() < 10
            ? '0' + newDateObj.getHours().toString()
            : newDateObj.getHours().toString())) +
        ((newDateObj.getMinutes() < 10
            ? '0' + newDateObj.getMinutes().toString()
            : newDateObj.getMinutes().toString())) +
        ((newDateObj.getSeconds() < 10
            ? '0' + newDateObj.getSeconds().toString()
            : newDateObj.getSeconds().toString()))
    );
}

// getFunctionSign('KGMBS_RESET_PASSWORD', '20190701123000', '0912345678')

function getFunctionSign(func_id, time_str, phone) {
    var temp_str =
        'FUNC_ID=' +
        func_id +
        '&SYS_DATE=' +
        time_str +
        '&ACNT_NO=' +
        phone +
        '&API_KEY=' +
        api_key;
    console.log(temp_str,sha1(temp_str))
    return sha1(temp_str);
}

export async function shareImage(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    const filesArray = [
      new File(
        [blob],
        'meme.jpg',
        {
          type: "image/jpeg",
          lastModified: new Date().getTime()
        }
     )
    ];
    const shareData = {
      files: filesArray,
    };
    navigator.share(shareData);
  }

//   批量啟用
// let phone_list = "0976104315,0936326985,0922715339,0915585846,0933530747,0963596726,0989991378,0987941368,0911661713,0972832089,0900195300"
// phone_list = phone_list.split(",")
// for(let i in phone_list){
//     KgResetPassword(phone_list[i]).then(function(rsp){
//         if(rsp){
//             var ori_password = rsp.password;
//             let member_no = rsp.member_no
//             // debug
//             // ori_password = "rrrr1234";
//             KgUpdatePassword(phone_list[i],member_no,ori_password,'rrrr1234').then(function(rsp){
//                 if(rsp){
//                     KgQueryMemberAll(phone_list[i],'rrrr1234').then(function(rsp){
//                         if(rsp.toString() != "true" &&  rsp.toString() != "false" && rsp.toString() != ""){
//                             register(phone_list[i],rsp.MEB_Name.split("-")[0],'rrrr1234','male','20230101','123',member_no,"").then(function(rsp){
//                                 console.log(phone_list[i],"register",rsp)
//                             })
//                         } else {
//                             console.log(phone_list[i],'KgQueryMemberAll 無此會員')
                            
//                         }
//                     })
//                 } else {
//                     console.log(phone_list[i],"KgUpdatePassword error")
//                 }
//             })
//         } else {
//             console.log(phone_list[i],"KgResetPassword error")
//         }
//     })
// }
