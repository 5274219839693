<template>
    <div
        :class="{ p_item: true, d_flex: true, verticle: verticle }"
        v-bind="{ ...$attrs }"
    >
        <div class="img_wrapper">
            <img
                src="@/assets/images/imgTrans.png"
                alt=""
                :style="{ 'background-image': `url(${data.imgSrc})` }"
            />
        </div>
        <div class="detail_wrapper d_flex">
            <h5>{{ data.title }}</h5>
            <h6>{{ data.type }}</h6>
            <span class="price">{{ data.price }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductItem',
    props: {
        data: {
            type: Object,
        },
        verticle: {
            type: Boolean,
        },
    },
    methods: {
        handleSave() {
            this.$emit('click:save');
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.p_item {
    justify-content: space-evenly;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    background-color: #fff;
    margin-bottom: 8px;
    padding: 12px 8px;
    .img_wrapper {
        img {
            width: 84px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
    }
    .detail_wrapper {
        width: calc(100% - 110px);
        text-align: left;
        flex-direction: column;
        justify-content: space-between;
        padding: 8px 0;

        .price {
            color: $color_main_second;
            &:before {
                content: '$';
            }
        }
    }

    * {
        font-size: 14px;
        line-height: 1.4;
    }

    &.verticle {
        flex-direction: column;
        align-items: center;
        .detail_wrapper {
            width: calc(100% - 24px);
        }
        h5,
        h6,
        span {
            margin-bottom: 6px;
        }
        h5 {
            display: -webkit-box;
            height: 40px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        h6 {
            color: #fff;
            background-image: url(~@/assets/images/icTag.svg);
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 40px;
            font-size: 12px;
            padding-left: 4px;
        }
    }
}
</style>
