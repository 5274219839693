<template>
    <v-main class="login-main">
        <PopupAlert v-model="showAlert" :errorMsg="errorMsg" :imgSrc="imgSrc" />
        <v-container class="login-container">
            <div class="login-logo">
                <img :src="require(`@/assets/images/logoL.svg`)" alt="" />
            </div>
            <div class="login-box">
                <p class="login-box--title">登入</p>
                <v-form ref="form" class="mt-4" v-model="valid" lazy-validation>
                    <v-row class="mt-0">
                        <v-col cols="12" sm="12" class="py-0 mb-4">
                            <FancyInput
                                v-model="form.account"
                                label="電話"
                                iconName="ic24Phone.svg"
                                :rules="[
                                    () =>
                                        accountMsg ||
                                        !!form.account ||
                                        '請輸入電話',
                                ]"
                                required
                        /></v-col>
                        <v-col cols="12" sm="12" class="py-0 mb-4">
                            <FancyInput
                                v-model="form.password"
                                label="密碼"
                                hint="6-12位英文及數字混合的密碼"
                                persistent-hint
                                type="password"
                                iconName="ic24Lock.svg"
                                :rules="[
                                    () =>
                                        passwordMsg ||
                                        !!form.password ||
                                        '請輸入密碼',
                                ]"
                                required
                            />
                        </v-col>
                    </v-row>
                </v-form>
                <div class="login-box--forgot">
                    <a
                        href=""
                        class=""
                        @click.prevent="$router.push('/forgot-password')"
                    >
                        忘記密碼?
                    </a>
                </div>
            </div>
            <div class="login-footer d-flex justify-center">
                <FancyButton
                    isGreen
                    class="btn mr-5"
                    width="150"
                    height="48"
                    label="註冊"
                    @click.prevent="$router.push('/register')"
                />
                <FancyButton
                    class="btn"
                    width="150"
                    height="48"
                    label="開始"
                    @click.prevent="goNext"
                />
            </div>
        </v-container>
    </v-main>
</template>

<script>
import FancyInput from '@/components/FancyInput.vue';
import FancyButton from '@/components/FancyButton.vue';
import PopupAlert from '@/components/Popup/PopupAlert.vue';
import { login, KgQueryMember, checkDeviceIdMatch } from '@/apis/api.js';
export default {
    name: 'LoginIndex',
    components: { FancyInput, FancyButton, PopupAlert },
    created() {
        console.log(
            localStorage.getItem('phone'),
            localStorage.getItem('password'),
            localStorage.getItem('member_no')
        );
        if (
            localStorage.getItem('phone') &&
            localStorage.getItem('password') &&
            localStorage.getItem('member_no')
        )
            this.$router.push('/');
    },
    data: () => ({
        accountMsg: '',
        passwordMsg: '',
        valid: '',
        showAlert: false,
        form: {
            account: '',
            password: '',
        },
        errorMsg: '帳號或密碼錯誤',
        imgSrc: '',
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.state.isLoading = false;
        });
    },
    methods: {
        goNext() {
            this.accountMsg = '';
            this.passwordMsg = '';
            const valid = this.$refs.form.validate();
            var parent = this;
            let test_account_list = ['0911911711','0963915586','0927199567','0970426816','0989187330','0931079960','0910572600','0976808519','0958184517','0956080286']
            if (valid) {
                // login
                login(parent.form.account, parent.form.password).then(function (
                    rsp
                ) {
                    if (rsp) {
                        // Check device Id
                        var device_id = localStorage.getItem('device_id');
                        if (!device_id) device_id = '';
                        checkDeviceIdMatch(
                            parent.form.account,
                            parent.form.password,
                            device_id
                        ).then(function (rsp) {
                            if (
                                rsp || test_account_list.indexOf(parent.form.account) != -1
                            ) {
                                // if(rsp || parent.form.account == "0927199567" || parent.form.account == "0968965101" || parent.form.account == "0956080286" || parent.form.account == "0912377640" || parent.form.account == "0989187330"){
                                KgQueryMember(
                                    parent.form.account,
                                    parent.form.password
                                ).then(function (rsp) {
                                    if (
                                        rsp.toString() != 'true' &&
                                        rsp.toString() != 'false' &&
                                        rsp.toString() != ''
                                    ) {
                                        localStorage.setItem('member_no', rsp);
                                        localStorage.setItem(
                                            'phone',
                                            parent.form.account
                                        );
                                        localStorage.setItem(
                                            'password',
                                            parent.form.password
                                        );
                                        parent.$router.push({
                                            path: '/',
                                            query: parent.$route.query,
                                        });
                                    } else {
                                        parent.errorMsg = '帳號或密碼錯誤';
                                        parent.imgSrc =
                                            '@/assets/images/imgWarning.svg';
                                        parent.showAlert = true;
                                    }
                                });
                            } else {
                                // parent.errorMsg = "需進行換機作業";
                                // parent.showAlert = true;
                                // 導入換機簡訊
                                parent.$router.push(
                                    '/register/otp?type=change&phone=' +
                                        parent.form.account +
                                        '&password=' +
                                        parent.form.password
                                );
                            }
                        });
                    } else {
                        parent.accountMsg = '帳號應為電話號碼';
                        parent.passwordMsg = '密碼應為密碼應為6-12位英數字混合';
                        parent.errorMsg = '帳號或密碼錯誤';
                        parent.imgSrc = '@/assets/images/imgWarning.svg';
                        parent.showAlert = true;
                        parent.$refs.form.validate();
                    }
                });
            }
            // if (!valid) {
            //     this.showAlert = true;
            // } else {
            //     this.$router.push('/member');
            // }
        },
        // login() {

        // }
    },
};
</script>

<style lang="scss" scoped>
.login-main {
    background-image: url('~@/assets/images/bg_login.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    .login-container {
        > .login-logo {
            margin-top: 50px;
            img {
                height: 117px;
                width: 100px;
            }
        }
    }
    .login-box {
        margin: 0 16px 30px 16px;
        padding: 36px 24px 20px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 25px;
        &--title {
            font-size: 20px;
            line-height: 24px;
            color: #ea5524;
        }
        &--forgot {
            text-align: right;
            > a {
                font-size: 12px;
                line-height: 14px;
                text-decoration-line: underline;
                color: $color_main_first;
            }
        }
    }

    .login-footer {
    }
}
</style>
