<template>
    <FancyButton
        class="btn"
        :label="label"
        width="150"
        height="48"
        v-bind="{ ...$attrs }"
        v-on="{
            $listeners,
            click: () => {
                handleClick();
            },
        }"
    />
</template>

<script>
import FancyButton from '@/components/FancyButton.vue';

export default {
    name: 'SalonBottomButton',
    components: {
        FancyButton,
    },
    props: {
        label: {
            type: String,
            default: '',
        },
    },
    emits: ['click'],
    methods: {
        handleClick() {
            this.$emit('click');
        },
    },
};
</script>

<style lang="scss" scoped>
.btn {
    // position: fixed;
    // left: 50%;
    // transform: translateX(-50%);
    // bottom: 52px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 35px;
    display: block;
}
</style>
