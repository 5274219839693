import { render, staticRenderFns } from "./ServiceSelection.vue?vue&type=template&id=4145c227&scoped=true&"
import script from "./ServiceSelection.vue?vue&type=script&lang=js&"
export * from "./ServiceSelection.vue?vue&type=script&lang=js&"
import style0 from "./ServiceSelection.vue?vue&type=style&index=0&id=4145c227&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4145c227",
  null
  
)

export default component.exports