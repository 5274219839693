<template>
    <FancyDialog v-bind="{ ...$attrs }" v-on="$listeners">
        <v-container fluid class="dialog_container">
            <div style="text-align: left;margin-bottom: 20px;">
                <div class="popup-title" style="margin-bottom: 10px;">已獲得優惠券</div>
                <div class="popup-desc">填寫完成，恭喜您獲得優惠券{{ giftQuantity }}張。</div>
            </div>
            
            <div class="footer d-flex justify-center" style="margin-bottom: 8px;">
                <button
                    class="style-btn style-m-btn style-dark-btn"
                    style="margin: 8px;"
                    @click="$emit('click:close')"
                >
                    關閉
                </button>
                <button
                    class="style-btn style-m-btn style-light-btn"
                    @click="$emit('click:confirm')"
                    style="margin: 8px;"
                >
                查看
                </button>
            </div>
        </v-container>
    </FancyDialog>
</template>

<script>
import FancyDialog from '@/components/FancyDialog.vue';
export default {
    components: {
        FancyDialog,
    },
    name: 'PopupRegisterGift',
    props: {
        serviceList: {
            type: Array,
        },
        giftQuantity: {
            type: Number,
            default: 1,
        },
    },
    emits: ['input', 'click:confirm'],
    data: () => ({}),
};
</script>

<style lang="scss" scoped>
div.dialog_container {
    p.title {
        color: $color_main_first;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        letter-spacing: -0.3px;
    }
    p.desc {
        font-size: 14px;
        line-height: 16px;
        color: $color_main_black;
    }
    > .footer {
        .btn {
            height: 50px;
            width: 100%;
            @media screen and (max-width: 374px) {
                max-width: 118px;
            }
            @media screen and (min-width: 375px) {
                max-width: 145.5px;
            }
        }
    }
}
</style>
