<template>
    <v-dialog persistent class="fancy" v-bind="{ ...$attrs }" v-on="$listeners">
        <div class="dialog_box">
            <slot />
        </div>
    </v-dialog>
</template>

<script>
export default {
    name: 'FancyDialog',
    inheritAttrs: false,
    props: {},
    methods: {
        handleDialog(value) {
            this.$emit('input', value);
        },
    },
};
</script>
<style lang="scss">
div.v-dialog {
    box-shadow: none;
    .dialog_box {
        background: #ffffff;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 32px 16px 8px 16px;
    }
}
</style>
