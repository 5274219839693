<template>
    <v-main>
        <BackOnTop :title="title" />
        <v-container
            fluid
            class="salon-container pt-4 px-8"
            style="height: calc(100vh - 50px); justify-content: center"
        >
            <div style="margin-bottom: -14px">
                <div
                    v-if="img"
                    class="style-confirm-img"
                    :style="{
                        border: '3px solid #FFDAAD',
                        margin: '0 auto',
                        'background-image': `url(${img})`,
                        width: '120px',
                        height: '120px',
                        'border-radius': '100%',
                        'background-size': 'cover',
                        'background-position': 'center',
                    }"
                ></div>
                <div class="style-confirm-bone">{{ pet_name }}</div>
            </div>
            <div
                class="style-title-l"
                style="color: #ff9f4b; margin-bottom: 18px"
            >
                {{ services.join('、') }}服務
            </div>
            <div
                class="style-title-l"
                style="
                    font-size: 20px;
                    letter-spacing: 0.8px;
                    margin-bottom: 14px;
                "
            >
                {{ query.date.replace(/-/g, '/') }}
            </div>
            <div
                class="style-title-l"
                style="
                    font-size: 20px;
                    letter-spacing: 0.8px;
                    margin-bottom: 18px;
                "
            >
                {{ query.time + ':00' }}
            </div>
            <div
                class="style-body-regular"
                style="font-size: 16px; margin-bottom: 140px"
            >
                預約門市 {{ store }}
            </div>
            <!-- <SalonInfoBox :infoList="infos" />
            <h2>預約服務項目</h2>
            <SalonServiceBox class="s-box" :serviceList="services" /> -->
            <div
                style="
                    position: absolute;
                    bottom: 84px;
                    width: calc(100vw - 64px);
                "
            >
                <FancyCheckContract
                    v-model="isAgreeMemberContract"
                    contractLink="/member/contract"
                    hint="為了確保您的權益，請務必詳閱服務條款"
                    persistent-hint
                />
                <button
                    v-if="isAgreeMemberContract"
                    :class="`style-btn style-l-btn style-light-btn`"
                    @click="goNext()"
                    style="border-radius: 18px; margin: 0 auto; border: none"
                >
                    確定<img
                        src="../../assets/images/white-foot.png"
                        style="width: 24px; height: 24px"
                    />
                </button>

                <button
                    v-else
                    :class="`style-btn style-l-btn style-disable-btn`"
                    style="border-radius: 18px; margin: 0 auto; border: none"
                >
                    確定<img
                        src="../../assets/images/black-foot.png"
                        style="width: 24px; height: 24px"
                    />
                </button>
            </div>
        </v-container>
        <PopupOrderSuccess
            v-model="showOrderSuccess"
            @click:confirm="
                switchOrderSuccess(false);
                $router.push('/salon/history');
            "
        />
        <PopupOrderFail
            v-model="showOrderFail"
            :phone="phone"
            @click:confirm="switchOrderFail(false)"
        />
        <PopupOrderUnable
            v-model="showOrderUnable"
            :phone="phone"
            @click:confirm="switchOrderUnable(false)"
        />
        <PopupOrderDuplicate
            v-model="showOrderDuplicate"
            :data="old_data"
            :services="services"
            :infos="infos"
            @click:confirm="cancelOldReservation()"
            @click:cancel="$router.push('/salon/history')"
        />
    </v-main>
</template>

<script>
import BackOnTop from '@/components/NewStyle/BackOnTop.vue';
import FancyCheckContract from '@/components/NewStyle/FancyCheckContract.vue';
import PopupOrderSuccess from '@/components/NewPopup/PopupOrderSuccess.vue';
import PopupOrderFail from '@/components/NewPopup/PopupOrderFail.vue';
import PopupOrderUnable from '@/components/NewPopup/PopupOrderUnable.vue';
import PopupOrderDuplicate from '@/components/NewPopup/PopupOrderDuplicate.vue';

import {
    getUserInfo,
    addReserve,
    KgGetStore,
    getPetImage,
    updateReserve,
    KgGetReserveList,
    cancelReserve,
    KgGetReserve,
} from '@/apis/api.js';

export default {
    name: 'SalonOrderConfirm',
    components: {
        BackOnTop,
        FancyCheckContract,
        PopupOrderSuccess,
        PopupOrderFail,
        PopupOrderUnable,
        PopupOrderDuplicate,
    },
    data: () => ({
        title: '美容預約',
        hasDuplicateReserve: false,
        showOrderDuplicate: false,
        showOrderSuccess: false,
        isAgreeMemberContract: false,
        showOrderFail: false,
        showOrderUnable: false,
        infos: [
            { key: '會員姓名', value: 'Scott' },
            { key: '聯絡電話', value: '0912344543' },
            { key: '寶貝姓名', value: '大白' },
            { key: '預約門市', value: '八德興豐店' },
            { key: '預約日期', value: '2021/06/18   14:00 ' },
        ],
        services: ['剪毛', '剪指甲', '剪毛', '(加購)spa護膚'],
        query: {},
        phone: '',
        serviceValueDict: {
            洗澡: {
                app: 0,
                kg: [1],
            },
            // '草本SPA':{
            //     app:7,
            //     kg:[2]
            // },
            剪毛: {
                app: 1,
                kg: [3],
            },
            游泳: {
                app: 2,
                kg: [4],
            },
            '洗澡+SPA': {
                app: 3,
                kg: [1, 2],
            },
            '洗澡+游泳': { app: 4, kg: [1, 4] },
            '剪毛+SPA': { app: 5, kg: [2, 3] },
            '剪毛+游泳': { app: 6, kg: [3, 4] },
        },
        img: '',
        pet_name: '',
        store: '',
        old_data: {},
        enable_edit: false,
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.state.isLoading = false;
        });
    },
    created() {
        var parent = this;
        if (this.$route.query.reserve_no) {
            this.title = '修改預約';
        }
        this.query = this.$route.query;
        this.services = decodeURIComponent(this.query.type).split('+');
        let pet_no = decodeURIComponent(this.query.baby).split(' ')[0];
        this.pet_name = decodeURIComponent(this.query.baby).split(' ')[1];
        this.store = decodeURIComponent(this.query.store).split(' ')[1];
        this.infos = [
            { key: '會員姓名', value: '' },
            { key: '聯絡電話', value: this.query.phone },
            { key: '寶貝姓名', value: this.pet_name },
            { key: '預約門市', value: this.store },
            {
                key: '預約日期',
                value: this.query.date + '\t' + this.query.time + ':00',
            },
        ];
        console.log(
            localStorage.getItem('phone'),
            localStorage.getItem('password')
        );

        let pet_type = decodeURIComponent(this.query.baby).split(' ')[2];
        getPetImage(
            localStorage.getItem('phone'),
            localStorage.getItem('password'),
            pet_no
        )
            .then(function (image) {
                parent.img = image
                    ? image
                    : require(`@/assets/images/imgCard${pet_type}.jpg`);
            })
            .catch(function (err) {
                console.log(err);
                parent.img = require(`@/assets/images/imgCard${pet_type}.jpg`);
            });
        getUserInfo(
            localStorage.getItem('phone'),
            localStorage.getItem('password')
        ).then(function (rsp) {
            if (rsp) {
                parent.infos[0].value = rsp.c_name;
            }
        });

        let store_dict = {};
        KgGetStore(localStorage.getItem('phone')).then(function (rsp) {
            if (rsp) {
                console.log(rsp);
                for (let i in rsp) {
                    store_dict[rsp[i].STO_No] = rsp[i].STO_Name;
                    if (
                        rsp[i].STO_No ==
                        decodeURIComponent(parent.query.store).split(' ')[0]
                    ) {
                        parent.phone = `+886${rsp[i].STO_Tel.replace('(', '')
                            .replace(')', '')
                            .replace('-', '')
                            .replace('0', '')}`;
                    }
                }
            }
        });

        var start_date, end_date;

        // 起始日 結束日為前後各60天
        var numberOfMlSeconds = new Date().getTime();
        var newDateObj = new Date(numberOfMlSeconds - 1000 * 60 * 60 * 24 * 60);
        start_date =
            newDateObj.getFullYear().toString() +
            (newDateObj.getMonth() + 1 < 10
                ? '0' + (newDateObj.getMonth() + 1).toString()
                : (newDateObj.getMonth() + 1).toString()) +
            (newDateObj.getDate() < 10
                ? ('0' + newDateObj.getDate()).toString()
                : newDateObj.getDate().toString());
        newDateObj = new Date(numberOfMlSeconds + 1000 * 60 * 60 * 24 * 60);
        end_date =
            newDateObj.getFullYear().toString() +
            (newDateObj.getMonth() + 1 < 10
                ? '0' + (newDateObj.getMonth() + 1).toString()
                : (newDateObj.getMonth() + 1).toString()) +
            (newDateObj.getDate() < 10
                ? '0' + newDateObj.getDate().toString()
                : newDateObj.getDate().toString());
        KgGetReserveList(
            localStorage.getItem('phone'),
            localStorage.getItem('member_no'),
            '',
            start_date,
            end_date
        ).then(function (rsp) {
            console.log('KgGetReserveList', rsp);

            rsp = rsp.filter(function (obj) {
                console.log(
                    obj.PBS_ApptNo,
                    parent.query.date.replace(/-/g, '').slice(2, 8)
                );
                return (
                    obj.PBS_ApptStatus != 4 &&
                    obj.PBS_ApptNo.match(
                        parent.query.date.replace(/-/g, '').slice(2, 8)
                    ) != null &&
                    obj.PET_SeqNo == pet_no
                );
            });
            console.log('KgGetReserveList', rsp);
            for (let i in rsp) {
                KgGetReserve(
                    localStorage.getItem('phone'),
                    localStorage.getItem('member_no'),
                    rsp[i].STO_No,
                    rsp[i].PBS_ApptNo
                ).then(function (inner_rsp) {
                    var hour =
                        inner_rsp.PBS_ApptBegTime.slice(0, 2) +
                        ':' +
                        inner_rsp.PBS_ApptBegTime.slice(2, 4);
                    var is_wash = inner_rsp.PBS_Svr01 == 'Y' ? true : false;
                    var is_spa = inner_rsp.PBS_Svr02 == 'Y' ? true : false;
                    var is_cut = inner_rsp.PBS_Svr03 == 'Y' ? true : false;
                    var is_swim = inner_rsp.PBS_Svr04 == 'Y' ? true : false;
                    // var is_basic = inner_rsp.PBS_Svr05 == 'Y' ? true : false;
                    parent.old_data = {
                        img: parent.img,
                        PBS_ApptNo: inner_rsp.PBS_ApptNo,
                        pet_no: parent.pet_no,
                        pet_name: parent.pet_name,
                        services: [],
                        time: parent.query.date.replace(/-/g, '/') + ' ' + hour,
                        store_no: inner_rsp.STO_No,
                        store_name: store_dict[inner_rsp.STO_No],
                    };

                    parent.old_data.services = [];
                    if (is_wash && is_spa) {
                        parent.old_data.services.push('洗澡');
                        parent.old_data.services.push('SPA');
                        parent.old_data.e_resv_item =
                            parent.serviceValueDict['洗澡+SPA'].app;
                    } else if (is_wash && is_swim) {
                        parent.old_data.services.push('洗澡');
                        parent.old_data.services.push('游泳');
                        parent.old_data.e_resv_item =
                            parent.serviceValueDict['洗澡+游泳'].app;
                    } else if (is_cut && is_spa) {
                        parent.old_data.services.push('剪毛');
                        parent.old_data.services.push('SPA');
                        parent.old_data.e_resv_item =
                            parent.serviceValueDict['剪毛+SPA'].app;
                    } else if (is_cut && is_swim) {
                        parent.old_data.services.push('剪毛');
                        parent.old_data.services.push('游泳');
                        parent.old_data.e_resv_item =
                            parent.serviceValueDict['剪毛+游泳'].app;
                    } else if (is_wash) {
                        parent.old_data.services.push('洗澡');
                        parent.old_data.e_resv_item =
                            parent.serviceValueDict['洗澡'].app;
                    } else if (is_cut) {
                        parent.old_data.services.push('剪毛');
                        parent.old_data.e_resv_item =
                            parent.serviceValueDict['剪毛'].app;
                    } else if (is_swim) {
                        parent.old_data.services.push('游泳');
                        parent.old_data.e_resv_item =
                            parent.serviceValueDict['游泳'].app;
                    }

                    // if (is_basic) {
                    //     parent.old_data.services.push('基礎美容');
                    // }
                    console.log('old_data', parent.old_data);
                    parent.hasDuplicateReserve = true;
                });
            }
        });
    },
    methods: {
        cancelOldReservation() {
            let parent = this;
            if (this.$route.query.reserve_no && !this.enable_edit) {
                parent.$store.state.isLoading = false;
                parent.showOrderDuplicate = false;
                parent.showOrderUnable = true;
            } else {
                cancelReserve(
                    localStorage.getItem('phone'),
                    localStorage.getItem('password'),
                    localStorage.getItem('member_no'),
                    this.old_data.time.split(' ')[0].replace(/\//g, ''),
                    this.old_data.store_no,
                    this.old_data.time.split(' ')[1].replace(/:/g, ''),
                    this.old_data.e_resv_item,
                    this.old_data.PBS_ApptNo,
                    '其他 改約其他時間'
                ).then(function (rsp) {
                    console.log('cancelReserve', rsp);
                    if (rsp) {
                        parent.showOrderDuplicate = false;
                        parent.goReserve();
                        parent.$store.state.isLoading = false;
                    } else {
                        parent.$store.state.isLoading = false;
                        parent.showOrderDuplicate = false;
                        parent.showOrderUnable = true;
                    }
                });
            }
        },
        goNext() {
            if (this.hasDuplicateReserve) {
                this.showOrderDuplicate = true;
            } else {
                this.goReserve();
            }
        },
        goReserve() {
            // var valid = this.$refs.form.validate();
            var parent = this;
            // 幾天前可修改預約
            var num = 0;
            var numberOfMlSeconds = new Date().getTime();
            var newDateObj = new Date(
                numberOfMlSeconds + 1000 * 60 * 60 * 24 * num
            );
            var start_date = `${newDateObj.getFullYear()}${
                newDateObj.getMonth() + 1 < 10 ? '0' : ''
            }${newDateObj.getMonth() + 1}${
                newDateObj.getDate() < 10 ? '0' : ''
            }${newDateObj.getDate()}`;
            console.log('start_date', start_date);
            if (this.isAgreeMemberContract && !this.$store.state.isLoading) {
                if (this.$route.query.reserve_no) {
                    // 修改預約流程
                    if (
                        start_date <
                            this.query.ori_c_resv_date.replace(/-/g, '') &&
                        this.enable_edit
                    ) {
                        updateReserve(
                            localStorage.getItem('phone'),
                            localStorage.getItem('password'),
                            localStorage.getItem('member_no'),
                            this.infos[0].value,
                            decodeURIComponent(this.query.baby).split(' ')[0],
                            decodeURIComponent(this.query.baby).split(' ')[1],
                            this.query.date.replace(/-/g, ''),
                            decodeURIComponent(this.query.store).split(' ')[0],
                            this.query.time,
                            this.serviceValueDict[
                                decodeURIComponent(this.query.type)
                            ].app,
                            this.query.reserve_no,
                            this.query.ori_store_no,
                            this.query.PBS_Svr05
                        ).then(function (rsp) {
                            parent.$store.state.isLoading = false;
                            if (rsp == 0) {
                                parent.switchOrderSuccess(true);
                            } else if (rsp == 1) {
                                parent.switchOrderFail(true);
                            } else {
                                parent.switchOrderUnable(true);
                            }
                        });
                    } else {
                        parent.$store.state.isLoading = false;
                        parent.switchOrderUnable(true);
                    }
                } else {
                    // 新增預約流程
                    this.$store.state.isLoading = true;
                    addReserve(
                        localStorage.getItem('phone'),
                        localStorage.getItem('password'),
                        localStorage.getItem('member_no'),
                        this.infos[0].value,
                        decodeURIComponent(this.query.baby).split(' ')[0],
                        decodeURIComponent(this.query.baby).split(' ')[1],
                        this.query.date.replace(/-/g, ''),
                        decodeURIComponent(this.query.store).split(' ')[0],
                        this.query.time,
                        this.serviceValueDict[
                            decodeURIComponent(this.query.type)
                        ].app
                    ).then(function (rsp) {
                        parent.$store.state.isLoading = false;
                        if (rsp == 0) {
                            parent.switchOrderSuccess(true);
                        } else if (rsp == 1) {
                            parent.switchOrderUnable(true);
                        } else {
                            parent.switchOrderFail(true);
                        }
                    });
                }
            }
        },
        switchOrderFail(isShow) {
            this.showOrderFail = isShow;
        },
        switchOrderSuccess(isShow) {
            this.showOrderSuccess = isShow;
        },
        switchOrderUnable(isShow) {
            this.showOrderUnable = isShow;
        },
    },
};
</script>

<style lang="scss" scoped>
.salon-container {
    background-image: url('~@/assets/images/logo_background.png');
    background-size: 75%;
    background-position-x: right;
    background-position-y: 35vh;
    h2 {
        margin: 28px 0 23px 0;
        color: $color_main_first;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
    }
    .s-box {
        margin: 0 0 35px 0;
    }
}
</style>
