var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',{staticClass:"login-main"},[_c('PopupAlert',{attrs:{"errorMsg":_vm.errorMsg,"imgSrc":_vm.imgSrc},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}}),_c('v-container',{staticClass:"login-container"},[_c('div',{staticClass:"login-logo"},[_c('img',{attrs:{"src":require(`@/assets/images/logoL.svg`),"alt":""}})]),_c('div',{staticClass:"login-box"},[_c('p',{staticClass:"login-box--title"},[_vm._v("登入")]),_c('v-form',{ref:"form",staticClass:"mt-4",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancyInput',{attrs:{"label":"電話","iconName":"ic24Phone.svg","rules":[
                                () =>
                                    _vm.accountMsg ||
                                    !!_vm.form.account ||
                                    '請輸入電話',
                            ],"required":""},model:{value:(_vm.form.account),callback:function ($$v) {_vm.$set(_vm.form, "account", $$v)},expression:"form.account"}})],1),_c('v-col',{staticClass:"py-0 mb-4",attrs:{"cols":"12","sm":"12"}},[_c('FancyInput',{attrs:{"label":"密碼","hint":"6-12位英文及數字混合的密碼","persistent-hint":"","type":"password","iconName":"ic24Lock.svg","rules":[
                                () =>
                                    _vm.passwordMsg ||
                                    !!_vm.form.password ||
                                    '請輸入密碼',
                            ],"required":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)],1)],1),_c('div',{staticClass:"login-box--forgot"},[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/forgot-password')}}},[_vm._v(" 忘記密碼? ")])])],1),_c('div',{staticClass:"login-footer d-flex justify-center"},[_c('FancyButton',{staticClass:"btn mr-5",attrs:{"isGreen":"","width":"150","height":"48","label":"註冊"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/register')}}}),_c('FancyButton',{staticClass:"btn",attrs:{"width":"150","height":"48","label":"開始"},on:{"click":function($event){$event.preventDefault();return _vm.goNext.apply(null, arguments)}}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }