<template>
    <LayoutWithNav>
        <v-main v-if="!$store.state.isLoading">
            <v-container class="member-container mb-4">
                <div class="member-box">
                    <div
                        class="member-box--avatar"
                        :style="{
                            'background-image': 'url(' + this.image + ')',
                        }"
                    >
                        <v-btn
                            class="btn"
                            depressed
                            height="24"
                            width="24"
                            min-width="24"
                            small
                            color="primary"
                        >
                            <label for="file">
                                <img
                                    width="24"
                                    height="24"
                                    class="avatar"
                                    :src="
                                        require(`@/assets/images/ic24Camera.svg`)
                                    "
                                    alt=""
                                />
                            </label>
                            <input
                                type="file"
                                id="file"
                                name="file"
                                ref="file"
                                accept="image/*"
                                @change="handleFileUpload()"
                                hidden
                            />
                        </v-btn>
                    </div>
                    <div class="member-box--user">
                        <p class="name" style="margin-bottom: 11px;">{{ name }}</p>
                        <div style="width: 322px;height: 123px; background-color: white; border-radius: 14px;">
                            <barcode
                                :value="memberNo"
                                height="80px"
                                fontSize="14"
                                width="2"
                                style="
                                    padding-top: 15px;
                                    border-radius: 14px;
                                    background-color: white;
                                    box-shadow: -1px 2px 10px -2px rgba(75, 37, 24, 0.08);
                                    box-shadow: 1px 1px 4px -2px rgba(75, 37, 24, 0.12);
                                "
                            >
                                Show this if the rendering fails.
                            </barcode>
                        </div>
                    </div>
                </div>

                <v-list flat>
                    <v-list-item-group color="primary">
                        <v-list-item
                            class="item"
                            color="#333"
                            @click.prevent="$router.push('/member/info')"
                        >
                            <img
                                class="item-img"
                                height="24"
                                width="24"
                                :src="
                                    require(`@/assets/images/ic24Account.svg`)
                                "
                                alt=""
                            />
                            <p class="item-text">個人資料</p>
                        </v-list-item>
                        <v-list-item
                            class="item"
                            color="#333"
                            @click.prevent="$router.push('/member/password')"
                        >
                            <img
                                class="item-img"
                                height="24"
                                width="24"
                                :src="require(`@/assets/images/ic24Lock.svg`)"
                                alt=""
                            />
                            <p class="item-text">修改密碼</p>
                        </v-list-item>
                        <v-list-item
                            class="item"
                            color="#333"
                            @click.prevent="$router.push('/pet?type=member')"
                        >
                            <img
                                class="item-img"
                                height="24"
                                width="24"
                                :src="require(`@/assets/images/ic24Dog.svg`)"
                                alt=""
                            />
                            <p class="item-text">寶貝管理</p>
                        </v-list-item>
                        <v-list-item
                            v-if="false"
                            class="item"
                            color="#333"
                            @click.prevent="showPhoneLB = true"
                        >
                            <img
                                class="item-img"
                                height="24"
                                width="24"
                                :src="require(`@/assets/images/ic24Phone.svg`)"
                                alt=""
                            />
                            <p class="item-text">客服電話</p>
                            <!-- <p class="item-text">客服電話</p> -->
                        </v-list-item>
                        <v-list-item
                            class="item"
                            color="#333"
                            @click.prevent="
                                $router.push('/register/contract?url=member')
                            "
                        >
                            <img
                                class="item-img"
                                height="24"
                                width="24"
                                :src="
                                    require(`@/assets/images/ic24Contract.svg`)
                                "
                                alt=""
                            />
                            <p class="item-text">用戶條款</p>
                        </v-list-item>

                        <v-list-item
                            class="item"
                            color="#333"
                            @click.prevent="showDeleteLB = true"
                        >
                            <img
                                class="item-img"
                                height="24"
                                width="24"
                                :src="require(`@/assets/images/ic24Delete.svg`)"
                                alt=""
                            />
                            <p class="item-text">申請刪除會員帳號</p>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
                <FancyButton
                    label="登出"
                    width="185"
                    height="45"
                    isLightOrange
                    @click.prevent="showlogoutLB = true"
                />
            </v-container>
        </v-main>
        <PopupDeleteAccount v-model="showDeleteLB" />
        <PopupLogout v-model="showlogoutLB" :logout="logout" />
        <PopupPhoneCall v-model="showPhoneLB" />
    </LayoutWithNav>
</template>

<script>
import LayoutWithNav from '@/layouts/LayoutWithNav.vue';
import FancyButton from '@/components/FancyButton.vue';
import PopupDeleteAccount from '@/components/Popup/PopupDeleteAccount.vue';
import PopupPhoneCall from '@/components/Popup/PopupPhoneCall.vue';
import PopupLogout from '@/components/Popup/PopupLogout.vue';
import VueBarcode from 'vue-barcode';
import { getUserInfo, uploadMemberImage, getMemberImage } from '@/apis/api.js';
export default {
    name: 'MemberIndex',
    components: {
        LayoutWithNav,
        FancyButton,
        PopupDeleteAccount,
        PopupLogout,
        PopupPhoneCall,
        barcode: VueBarcode,
    },
    data: () => ({
        name: '',
        file: '',
        image: '',
        showDeleteLB: false,
        showlogoutLB: false,
        showPhoneLB: false,
        memberNo: '',
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    mounted() {
        // this.$nextTick(() => {
        //     this.$store.state.isLoading = false;
        // });
    },
    created() {
        if (
            !localStorage.getItem('phone') ||
            !localStorage.getItem('password') ||
            !localStorage.getItem('member_no')
        ) {
            this.$router.push('/login');
        } else {
            var parent = this;
            if (
                !localStorage.getItem('phone') ||
                !localStorage.getItem('password') ||
                !localStorage.getItem('member_no')
            )
                this.$router.push('/login');
            getUserInfo(
                localStorage.getItem('phone'),
                localStorage.getItem('password')
            ).then(function (rsp) {
                parent.name = rsp.c_name;
            });
            getMemberImage(
                localStorage.getItem('phone'),
                localStorage.getItem('password')
            ).then(function (rsp) {
                console.log(rsp);
                if (rsp) parent.image = rsp;
                else parent.image = require(`@/assets/images/imgMemberM.svg`);
                setTimeout(function () {
                    parent.$store.state.isLoading = false;
                }, 500);
            });
            setTimeout(function () {
                parent.$store.state.isLoading = false;
            }, 3000);
            if (localStorage.getItem('member_no'))
                this.memberNo = localStorage.getItem('member_no');
        }
    },
    methods: {
        logout() {
            var parent = this;
            this.showlogoutLB = false;
            localStorage.removeItem('phone');
            localStorage.removeItem('password');
            localStorage.removeItem('member_no');
            setTimeout(function () {
                parent.$router.push('/login');
            }, 1000);
        },
        getImage() {},
        handleFileUpload() {
            this.$store.state.isLoading = true;
            this.file = this.$refs.file.files[0];
            var href = URL.createObjectURL(this.file);
            var parent = this;
            uploadMemberImage(localStorage.getItem('phone'), this.file).then(
                function (rsp) {
                    parent.image = href;
                    console.log('uploadMemberImage', rsp);
                    setTimeout(function () {
                        parent.$store.state.isLoading = false;
                    }, 500);
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.member-container {
    // margin: 12px 0;
    // padding: 10px 0 0 0;
    .member-box {
        margin: 0 16px;
        margin: 90px 16px 0px 16px;
        background: rgba(250, 246, 243, 1);
        border-radius: 16px;
        height: 235px;
        position: relative;
        &--avatar {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -25%;
            width: 95px;
            height: 95px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 50%;

            // img {
            //     width: 100%;
            //     height: 100%;
            // }
            .btn {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
        &--user {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 57.5px;
            p.name {
                font-size: 18px;
                line-height: 21px;
                color: $color_main_black;
            }
        }
    }
    .v-list {
        margin-bottom: 35px;
        .v-item-group {
            .v-list-item {
                &.item {
                    color: $color_main_black;
                    min-height: 45px !important;
                    box-shadow: inset 0px -1px 0px #e9e9e9;
                    &.v-item--active {
                        color: $color_main_black;
                    }
                }
                img.item-img {
                    margin: 0 12px 0 20px;
                }
                p.item-text {
                    padding: 0;
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }
    }
}
</style>
