// src/plugins/vuetify.js

import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
// import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

const opts = {
    theme: {
        themes: {
            light: {
                primary: '#FF9F4B',
                secondary: '#53B175',
                accent: '#8c9eff',
                error: '#b71c1c',
                grey: '#ccc',
            },
        },
    },
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
};

export default new Vuetify(opts);
