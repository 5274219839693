<template>
    <div>
    <v-select
        class="fancy"
        v-bind="{ ...$attrs, ...commonAttrs }"
        v-on="$listeners"
        hide-spin-buttons
        @click="selectFocus"
    >
        <template v-slot:prepend-inner>
            <img
                width="24"
                height="24"
                :src="require(`@/assets/images/${iconName}`)"
                v-if="iconName"
                alt=""
            />
        </template>

        <template v-slot:append>
            <img
                mt-3
                width="24"
                height="24"
                :src="require(`@/assets/images/ic24ArrowD.svg`)"
                alt=""
            />
        </template>
        <template
            v-for="(_, scopedSlotName) in $scopedSlots"
            #[scopedSlotName]="slotData"
        >
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template>
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-select>
    <v-bottom-sheet scrollable persistent v-model="isOpen">
            <v-card class="select-sheet text-center pa-6">
                <div class="d_flex justify-space-between mb-8">
                    <p>{{ label }}</p>
                    <a @click="isOpen = false">完成</a>
                </div>
                <v-card-text class="select-sheet-card-text">
                    <v-list nav dense>
                        <v-list-item-group
                            v-model="selectedItem"
                            color="primary"
                            mandatory
                        >
                            <v-list-item
                                class="select-sheet-list-item"
                                :ripple="false"
                                v-for="(option, i) in options"
                                :key="i"
                            >
                                <v-list-item-content>
                                    <v-list-item-title
                                        v-text="option.text"
                                    ></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-bottom-sheet>
    </div>
</template>

<script>
export default {
    name: 'FancySelect',
    inheritAttrs: true,
    props: {
        iconName: String,
        options: Array,
        label: String
    },
    data: () => ({ selectedItem: null, isOpen: false }),
    watch: {
        selectedItem() {
            this.$emit('input', this.options[this.selectedItem].value)
        },
    },
    methods:{
        selectFocus(){
            this.isOpen = true
        }
    },
    computed: {
        commonAttrs() {
            return {
                outlined: true,
                shaped: true,
            };
        },
    },
};
</script>

<style lang="scss">
.v-menu__content {
    display: none;
}
div.fancy {
    &.v-text-field {
        > .v-input__control {
            > .v-input__slot {
                margin-bottom: 4px;
                fieldset {
                    color: $color_main_first !important;
                    padding-left: 12px !important;
                    margin: 0;
                }
                .v-label {
                    color: $color_main_grey !important;
                    height: 24px !important;
                    line-height: 24px !important;
                }
                .v-label--active {
                    color: $color_main_first !important;
                    transform: translate(6px, -20px) scale(0.75) !important;
                }
                .v-input__prepend-inner {
                    margin-top: 12px !important;
                }
                > .v-select__slot {
                    > .v-input__append-inner {
                        margin-top: 12px;
                    }
                }
            }
        }
        // &--outlined {
        //     &.v-input--is-focused {
        //         fieldset {
        //             border: none !important;
        //         }
        //     }
        // }
    }
    &.sm_select {
        width: 200px;
        margin-left: auto !important;
        margin-right: auto !important;

        div {
            line-height: 35px;
            min-height: 35px;
            height: 35px;
            padding: 0;
        }
        .v-input__control {
            .v-input__slot {
                background-color: #f8f1ec;
                border: 1px solid $color_main_first;
                border-radius: 18px;
                min-height: 35px;
                box-shadow: none !important;
                fieldset {
                    color: transparent !important;
                }
                .v-select__selection {
                    color: $color_main_first;
                    min-width: 150px;
                }

                > .v-select__slot {
                    > .v-input__append-inner {
                        margin-top: 0 !important;
                        color: #999;
                    }
                }
            }
        }
        .v-select__selection--comma {
            margin: 0;
            line-height: 33px;
        }
        .v-input__append-inner {
            margin-right: -10px;
            img {
                height: 33px;
            }
        }
    }
}
div.select-button {
    cursor: pointer;
    width: 103px;
    height: 48px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: $color_main_black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 8px 0px 16px;
    background: #f9f6f4;
    border-radius: 16px;
}

.v-sheet {
    &.select-sheet {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        background: #ffffff;
        border-radius: 12px 12px 0px 0px;
        margin-bottom: 80px;
        .v-card__text.select-sheet-card-text {
            height: 180px;
            padding: 0;
        }
    }
}

.v-list-item {
    &.select-sheet-list-item {
        color: $color_main_black;
        &.v-item--active {
            background: rgba(249, 98, 48, 0.15);
        }
        &:before {
            background: none !important;
        }
        .v-list-item__content {
            padding: 5px 0;
        }
        .v-list-item__title {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
    }
}
// .v-text-field > .v-input__control > .v-input__slot .v-input__append-inner
</style>
