<template>
    <img
        src="@/assets/images/paw.gif"
        class="loading"
        alt=""
        ref="loadingPic"
        v-show="isLoading"
    />
</template>

<script>
export default {
    name: 'Loading',
    computed: {
        isLoading() {
            return this.$store.state.isLoading;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.loading {
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
}
</style>
