<template>
    <v-main>
        <BackOnTop
            v-if="!isRating && $route.query.PBS_ShtType != 'R'"
            :url="url"
            title="美容紀錄"
            labelRight="為美容評分"
            showRight
            @click:right="showSalonRating = true"
        />
        <BackOnTop v-else :url="url" title="美容紀錄" />
        <v-container
            v-if="!$store.state.isLoading"
            fluid
            class="salon-container pt-3 px-6 mt-3 mb-4 text-left"
        >
            <div>
                <div class="style-title-m" style="font-size: 16px !important">
                    {{ services.join('、') }}
                    {{ info.name }}
                </div>
                <div
                    class="style-body-regular"
                    style="
                        letter-spacing: 0.56px;
                        color: #6f6f6f;
                        margin-top: 4px;
                    "
                >
                    {{ info.time }}
                    {{ info.hour.split(':')[0] >= 12 ? '下午' : '上午'
                    }}{{ info.hour }}
                </div>
                <div
                    class="style-body-regular"
                    style="color: #6f6f6f; margin-top: 4px"
                >
                    體重: {{ weight ? weight + 'kg' : '尚未提供' }}
                </div>
            </div>
            <div v-if="isRating">
                <div
                    class="style-title-m style-record-title"
                    style="margin-top: 30px"
                >
                    美容評分<br />&nbsp;
                </div>
                <div
                    style="
                        position: relative;
                        bottom: 25px;
                        margin-bottom: -40px;
                    "
                >
                    <SalonRating v-model="star" readonly />
                </div>
                <div
                    class="style-title-m style-record-title"
                    style="border: none"
                >
                    顧客回饋
                </div>
                <div class="style-regular-body" style="font-size: 14px">
                    {{ reasonText }}
                </div>
            </div>
            <div class="style-title-m style-record-title">
                美容照片<br />After
            </div>
            <!-- <SalonProcessBox
                v-if="processList.length"
                class="p-box mx-4"
                :processList="processList"
            /> -->
            <SalonPhotoSlides
                v-if="slides_after.length"
                :slides="slides_after"
            />
            <div
                class="style-title-m style-record-title"
                style="border: none; margin-top: 20px"
            >
                Before
            </div>
            <!-- <SalonProcessBox
                v-if="processList.length"
                class="p-box mx-4"
                :processList="processList"
            /> -->
            <SalonPhotoSlides
                v-if="slides_before.length"
                :slides="slides_before"
            />
            <div
                class="style-title-m style-record-title"
                style="margin-top: 30px"
            >
                專業建議<br />&nbsp;
            </div>
            <div style="position: relative; bottom: 20px">
                <div
                    class="style-advice col-12"
                    style="
                        display: flex;
                        padding: 0px;
                        padding-left: 18px;
                        height: 156px;
                        /* padding: 8px 18px 4px 18px; */
                        justify-content: space-between;
                        margin-bottom: 8px;
                    "
                >
                    <div style="width: calc(100% - 116px)">
                        <div
                            class="style-title-m"
                            style="
                                color: #ff9f4b;
                                font-size: 14px;
                                margin-top: 22px;
                            "
                        >
                            獸醫師:
                        </div>
                        <div
                            style="
                                color: #0d0c0c;
                                font-size: 14px;
                                margin-top: 10px;
                                padding-bottom: 22px;
                            "
                        >
                            {{
                                doctor_advice
                                    ? doctor_advice
                                    : '定期體檢、提供適當飲食和運動，定期疫苗和驅蟲治療，留意行為變化，預防緊急情況。需要協助，請聯絡我們！'
                            }}
                        </div>
                    </div>
                    <div style="width: 116px">
                        <img
                            src="~@/assets/images/doctor-advice.png"
                            style="margin-top: 15px"
                        />
                    </div>
                </div>
                <div
                    class="style-advice col-12"
                    style="
                        display: flex;
                        padding: 0px;
                        padding-right: 20px;
                        justify-content: space-between;
                        margin-bottom: 8px;
                        height: 156px;
                    "
                >
                    <div style="width: 105px">
                        <img
                            src="~@/assets/images/beauty-advice.png"
                            style="margin-left: 15px; margin-top: 15px"
                        />
                    </div>
                    <div style="width: calc(100% - 105px)">
                        <div
                            class="style-title-m"
                            style="
                                color: #ff9f4b;
                                font-size: 14px;
                                margin-top: 22px;
                            "
                        >
                            美容師:
                        </div>
                        <div
                            style="
                                color: #0d0c0c;
                                font-size: 14px;
                                margin-top: 10px;
                                padding-bottom: 22px;
                            "
                        >
                            {{
                                beauty_advice
                                    ? beauty_advice
                                    : '定期體檢、提供適當飲食和運動，定期疫苗和驅蟲治療，留意行為變化，預防緊急情況。需要協助，請聯絡我們！'
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </v-container>
        <PopupSalonRating
            v-model="showSalonRating"
            :STO_No="store_id"
            :PBS_ApptNo="id"
            @click:confirm="
                showSalonRating = false;
                loadFeedback();
            "
            @click:cancel="showSalonRating = false"
        />
    </v-main>
</template>

<script>
import BackOnTop from '@/components/NewStyle/BackOnTop.vue';
import SalonRating from '@/components/SalonRating.vue';
import SalonPhotoSlides from '@/components/NewStyle/SalonPhotoSlides.vue';
import PopupSalonRating from '@/components/NewPopup/PopupSalonRating.vue';
import {
    getReserveList,
    KgGetStore,
    getPictList,
    KgGetPetList,
    KgGetReserve,
    KgGetBeauty,
    checkFeedback,
    KgGetSheetList,
} from '@/apis/api.js';

export default {
    name: 'SalonCompleted',
    components: {
        BackOnTop,
        SalonRating,
        SalonPhotoSlides,
        PopupSalonRating,
    },
    data: () => ({
        showSalonRating: false,
        slides_before: [],
        slides_after: [],
        infos: [],
        processList: [],
        services: [],
        store_id: '',
        id: '',
        url: '',
        info: {
            name: '',
            time: '',
            hour: '',
        },
        isRating: false,
        reasonText: '',
        star: 0,
        weight: 0,
        doctor_advice: '',
        beauty_advice: '',
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    mounted() {
        var parent = this;
        this.$nextTick(() => {
            setTimeout(function () {
                parent.$store.state.isLoading = false;
            }, 2000);
        });
    },
    created() {
        var parent = this;
        this.url =
            '/salon/history?selectedTime=' +
            parent.$route.query.selectedTime +
            '&selectedStatus=' +
            parent.$route.query.selectedStatus +
            '&selectedType=' +
            parent.$route.query.selectedType;
        var id = parent.$route.query.id;
        parent.id = id;
        parent.store_id = parent.$route.query.store_id;
        var store_dict = {};
        checkFeedback(
            localStorage.getItem('phone'),
            localStorage.getItem('password'),
            id
        ).then(function (rsp) {
            if (rsp.match('false') == null) {
                parent.isRating = true;
                console.log(rsp.replace(/\\/g, ''));
                rsp = JSON.parse(rsp.replace(/\\/g, ''));
                parent.star = parseInt(rsp.star);
                parent.reasonText = rsp.feedback;
            } else {
                parent.star = 5;
            }
        });
        var numberOfMlSeconds = new Date().getTime();
        var newDateObj = new Date(numberOfMlSeconds - 1000 * 60 * 60 * 24 * 60);
        this.start_date =
            newDateObj.getFullYear().toString() +
            (newDateObj.getMonth() + 1 < 10
                ? '0' + (newDateObj.getMonth() + 1).toString()
                : (newDateObj.getMonth() + 1).toString()) +
            (newDateObj.getDate() < 10
                ? ('0' + newDateObj.getDate()).toString()
                : newDateObj.getDate().toString());
        newDateObj = new Date(numberOfMlSeconds + 1000 * 60 * 60 * 24 * 60);
        this.end_date =
            newDateObj.getFullYear().toString() +
            (newDateObj.getMonth() + 1 < 10
                ? '0' + (newDateObj.getMonth() + 1).toString()
                : (newDateObj.getMonth() + 1).toString()) +
            (newDateObj.getDate() < 10
                ? '0' + newDateObj.getDate().toString()
                : newDateObj.getDate().toString());
        KgGetStore(localStorage.getItem('phone')).then(function (store_list) {
            for (let i in store_list) {
                console.log(store_list[i]);
                store_dict[store_list[i].STO_No] = store_list[i].STO_Name;
            }
            if (parent.$route.query.PBS_ShtType == 'R') {
                KgGetBeauty(
                    localStorage.getItem('phone'),
                    parent.$route.query.store_id,
                    parent.$route.query.id,
                    parent.$route.query.PBS_Date
                ).then(function (rsp) {
                    if (rsp) {
                        parent.weight = rsp.PBS_AccyLockNo;
                        parent.beauty_advice = rsp.PBS_ContactInfo;
                        parent.doctor_advice = rsp.PET_HealthDesc;

                        KgGetSheetList(
                            localStorage.getItem('phone'),
                            localStorage.getItem('member_no'),
                            parent.$route.query.store_id,
                            parent.start_date,
                            parent.end_date
                        ).then(function (rsp) {
                            if (rsp) {
                                rsp = rsp.filter(function (obj) {
                                    if (
                                        obj.PBS_ApptNo ||
                                        obj.PBS_ShtNo != parent.$route.query.id
                                    ) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                });
                                console.log('fetchSheetList', rsp);

                                rsp = rsp.map(function (obj) {
                                    obj.STO_No = parent.$route.query.store_id;
                                    if (
                                        ['R0', 'R1', 'R2'].indexOf(
                                            obj.PBS_ShtStatus
                                        ) != -1
                                    ) {
                                        obj.PBS_ApptStatus = 3;
                                    } else if (
                                        ['R3'].indexOf(obj.PBS_ShtStatus) != -1
                                    ) {
                                        obj.PBS_ApptStatus = 'S1';
                                    } else if (
                                        ['R4', 'R5'].indexOf(
                                            obj.PBS_ShtStatus
                                        ) != -1
                                    ) {
                                        obj.PBS_ApptStatus = 4;
                                    }
                                    //
                                    obj.PBS_CheckInDate = obj.PBS_ShtDate;
                                    obj.PBS_ApptBegTime = obj.CRT_Date.slice(
                                        8,
                                        12
                                    );
                                    obj.PBS_ApptNo = obj.PBS_ShtNo;
                                    obj.PBS_No = obj.PBS_ShtNo;
                                    var hour =
                                        obj.PBS_ApptBegTime.slice(0, 2) +
                                        ':' +
                                        obj.PBS_ApptBegTime.slice(2, 4);
                                    var is_wash =
                                        obj.PBS_Svr01 == 'Y' ? true : false;
                                    var is_spa =
                                        obj.PBS_Svr02 == 'Y' ? true : false;
                                    var is_cut =
                                        obj.PBS_Svr03 == 'Y' ? true : false;
                                    var is_swim =
                                        obj.PBS_Svr04 == 'Y' ? true : false;
                                    var is_basic =
                                        obj.PBS_Svr05 == 'Y' ? true : false;

                                    parent.store = store_dict[obj.STO_No];
                                    parent.data = obj;
                                    parent.date =
                                        obj.PBS_CheckInDate.slice(0, 4) +
                                        '/' +
                                        obj.PBS_CheckInDate.slice(4, 6) +
                                        '/' +
                                        obj.PBS_CheckInDate.slice(6, 8);
                                    parent.time = hour;
                                    parent.pet_name = obj.PET_Name;
                                    parent.pet_no = obj.PET_SeqNo;

                                    parent.info = {
                                        name: obj.PET_Name,
                                        time:
                                            obj.PBS_CheckInDate.slice(0, 4) +
                                            '/' +
                                            obj.PBS_CheckInDate.slice(4, 6) +
                                            '/' +
                                            obj.PBS_CheckInDate.slice(6, 8),
                                        hour: hour,
                                    };
                                    parent.services = [];
                                    if (is_wash && is_spa) {
                                        parent.services.push('洗澡+SPA');
                                    } else if (is_wash && is_swim) {
                                        parent.services.push('洗澡+游泳');
                                    } else if (is_cut && is_spa) {
                                        parent.services.push('剪毛+SPA');
                                    } else if (is_cut && is_swim) {
                                        parent.services.push('剪毛+游泳');
                                    } else if (is_wash) {
                                        parent.services.push('洗澡');
                                    } else if (is_cut) {
                                        parent.services.push('剪毛');
                                    } else if (is_swim) {
                                        parent.services.push('游泳');
                                    }

                                    if (is_basic) {
                                        parent.services.push('基礎美容');
                                    }
                                    // parent.services = [item_dict[rsp[i].e_resv_item]]
                                    parent.loadPicList(parent.$route.query.store_id)
                                    parent.$store.state.isLoading = false;
                                    return obj;
                                });

                                // return true;
                            } else {
                                // return false;
                            }
                        });
                    }
                });
            } else {
                getReserveList(
                    localStorage.getItem('phone'),
                    localStorage.getItem('password')
                ).then(function (rsp) {
                    rsp = rsp
                        .filter(function (obj) {
                            return (
                                obj.c_pbsapptno == id &&
                                obj.e_storeno == parent.store_id
                            );
                        })
                        .slice(0, 1);
                    for (let i in rsp) {
                        if (
                            rsp[i].c_pbsapptno == id &&
                            rsp[i].e_storeno == parent.store_id &&
                            !parent.processList.length
                        ) {
                            parent.STO_No = rsp[i].e_storeno;
                            parent.data = rsp[i];
                            // parent.services = [item_dict[rsp[i].e_resv_item]]
                            KgGetPetList(
                                localStorage.getItem('phone'),
                                localStorage.getItem('member_no')
                            ).then(function (res) {
                                for (let j in res) {
                                    if (res[j].PET_Name == rsp[i].c_pet_name) {
                                        KgGetReserve(
                                            localStorage.getItem('phone'),
                                            localStorage.getItem('member_no'),
                                            rsp[i].e_storeno,
                                            rsp[i].c_pbsapptno
                                        ).then(function (inner_rsp) {
                                            var hour =
                                                inner_rsp.PBS_ApptBegTime.slice(
                                                    0,
                                                    2
                                                ) +
                                                ':' +
                                                inner_rsp.PBS_ApptBegTime.slice(
                                                    2,
                                                    4
                                                );
                                            parent.services = [];
                                            var is_wash =
                                                inner_rsp.PBS_Svr01 == 'Y'
                                                    ? true
                                                    : false;
                                            var is_spa =
                                                inner_rsp.PBS_Svr02 == 'Y'
                                                    ? true
                                                    : false;
                                            var is_cut =
                                                inner_rsp.PBS_Svr03 == 'Y'
                                                    ? true
                                                    : false;
                                            var is_swim =
                                                inner_rsp.PBS_Svr04 == 'Y'
                                                    ? true
                                                    : false;
                                            var is_basic =
                                                inner_rsp.PBS_Svr05 == 'Y'
                                                    ? true
                                                    : false;
                                            if (is_wash && is_spa) {
                                                parent.services.push(
                                                    '洗澡+SPA'
                                                );
                                            } else if (is_wash && is_swim) {
                                                parent.services.push(
                                                    '洗澡+游泳'
                                                );
                                            } else if (is_cut && is_spa) {
                                                parent.services.push(
                                                    '剪毛+SPA'
                                                );
                                            } else if (is_cut && is_swim) {
                                                parent.services.push(
                                                    '剪毛+游泳'
                                                );
                                            } else if (is_wash) {
                                                parent.services.push('洗澡');
                                            } else if (is_cut) {
                                                parent.services.push('剪毛');
                                            } else if (is_swim) {
                                                parent.services.push('游泳');
                                            }

                                            if (is_basic) {
                                                parent.services.push(
                                                    '基礎美容'
                                                );
                                            }
                                            parent.info = {
                                                name: rsp[i].c_pet_name,
                                                time:
                                                    rsp[i].c_resv_date.slice(
                                                        0,
                                                        4
                                                    ) +
                                                    '/' +
                                                    rsp[i].c_resv_date.slice(
                                                        4,
                                                        6
                                                    ) +
                                                    '/' +
                                                    rsp[i].c_resv_date.slice(
                                                        6,
                                                        8
                                                    ),
                                                hour: hour,
                                            };
                                            parent.infos = [
                                                {
                                                    key: '會員姓名',
                                                    value: rsp[i].c_name,
                                                },
                                                {
                                                    key: '聯絡電話',
                                                    value: rsp[i].c_phone_num,
                                                },
                                                {
                                                    key: '寶貝姓名',
                                                    value: rsp[i].c_pet_name,
                                                },
                                                {
                                                    key: '預約門市',
                                                    value: store_dict[
                                                        rsp[i].e_storeno
                                                    ],
                                                },
                                                {
                                                    key: '預約日期',
                                                    value:
                                                        rsp[
                                                            i
                                                        ].c_resv_date.slice(
                                                            0,
                                                            4
                                                        ) +
                                                        '/' +
                                                        rsp[
                                                            i
                                                        ].c_resv_date.slice(
                                                            4,
                                                            6
                                                        ) +
                                                        '/' +
                                                        rsp[
                                                            i
                                                        ].c_resv_date.slice(
                                                            6,
                                                            8
                                                        ) +
                                                        '   ' +
                                                        hour,
                                                },
                                            ];
                                            parent.processList = [];
                                            KgGetBeauty(
                                                localStorage.getItem('phone'),
                                                parent.STO_No,
                                                inner_rsp.PBS_No,
                                                inner_rsp.PBS_Date
                                            ).then(function (rsp) {
                                                if (rsp) {
                                                    parent.weight =
                                                        rsp.PBS_AccyLockNo;
                                                    parent.beauty_advice =
                                                        rsp.PBS_ContactInfo;
                                                    parent.doctor_advice =
                                                        rsp.PET_HealthDesc;
                                                    if (
                                                        rsp.PBS_DTL_INFO.length
                                                    ) {
                                                        var big_end_time = '0';
                                                        var small_start_time = 40220530215412;
                                                        for (let index in rsp.PBS_DTL_INFO) {
                                                            console.log(
                                                                small_start_time,
                                                                rsp
                                                                    .PBS_DTL_INFO[
                                                                    index
                                                                ].PBS_SvrBegTime
                                                            );
                                                            if (
                                                                rsp
                                                                    .PBS_DTL_INFO[
                                                                    index
                                                                ]
                                                                    .PBS_SvrEndTime >
                                                                big_end_time
                                                            ) {
                                                                big_end_time =
                                                                    rsp
                                                                        .PBS_DTL_INFO[
                                                                        index
                                                                    ]
                                                                        .PBS_SvrEndTime;
                                                            }
                                                            if (
                                                                rsp
                                                                    .PBS_DTL_INFO[
                                                                    index
                                                                ]
                                                                    .PBS_SvrBegTime <
                                                                small_start_time
                                                            ) {
                                                                small_start_time =
                                                                    rsp
                                                                        .PBS_DTL_INFO[
                                                                        index
                                                                    ]
                                                                        .PBS_SvrBegTime;
                                                            }
                                                            if (
                                                                index ==
                                                                rsp.PBS_DTL_INFO
                                                                    .length -
                                                                    1
                                                            ) {
                                                                if (
                                                                    small_start_time.length ==
                                                                    14
                                                                ) {
                                                                    parent.processList.push(
                                                                        {
                                                                            time:
                                                                                small_start_time.slice(
                                                                                    8,
                                                                                    10
                                                                                ) +
                                                                                ':' +
                                                                                small_start_time.slice(
                                                                                    10,
                                                                                    12
                                                                                ),
                                                                            step: '處理中',
                                                                            isFinished: true,
                                                                        }
                                                                    );
                                                                }
                                                                if (
                                                                    big_end_time.length ==
                                                                    14
                                                                ) {
                                                                    parent.processList.push(
                                                                        {
                                                                            time:
                                                                                big_end_time.slice(
                                                                                    8,
                                                                                    10
                                                                                ) +
                                                                                ':' +
                                                                                big_end_time.slice(
                                                                                    10,
                                                                                    12
                                                                                ),
                                                                            step: '完成服務',
                                                                            isFinished: true,
                                                                        }
                                                                    );
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            });
                                        });

                                        getPictList(
                                            rsp[i].e_storeno,
                                            parent.$route.query.PBS_No,
                                            localStorage.getItem('member_no'),
                                            parent.$route.query.PET_SeqNo
                                        ).then(function (rsp) {
                                            console.log(rsp);
                                            if (rsp) {
                                                parent.slides_before = [];
                                                parent.slides_after = [];
                                                if (rsp.before) {
                                                    parent.slides_before =
                                                        rsp.before.map(
                                                            function (url) {
                                                                return url.replace(
                                                                    'http://',
                                                                    'https://'
                                                                );
                                                            }
                                                        );
                                                }
                                                if (rsp.after) {
                                                    parent.slides_after =
                                                        rsp.after.map(function (
                                                            url
                                                        ) {
                                                            return url.replace(
                                                                'http://',
                                                                'https://'
                                                            );
                                                        });
                                                }
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    }
                });
            }
        });
    },
    methods: {
        loadPicList(store_no) {
            let parent = this;
            getPictList(
                store_no,
                parent.$route.query.PBS_No,
                localStorage.getItem('member_no'),
                parent.$route.query.PET_SeqNo
            ).then(function (rsp) {
                console.log(rsp);
                if (rsp) {
                    parent.slides_before = [];
                    parent.slides_after = [];
                    if (rsp.before) {
                        parent.slides_before =
                            rsp.before.map(
                                function (url) {
                                    return url.replace(
                                        'http://',
                                        'https://'
                                    );
                                }
                            );
                    }
                    if (rsp.after) {
                        parent.slides_after =
                            rsp.after.map(function (
                                url
                            ) {
                                return url.replace(
                                    'http://',
                                    'https://'
                                );
                            });
                    }
                }
            });
        },
        loadFeedback() {
            let parent = this;
            checkFeedback(
                localStorage.getItem('phone'),
                localStorage.getItem('password'),
                parent.id
            ).then(function (rsp) {
                if (rsp.match('false') == null) {
                    parent.isRating = true;
                    console.log(rsp.replace(/\\/g, ''));
                    rsp = JSON.parse(rsp.replace(/\\/g, ''));
                    parent.star = parseInt(rsp.star);
                    parent.reasonText = rsp.feedback;
                } else {
                    parent.star = 5;
                }
            });
        },
    },
};
</script>

<style lang="scss">
.salon-container {
    h2 {
        margin: 28px 0 23px 0;
        color: $color_main_first;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
    }
    .p-box {
        margin: 0 0 16px 0;
    }
}
</style>
