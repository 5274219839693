<template>
    <v-otp-input
        v-bind="{ ...$attrs, ...commonAttrs }"
        v-on="$listeners"
    ></v-otp-input>
</template>

<script>
export default {
    name: 'FancyOTP',
    inheritAttrs: false,
    props: {},
    computed: {
        commonAttrs() {
            return {
                plain: true,
                ['hide-spin-buttons']: true,
                type: 'number',
                length: '6',
            };
        },
    },
    methods: {
        handleDialog(value) {
            this.$emit('input', value);
        },
    },
};
</script>
<style lang="scss">
div.v-otp-input {
    > .v-input {
        padding: 0 16px;
    }
    .v-text-field {
        > .v-input__control {
            > .v-input__slot {
                > .v-text-field__slot {
                    > input {
                        color: #f3603f;
                        font-size: 25px;
                        padding: 0;
                        margin-bottom: 4px;
                    }
                }

                &:before {
                    border-color: #f3603f;
                }
                &:after {
                    border: none;
                }
            }
        }
        &:not(.v-input--has-state) {
            &:hover {
                > .v-input__control {
                    > .v-input__slot {
                        &:before {
                            border-color: #f3603f;
                        }
                    }
                }
            }
        }
    }
}
</style>
