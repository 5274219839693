<template>
    <v-main>
        <BackOnTop />
        <!-- <FancySnackbar v-model="showAlert" :label="errorMsg" isUpper /> -->
        <PopupAlert v-model="showAlert" :errorMsg="errorMsg" :imgSrc="imgSrc" />
        <PageTitleBar title="簡訊驗證" />
        <v-container class="fp-container otp px-4 mb-4">
            <p class="subject mb-4">已傳送驗證碼至您的手機</p>
            <p class="desc mb-5">
                請輸入6位數驗證碼, 若未收到簡訊請按重新發送或重新確認手機號碼<br />
                若無法收到驗證碼，請聯繫大樹寵物官網客服
            </p>
            <p class="resend mb-6" :class="{ cd: isCD }" @click="cdTimer(true)">
                重新發送驗證碼
                {{ isCD ? '00:' + cd.toString().padStart(2, '0') : '' }}
            </p>

            <FancyOTP v-model="otp" />
        </v-container>
        <FancyButton
            class="mt-9"
            label="送出"
            width="150"
            height="48"
            isGreen
            @click="checkVerifyCode()"
        />
    </v-main>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
import PageTitleBar from '@/components/PageTitleBar.vue';
import FancyButton from '@/components/FancyButton.vue';
import PopupAlert from '@/components/Popup/PopupAlert.vue';
import FancyOTP from '@/components/FancyOTP.vue';
import { sendVerifyCode, checkVerifyCode, resetPassword } from '@/apis/api.js';
export default {
    name: 'ForgotPasswordOTP',
    components: { BackOnTop, PageTitleBar, FancyButton, FancyOTP, PopupAlert},
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.state.isLoading = false;
        });
    },
    data: () => ({
        otp: '',
        imgSrc:'',
        isSuccessOpened: false,
        isCD: false,
        cd: 60,
        showAlert: false,
        errorMsg: '驗證碼錯誤',
    }),
    created() {
        this.cdTimer(true);
        // sendVerifyCode(this.$route.query.phone).then(function (rsp) {
        //     if (rsp) {
        //         console.log('發送成功');
        //     }
        // });
    },
    methods: {
        cdTimer(start) {
            if (start && !this.isCD) {
                sendVerifyCode(this.$route.query.phone).then(function (rsp) {
                    if (rsp) {
                        console.log('發送成功');
                    }
                });
                this.isCD = true;
                this.cd = 60;
            }
            if (this.cd > 0) {
                setTimeout(() => {
                    this.cd -= 1;
                    this.cdTimer();
                }, 1000);
            }

            if (this.cd == 0) {
                this.isCD = false;
            }
        },
        checkVerifyCode() {
            var parent = this;
            checkVerifyCode(this.$route.query.phone, parent.otp).then(function (
                rsp
            ) {
                if (rsp) {
                    console.log('驗證成功');
                    resetPassword(parent.$route.query.phone).then(function (
                        rsp
                    ) {
                        if (rsp) {
                            parent.$router.push(
                                '/forgot-password/reset?phone=' +
                                    parent.$route.query.phone
                            );
                        }
                    });
                    // parent.$router.push('/login');
                } else {
                    parent.showAlert = true;
                    parent.imgSrc = '@/assets/images/imgWarning.svg'
                    parent.$store.state.isLoading = false;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.fp-container {
    margin: 12px 0;
    padding: 10px 0 0 0;
    &.otp {
        text-align: left;
        p {
            &.subject {
                font-size: 22px;
                color: $color_main_first;
            }
            &.desc {
                font-size: 14px;
                color: $color_main_black;
            }
            &.resend {
                cursor: pointer;
                font-size: 14px;
                line-height: 16px;
                color: $color_main_second;
                &.cd {
                    color: $color_main_grey_light;
                }
            }
        }
    }
}
</style>
