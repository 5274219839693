var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FancyDialog',_vm._g(_vm._b({},'FancyDialog',{ ..._vm.$attrs },false),_vm.$listeners),[_c('v-container',{staticClass:"dialog_container text-left",attrs:{"fluid":""}},[_c('div',{staticStyle:{"text-align":"left","margin-bottom":"12px"}},[_c('div',{staticClass:"popup-title",staticStyle:{"margin-bottom":"10px"}},[_vm._v("確定取消預約?")]),_c('div',{staticClass:"popup-desc"},[_vm._v("將取消本次預約，如需再次預約，需重新填寫預約資料")])]),_c('v-form',{ref:"cancel_form",model:{value:(_vm.cancelValid),callback:function ($$v) {_vm.cancelValid=$$v},expression:"cancelValid"}},[_c('div',{staticClass:"popup-desc",staticStyle:{"margin-bottom":"8px"}},[_vm._v("取消原因")]),_c('div',{staticClass:"popup-desc"},[_c('FancyRadio',{staticClass:"mt-n1 f_sm",attrs:{"fluid":"","items":[
                    { label: '有事無法前來', value: '有事無法前來' },
                    { label: '天氣因素', value: '天氣因素' },
                    { label: '其他', value: '其他' },
                ],"rules":[
                    () => !!_vm.form.reason || '請選擇取消原因',
                ],"required":""},model:{value:(_vm.form.reason),callback:function ($$v) {_vm.$set(_vm.form, "reason", $$v)},expression:"form.reason"}}),(_vm.form.reason == '其他')?_c('FancyTextarea',{staticClass:"textarea_else",attrs:{"height":"100","placeholder":"其他原因"},model:{value:(_vm.form.reasonText),callback:function ($$v) {_vm.$set(_vm.form, "reasonText", $$v)},expression:"form.reasonText"}}):_vm._e()],1)]),_c('div',{staticClass:"footer d-flex justify-center",staticStyle:{"margin-bottom":"8px"}},[_c('button',{staticClass:"style-btn style-m-btn style-dark-btn",staticStyle:{"margin":"8px"},on:{"click":function($event){return _vm.$emit('click:cancel_cancel_reserve')}}},[_vm._v(" 取消 ")]),_c('button',{staticClass:"style-btn style-m-btn style-light-btn",staticStyle:{"margin":"8px"},on:{"click":function($event){return _vm.check()}}},[_vm._v(" 確認 ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }